import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';


/**
 * Checks if a control's value is in a given array.
 *
 * @param array to check the control's value against.
 * @param exclude If provided, this value will be excluded from the array when checking.
 * @returns ValidatorFn a validator function which checks if the control's value is in the array.
 */
export const inArray = (array: any[], exclude?: any): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    // Filter the array to exclude a value if provided, then check if the control's value is in the array.
    return array.filter(value => value !== exclude).includes(control.value) ? {'IN_ARRAY': true} : null;
  };
};
