import { Injectable } from '@angular/core';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { FieldConfig } from '@app/core/model/other/field-config';
import { IIndicatorWidget } from '@app/shared/components/indicator-status-panel/indicator-status-panel.component';
import { getValue } from '@app/shared/extra/utils';
import { ColumnBuilder, ExtendedColDef } from '@app/shared/grid/column-builder';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { ColGroupDef, ColumnState, ValueGetterFunc } from 'ag-grid-community';
import { ColDef, ValueGetterParams } from 'ag-grid-enterprise';
import { gql } from 'apollo-angular';
import { Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class ColumnGroup {
  public code: string;
  public order: number;
  public customOptions?: Record<string, any>;
  public label: string;

  @Transform(({value}) => EntityTypeEnum[value])
  public entityType: EntityTypeEnum;

  @Expose({name: 'columnsList'})
  @Type(() => FieldConfig)
  public columns: FieldConfig[];
}

export interface GridConfig {
  columnDefs: ExtendedColDef[],
  toolPanelColDefs: ColGroupDef[],
  defaultColumnStates: ColumnState[],
  indicators: IIndicatorWidget[]
}

@Injectable()
export class GridConfigService {

  private gridConfigGraphQlFragment = gql`
    fragment GridConfigInfo on GridConfig {
      organizationId
      code
      columnGroupsList {
        code
        order
        label
        entityType
        customOptions
        columnsList {
          fieldCode
          order
          customOptions
          field {
            code
            label
            entityType
            fieldType
            checkType
            computed
            parentPathList
            fieldValuesList
            validatorsList {
              conditionsList {
                field
                operator
                value
              }
              definition
              type
            }
          }
          conditionsToViewList {
            field
            operator
            value
          }
          conditionsToEditList {
            field
            operator
            value
          }
        }
      }
      indicatorsList
    }
  `;

  constructor(private generalService: GeneralService,
              private appManager: AppManager,
              private deviceDetectorService: DeviceDetectorService,
              private columnBuilder: ColumnBuilder) {
  }

  /**
   * Fetch and build a GridConfig to be used for displaying a datagrid.
   * The configuration features the column definitions for the datagrid as well as the tool panel and default column
   * states to be applied.
   * @param code GridConfig's code.
   * @param filter Optional filter to discard specific columns.
   * @return An Observable that will emit the GridConfig.
   */
  public getGridConfig(code: string, filter = (_: FieldConfig): boolean => true): Observable<GridConfig> {
    const COMBINED_QUERY = gql`
      query GridConfigQuery($code: String!, $orgId: String!) {
        gridConfig(code: $code, organizationId: $orgId) {
          ...GridConfigInfo
        }
      }${this.gridConfigGraphQlFragment}
    `;
    const QUERY_VAR = {code, orgId: this.appManager.currentOrganization.id};

    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => {
          return {
            columnGroups: plainToInstance(
              ColumnGroup,
              response.data['gridConfig']['columnGroupsList'] as ColumnGroup[]
            ),
            indicators: response.data['gridConfig']['indicatorsList'] as Record<string, any>[]
          };
        }),
        map(gridData => {
          // Extract indicators from each section
          const indicators = this.buildIndicators(gridData.indicators);

          // Ungroup columns, keep column groups' codes and propertiesPaths
          const columnConfigs = gridData.columnGroups.flatMap(columnGroup => {
            return columnGroup.columns.map(column => {
              return {
                columnGroupCode: columnGroup.code,
                columnGroupLabel: columnGroup.label,
                propertiesPath: columnGroup.customOptions?.['propertiesPath'],
                column
              };
            });
          })
            // Filter unwanted columns
            .filter(({column}) => filter(column))
            // Reduce columns into gridConfig
            .reduce((gridConfig, {column, columnGroupCode, columnGroupLabel, propertiesPath}) => {
              // Create column definition
              this.buildColumnDef(column, gridConfig.columnDefs, propertiesPath);

              // Create tool panel column definition
              this.buildToolPanelColDef(columnGroupCode, columnGroupLabel, column.code, gridConfig.toolPanelColDefs);

              // Create default column state
              this.buildDefaultColumnState(column, gridConfig.defaultColumnStates);

              return gridConfig;
            }, {columnDefs: [], toolPanelColDefs: [], defaultColumnStates: []} as GridConfig);

          return {...columnConfigs, indicators};
        })
      );
  }

  /**
   * Calculate if a column should be hidden based on the field config and the type of device used.
   * @param column FieldConfig corresponding to the column.
   * @return A boolean value indicating whether the column should be hidden.
   * @private
   */
  private shouldHideColumn(column: FieldConfig): boolean {
    return this.deviceDetectorService.isMobile() && !column.customOptions?.['displayMobile'];
  }

  /**
   * Build a ColDef from a column's FieldConfig then pushes it to the provided list of column configurations.
   * @param column FieldConfig corresponding to the column.
   * @param columnDefs ColDef array to push the new column into.
   * @param propertiesPath Properties' path within the FormStateService, if it's not at the root.
   * @private
   */
  private buildColumnDef(column: FieldConfig, columnDefs: ColDef[], propertiesPath?: string): void {
    columnDefs.push(
      this.columnBuilder.createColumn(
        column.field.fieldType,
        column.field.label,
        GridConfigService.createValueGetter(propertiesPath),
        {
          formatType: column.customOptions?.['formatType'],
          suffix: column.customOptions?.['suffixType'],
          colId: column.fieldCode,
          hide: this.shouldHideColumn(column), // FIXME set to true by default for all columns
          pinned: column.customOptions?.['pinned'] ?? false,
          allowedAggFuncs: column.customOptions?.['aggFuncs'] ?? [],
          aggFunc: column.customOptions?.['defaultAggFunc'] ?? null,
          enableValue: column.customOptions?.['aggFuncs']?.length > 0 ?? false,
          translate: column.customOptions?.['translate'] ?? false,
          fieldConfig: column,
          propertiesPath: column.customOptions?.['propertiesPath'],
          noFilter: column.customOptions?.['noFilter']
        }
      )
    );
  }

  /**
   * Build a ColDef from a FieldConfig to be used for the tool panel, then pushes it to the corresponding group's
   * children within the provided list of column group configurations. If a field group does not exist, it is created
   * before adding the column to its children list.
   * @param groupId ID of the group the field belongs to.
   * @param headerName Label for the column group.
   * @param colId ID of the column to create a ColDef for.
   * @param toolPanelColGroupDefs The ColGroupDef array to push the new column into.
   * @private
   */
  private buildToolPanelColDef(
    groupId: string,
    headerName: string,
    colId: string,
    toolPanelColGroupDefs: ColGroupDef[]
  ): void {
    let colGroup = toolPanelColGroupDefs.find(group => group.groupId === groupId);

    // Create group if it does not already exist in the tool panel
    if (!colGroup) {
      colGroup = {groupId, headerName, children: []};
      toolPanelColGroupDefs.push(colGroup);
    }

    // Add field to group
    colGroup.children.push({colId});
  }

  /**
   * Retrieve the default column state from a FieldConfig, if any, and pushes it to the provided list of column states.
   * @param column FieldConfig corresponding to the column.
   * @param defaultColumnStates List of column states to push the new states into.
   * @private
   */
  private buildDefaultColumnState(column: FieldConfig, defaultColumnStates: ColumnState[]): void {
    if (column.customOptions?.['defaultColumnState']) {
      defaultColumnStates.push({
        colId: column.fieldCode,
        ...column.customOptions['defaultColumnState']
      });
    }
  }

  /**
   * Builds an indicator widget for each of the indicator configurations provided.
   * @param indicatorsData List of indicator configurations.
   * @return List of indicator widgets.
   */
  private buildIndicators(indicatorsData: Record<string, any>[] = []): IIndicatorWidget[] {
    return indicatorsData?.map(config => {
      return {
        label: config['label'],
        icon: config['indicatorIcon'],
        formatType: config['formatType'],
        suffix: config['suffix'],

        computation: config['computation'],
        property: config['property'],
        filters: config['filters'],

        totalValue: 0,
        filteredValue: 0
      };
    }) ?? [];
  }

  /**
   * Common value getter to retrieve the value in an entity.
   * @param propertiesPath Properties' path if it's not root.
   * @private
   */
  private static createValueGetter(propertiesPath?: string): ValueGetterFunc {
    const root = propertiesPath ? [propertiesPath] : [];
    return (params: ValueGetterParams): any => {
      const colDef = params.colDef as ExtendedColDef;
      return !params.node.group
        ? getValue(params.data, root.concat(colDef.fieldConfig.fieldPath))
        : undefined;
    };
  }
}
