import { Component, HostListener } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Project } from '@app/core/model/entities/project/project';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';
import { BaseProjectSheet } from '@app/features/main/views/projects/project-sheet/base-project-sheet';
import { ProjectModalService } from '@app/features/main/views/projects/projects-inventory/modals/project-modal-service';
import { ProjectsService } from '@app/features/main/views/projects/projects.service';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@services/file.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { of } from 'rxjs';
import { filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'projects-sidebar',
  templateUrl: './projects-sidebar.component.html',
  providers: [ProjectModalService, DocumentModalService]
})
export class ProjectsSidebarComponent extends BaseProjectSheet {

  public eventsOrigin = EventOriginEnum.SIDEPANEL;
  public preconditionForDeletion = false;

  constructor(public accessManager: AccessManager,
              public appConfig: AppConfig,
              public fileService: FileService,
              public projectsService: ProjectsService,
              protected appManager: AppManager,
              protected analyticsService: AnalyticsService,
              protected formStateService: FormStateService,
              protected translate: TranslateService,
              protected snackbarManager: SnackbarManager,
              protected documentsService: DocumentsService,
              protected singleEditService: SingleEditService,
              protected projectModalService: ProjectModalService) {
    super(
      accessManager,
      appConfig,
      fileService,
      projectsService,
      appManager,
      analyticsService,
      formStateService,
      translate,
      snackbarManager,
      documentsService,
      projectModalService
    );
  }

  /**
   * Listen for sidebar toggle actions and updates from the FormStateService.
   * Update sidebar data and call Project service when necessary.
   */
  public ngOnInit(): void {
    super.ngOnInit();

    // Update the side panel's content whenever a new Project is selected
    this.projectsService.sidePanelToggle$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(projectData => {
          if (projectData) {
            return this.projectsService.projectUpdated$
              .pipe(
                takeUntil(this.destroy$),
                filter(updatedProject => updatedProject.id === projectData.id),
                startWith(projectData)
              );
          } else {
            return of(projectData);
          }
        }),
        tap(() => {
          this.singleEditService.singleEditSubject.next(null);

          // Unload current Project
          if (this.appManager.currentEntity instanceof Project) {
            this.appManager.unloadCurrentEntity();
          }
        }),
        filter(project => !!project)
      )
      .subscribe(project => {
        this.project = project;
        this.appManager.currentEntity = project;
        this.loadProjectData();

        // Check if the user has permission to delete the project.
        // Only super admin, group with all asset and type access or with access to all the projects' related assets has permission to delete.
        const currentUserGroup = this.accessManager.currentUser.groups?.firstOrNull(group => group.client.organizationId === this.appManager.currentOrganization.id);
        if (this.accessManager.isSuperAdmin() || (currentUserGroup?.allAssetAccess && currentUserGroup?.allAssetTypeAccess)) {
          this.preconditionForDeletion = true;
        } else {
          const projectAssetIds = this.project.assetIds;
          const projectAssetTypeIds = (currentUserGroup.allAssetAccess || currentUserGroup.assetIds.some(assetId => projectAssetIds.includes(assetId)))
            && (currentUserGroup.allAssetTypeAccess || currentUserGroup.assetTypeIds.some(assetTypeId => projectAssetTypeIds.includes(assetTypeId)));
        }
      });
  }

  /**
   * Open a dialog to delete the current Project entity if the user confirms.
   */
  public deleteProject(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.DELETE_DIALOG,
      EntityTypeEnum.PROJECT,
      this.project.id
    );
    this.projectModalService.openDeleteProjectsDialog([this.project])
      .pipe(
        takeUntil(this.destroy$),
        filter(result => !!result)
      )
      .subscribe(() => this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.PROJECT_DELETED')));
  }

  /**
   * Close the sidebar.
   */
  public closeProjectSidePanel(): void {
    this.projectsService.closeProjectSidePanel();
  }

  /**
   * Bypass Material escape handler to call closeProjectSidebar,
   * which manage the current entity stacks.
   * @param event
   */
  @HostListener('keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeProjectSidePanel();
    }
  }

  /**
   * Navigate to the Project's sheet.
   */
  public async navigateToProjectSheet(): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.SHEET,
      EntityTypeEnum.PROJECT,
      this.project.id
    );
    await this.projectsService.navigateToProjectSheet(this.project.id);
  }

  /**
   * Open a dialog to upload Documents and link updated Documents to the Project entity.
   */
  public uploadProjectDocuments(): void {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.SIDEPANEL,
      NavigateToEnum.UPLOAD_DIALOG,
      EntityTypeEnum.PROJECT,
      this.project.id
    );
    super.uploadProjectDocuments();
  }
}
