<mat-toolbar #toolBarOrigin="cdkOverlayOrigin" cdkOverlayOrigin class="topbar telative" color="primary">
  <!-- ============================================================== -->
  <!-- app application-header component - style you can find in application-header.scss / user-config-header.component.ts-->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Sidebar toggle-->
  <!-- ============================================================== -->
  <button (click)="toggleSideNav()" *ngIf="!data?.hideSidenav" [matTooltip]="'TOOLTIP.SIDEBAR' | translate"
          class="snav-btn" mat-icon-button>
    <i class="mat-icon sidenav-icon mdi mdi-menu"></i>
  </button>
  <!-- ============================================================== -->
  <!-- Logo - organization name -->
  <!-- ============================================================== -->
  <div class="navbar-header fx-layout-row fx-justify-start fx-align-center">
    <!--<a class="navbar-brand" href="index.html">-->
    <a [routerLink]="['/']" class="navbar-brand" (click)="onNavigation('TITLE.HOME')">
      <!-- Logo icon -->
      <img alt="mya" class="mya-logo" ngSrc="assets/images/mya_icon.svg" height="16" width="16">
    </a>
  </div>
  <!-- ============================================================== -->
  <!-- Extensible separator between headers to keep them at each end  -->
  <!-- ============================================================== -->
  <span class="navbar-organization-info fx-flex-grow fx-justify-start">
    <!-- Organization name hidden on TB Dashboard to avoid displaying it after an organization is selected -->
    <div *ngIf="this.organizationName && this.data.title !== 'TITLE.ORGANIZATIONS'"
         class="fx-fill fx-layout-row fx-justify-center fx-align-center">
      <img *ngIf="logo" [src]="logo" class="navbar-logo" [alt]="'Logo ' + organizationName">
      <div id="organization-name" [ngClass]="{'fx-hide-lt-sm': logo}">{{ this.organizationName }}</div>
    </div>
  </span>
  <!-- ============================================================== -->
  <!-- help popup menu -->
  <!-- ============================================================== -->
  <button #aide [matMenuTriggerFor]="helpMenu" [matTooltip]="'TOOLTIP.HELP' | translate" mat-icon-button
          (menuOpened)="onNavigation('TOOLTIP.HELP')">
    <i class="mat-icon help-icon mdi mdi-help-circle"></i>
  </button>
  <mat-menu #helpMenu [overlapTrigger]="false" id="helpMenu" yPosition="below">
    <a [href]="['https://www.tbmaestro.com/guide-mya/']" [target]="['_blank']" mat-menu-item
       (click)="onNavigation('MENU.USER_GUIDE')">
      <span class="mat-icon mdi mdi-book-open-variant"></span>
      <span>{{ 'MENU.USER_GUIDE' | translate }}</span>
    </a>
    <a [href]="['https://tbmaestro.atlassian.net/servicedesk']" [target]="['_blank']" mat-menu-item
       (click)="onNavigation('MENU.SERVICE_DESK')">
      <span class="mat-icon mdi mdi-forum"></span>
      <span>{{ 'MENU.SERVICE_DESK' | translate }}</span>
    </a>
  </mat-menu>

  <!-- ============================================================== -->
  <!-- rights management popup menu -->
  <!-- ============================================================== -->
  <button #rights="matTooltip" *ngIf="appManager.currentOrganization && accessManager.hasAccess(Permission.VIEW_CLIENT)"
          [routerLink]="['/organization', appManager.currentOrganization?.id, 'clients']"
          [matTooltip]="'TOOLTIP.MANAGE_RIGHTS' | translate" mat-icon-button
          (click)="onNavigation('TOOLTIP.MANAGE_RIGHTS')">
    <i class="mat-icon help-icon mdi mdi-shield-account"></i>
  </button>

  <!-- ============================================================== -->
  <!-- retool apps popup menu -->
  <!-- ============================================================== -->
  <ng-container *ngIf="appManager.currentOrganization && accessManager.hasAccess(Permission.VIEW_RETOOL_APP)">
    <ng-container *ngIf="retoolApps$ | async as retoolApps">
      <button [matMenuTriggerFor]="retoolMenu"
              (menuOpened)="onNavigation('TOOLTIP.RETOOL_APP')"
              [matTooltip]="'TOOLTIP.RETOOL_APP' | translate" mat-icon-button
              (click)="onNavigation('TOOLTIP.RETOOL_APP')"
              [disabled]="!retoolApps?.length">
        <i class="mat-icon help-icon mdi mdi-toolbox-outline"></i>
      </button>
      <mat-menu #retoolMenu [overlapTrigger]="false" id="retoolMenu" yPosition="below">
        <button *ngFor="let retoolApp of retoolApps" mat-menu-item (click)="onNavigation('MENU.RETOOL_APP')"
                [routerLink]="['/organization', appManager.currentOrganization?.id, 'retool-app', retoolApp.id]">
          <span [style]="{color: retoolApp.color}" class="mat-icon mdi mdi-application-outline msm"></span>
          <span>{{ retoolApp.name }}</span>
        </button>
      </mat-menu>
    </ng-container>
  </ng-container>

  <!-- ============================================================== -->
  <!-- org popup menu -->
  <!-- ============================================================== -->
  <button #org="matTooltip"
          *ngIf="appManager.currentOrganization &&
          (accessManager.hasAccessToOrganization(appManager.currentOrganization?.id) || accessManager.isSuperAdmin()) &&
          (accessManager.hasAccess(Permission.MANAGE_CONFIG_ORGANIZATION) || accessManager.hasAccess(Permission.VIEW_RISK) ||
          accessManager.hasAccess(Permission.VIEW_CRV) || accessManager.hasAccess(Permission.VIEW_SNAPSHOT) ||
          accessManager.hasAccess(Permission.VIEW_DOCUMENT))"
          [matMenuTriggerFor]="orgMenu" [matTooltip]="'TOOLTIP.CONFIGURATION' | translate" mat-icon-button
          (menuOpened)="onNavigation('TOOLTIP.CONFIGURATION')">
    <i class="mat-icon help-icon mdi mdi-cog"></i>
  </button>
  <mat-menu #orgMenu [overlapTrigger]="false" id="orgMenu" yPosition="below">
    <button *ngIf="accessManager.hasAccess(Permission.MANAGE_CONFIG_ORGANIZATION)"
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'general']"
            mat-menu-item (click)="onNavigation('MENU.ORG_GENERAL_INFO')">
      <span class="mat-icon mdi mdi-image-text"></span>
      <span>{{ 'MENU.ORG_GENERAL_INFO' | translate }}</span>
    </button>
    <button *ngIf="accessManager.hasAccess(Permission.MANAGE_CONFIG_ORGANIZATION)"
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'storage']"
            mat-menu-item (click)="onNavigation('MENU.ORG_STORAGE_SPACE')">
      <span class="mat-icon mdi mdi-database msm"></span>
      <span>{{ 'MENU.ORG_STORAGE_SPACE' | translate }}</span>
    </button>
    <button
      *ngIf="accessManager.hasAllNeededPermissions([Permission.MANAGE_CONFIG_ORGANIZATION, Permission.EDIT_BI_REPORT])"
      [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'BI-reports']"
      mat-menu-item (click)="onNavigation('MENU.BI_REPORTS')">
      <span class="mat-icon mdi mdi-file-chart"></span>
      <span>{{ 'MENU.BI_REPORTS' | translate }}</span>
    </button>
    <button *ngIf="accessManager.hasAccess(Permission.VIEW_CRV)"
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'crv']"
            mat-menu-item (click)="onNavigation('MENU.CRV_DETAILS')">
      <span class="mat-icon mdi mdi-home-analytics"></span>
      <span>{{ 'MENU.CRV_DETAILS' | translate }}</span>
    </button>
    <button
      *ngIf="accessManager.hasAllNeededPermissions([Permission.MANAGE_CONFIG_ORGANIZATION, Permission.MANAGE_ASSET_TYPE])"
      [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'types']"
      mat-menu-item (click)="onNavigation('MENU.ASSET_TYPES')">
      <span class="mat-icon mdi mdi-office-building-cog-outline"></span>
      <span>{{ 'MENU.ASSET_TYPES' | translate }}</span>
    </button>
    <button *ngIf="accessManager.hasAccess(Permission.VIEW_RISK)"
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'risks']"
            mat-menu-item (click)="onNavigation('MENU.RISKS')">
      <span class="mat-icon mdi mdi-alert"></span>
      <span>{{ 'MENU.RISKS' | translate }}</span>
    </button>
    <button *ngIf="accessManager.hasAllNeededPermissions([Permission.EDIT_CREDENTIALS_JMAP, Permission.VIEW_JMAP])"
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'jmap']"
            mat-menu-item (click)="onNavigation('MENU.JMAP')">
      <span class="mat-icon mdi mdi-map"></span>
      <span>{{ 'MENU.JMAP' | translate }}</span>
    </button>
    <button *ngIf="accessManager.hasAccess(Permission.VIEW_SNAPSHOT)"
            mat-menu-item
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'snapshots']"
            (click)="onNavigation('MENU.SNAPSHOTS')">
      <span class="mat-icon mdi mdi-folder-multiple"></span>
      <span>{{ 'MENU.SNAPSHOTS' | translate }}</span>
    </button>
    <button *ngIf="accessManager.hasAccess(Permission.VIEW_DOCUMENT)"
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'files']"
            mat-menu-item (click)="onNavigation('MENU.FILE_REPOSITORY')">
      <span class="mat-icon mdi mdi-file-cloud-outline"></span>
      <span>{{ 'MENU.FILE_REPOSITORY' | translate }}</span>
    </button>
    <button *ngIf="accessManager.hasAllNeededPermissions([Permission.MANAGE_RETOOL_APP, Permission.VIEW_RETOOL_APP])"
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'retool-apps']"
            mat-menu-item (click)="onNavigation('MENU.RETOOL_APP')">
      <span class="mat-icon mdi mdi-toolbox-outline"></span>
      <span>{{ 'MENU.RETOOL_APP' | translate }}</span>
    </button>
    <button *ngIf="accessManager.hasAllNeededPermissions([Permission.VIEW_ASSET_AUDIT])"
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'simplified-audit']"
            mat-menu-item (click)="onNavigation('MENU.SIMPLIFIED_AUDIT')">
      <span class="mat-icon mdi mdi-tools"></span>
      <span>{{'MENU.SIMPLIFIED_AUDIT' | translate }}</span>
    </button>
    <button *ngIf="accessManager.isSuperAdmin() || (accessManager.isUserRole(UserRoleEnum.ADMIN_TB) && accessManager.hasAccessToOrganization(appManager.currentOrganization?.id))"
            [routerLink]="['/organization', appManager.currentOrganization?.id, 'configuration', 'external-links']"
            mat-menu-item (click)="onNavigation('MENU.EXTERNAL_LINK')">
      <span class="mat-icon mdi mdi-link-variant"></span>
      <span>{{'MENU.EXTERNAL_LINK' | translate }}</span>
    </button>
  </mat-menu>
  <!-- ============================================================== -->
  <!-- Profile popup menu -->
  <!-- ============================================================== -->
  <button [matMenuTriggerFor]="profile"
          [matTooltip]="'TOOLTIP.PROFILE' | translate"
          class="profile-pic">
    <img *ngIf="authService.user$ | async as user"
         class="profile-picture"
         [ngSrc]="user.picture"
         width="40"
         height="40"
         alt="{{'TOOLTIP.PROFILE' | translate}}">
  </button>
  <mat-menu #profile [overlapTrigger]="false" id="profileMenu" yPosition="below">
    <button [routerLink]="['/profile']" mat-menu-item (click)="onNavigation('MENU.MY_PROFILE')">
      <span class="mat-icon mdi mdi-account-box"></span>
      <span>{{ 'MENU.MY_PROFILE' | translate }}</span>
    </button>
    <restricted-menu-item [permissions]="[Permission.VIEW_SUBSCRIPTION]"
                          [preconditions]="appManager.currentOrganization !== undefined"
                          [routerLink]="['/organization', appManager.currentOrganization?.id, 'subscription']">
      <span class="mat-icon mdi mdi-credit-card"></span>
      <span>{{ 'MENU.MY_SUBSCRIPTION' | translate }}</span>
    </restricted-menu-item>
    <!--<button mat-menu-item>
      <span class="mat-icon mdi mdi-book-open"></span>
      <span>{{'MENU.TERMS_CONDITIONS' | translate }}</span>
    </button>!-->
    <button (click)="logout()" mat-menu-item>
      <span class="mat-icon mdi mdi-logout"></span>
      <span>{{ 'MENU.LOGOUT' | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
