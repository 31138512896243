import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';

export const checkDateChronologyValidator: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
  const startDate = group.controls['startDate'];
  const endDate = group.controls['endDate'];

  const datesChronologyResult = dayjs(startDate.value).startOf('day').isAfter(dayjs(endDate.value)) ? { AFTER_DATE: true } : null;
  startDate.setErrors(datesChronologyResult);
  endDate.setErrors(datesChronologyResult);
  return datesChronologyResult;
};

