import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Chart, ChartOptions, Dashboard } from '@app/core/model/entities/dashboard/dashboard';
import {
  CreateDashboardModalComponent
} from '@app/shared/components/dashboard/create-dashboard-modal/create-dashboard-modal.component';
import { setValue } from '@app/shared/extra/utils';
import { FieldFormatTypePipe } from '@app/shared/pipes/field-format-type.pipe';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { ChartModel, ChartType, CreateRangeChartParams } from 'ag-grid-community';
import {
  AgAxisLabelFormatterParams,
  AgPieSeriesLabelFormatterParams
} from 'ag-grid-enterprise/dist/lib/chart/agChartOptions';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { DocumentNode } from 'graphql';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';


@Injectable()
export class DashboardService {
  public currentDashboard: Dashboard;
  public sidePanelToggleSubject: BehaviorSubject<Dashboard[]> = new BehaviorSubject(null);
  public chartModels: ChartModel[];

  private applyDashboardSubject = new Subject<Dashboard>();
  private changeDashboardSubject = new Subject<Dashboard>();
  private updateDashboardSubject = new Subject<Dashboard>();
  private deleteDashboardSubject = new Subject<Dashboard>();
  private readonly dashboardGraphqlFragment: DocumentNode = gql`
    fragment DashboardInfo on Dashboard {
      id
      name
      entityType
      isDefault
      chartsList
    }
  `;

  constructor(private generalService: GeneralService,
              private appManager: AppManager,
              private analyticsService: AnalyticsService,
              private popupManager: PopupManager,
              private snackbarManager: SnackbarManager,
              private translate: TranslateService,
              public fieldFormatPipe: FieldFormatTypePipe) {
  }

  /**
   * Emits a new Dashboard to apply as the current one after its creation.
   */
  public get dashboardApplied$(): Observable<Dashboard> {
    return this.applyDashboardSubject.asObservable();
  }

  /**
   * Emits the previous Dashboard applied after a change of current dashboard.
   */
  public get dashboardReplaced$(): Observable<Dashboard> {
    return this.changeDashboardSubject.asObservable();
  }

  /**
   * Emits a Dashboard whenever it has been updated.
   */
  public get dashboardUpdated$(): Observable<Dashboard> {
    return this.updateDashboardSubject.asObservable();
  }

  /**
   * Emits a Dashboard whenever it has been deleted.
   */
  public get dashboardDeleted$(): Observable<Dashboard> {
    return this.deleteDashboardSubject.asObservable();
  }

  /**
   * Replace current dashboard by a new one.
   * @param dashboard New current dashboard
   */
  public changeDashboard(dashboard: Dashboard): void {
    const previousDashboard = this.currentDashboard;
    this.currentDashboard = dashboard;
    this.currentDashboard.isModified = false;
    this.changeDashboardSubject.next(previousDashboard);
  }

  /**
   * Make an API request to fetch Dashboards linked to the current Organization and to an entity type specified in param.
   * @param entityType Type of the entity which is associated to the dashboards
   * @return Observable emitting a list of all Dashboards.
   */
  public loadDashboards(entityType: string): Observable<Dashboard[]> {
    const QUERY = gql`
      query DashboardsByOrganizationIdAndEntityType($organizationId: String!, $entityType: String!) {
        dashboardsByOrganizationIdAndEntityType(organizationId:$organizationId, entityType:$entityType){
          ...DashboardInfo
        }
      }
      ${this.dashboardGraphqlFragment}
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      entityType
    };
    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => {
          return plainToInstance(Dashboard, response.data['dashboardsByOrganizationIdAndEntityType'] as Dashboard[]);
        })
      );
  }

  /**
   * Return a copy of the current dashboard with the current options and configs found in chart models.
   * @return {Dashboard} Copy of current dashboard updated with the current chart models
   * @private
   */
  private copyCurrentDashboard(): Dashboard {
    const copyDashboard = Object.assign({}, this.currentDashboard);
    copyDashboard.charts = copyDashboard.charts.map(chart => {
      const copy = Object.assign({}, chart);
      copy.options = Object.assign({}, copy.options);
      copy.config = undefined;
      return copy;
    });
    this.chartModels.forEach(chartModel => {
      const index = copyDashboard.charts.findIndex(chart => chart.chartRef.chartId == chartModel.chartId);
      if (index >= 0) {
        const themeOverrides = Object.assign(
          this.currentDashboard.charts[index].config.chartThemeOverrides,
          chartModel.chartOptions
        );
        copyDashboard.charts[index].config = <CreateRangeChartParams>{
          chartType: chartModel.chartType,
          cellRange: chartModel.cellRange,
          aggFunc: chartModel.aggFunc,
          chartThemeName: chartModel.chartThemeName,
          chartThemeOverrides: themeOverrides
        };
      }
    });

    this.currentDashboard.isModified = false;
    return copyDashboard;
  }

  /**
   * Save current dashboard state and call the API to update it.
   * @return Observable emitting the updated Dashboard once the update is completed
   */
  public updateDashboard(): Observable<Dashboard> {
    this.currentDashboard.charts = this.copyCurrentDashboard().charts;

    const updateDashboardInput = {
      dashboardId: this.currentDashboard.id,
      dashboardInput: {
        name: this.currentDashboard.name,
        charts: this.currentDashboard.charts.map(chart => {
          return {
            options: chart.options,
            config: chart.config
          };
        })
      }
    };

    const DASHBOARD_MUTATION = gql`
      mutation UpdateDashboard($updateDashboardInput: UpdateDashboardInput!) {
        updateDashboard(updateDashboardInput: $updateDashboardInput){
          ...DashboardInfo
        }
      }
      ${this.dashboardGraphqlFragment}
    `;
    const MUTATION_VAR = {
      updateDashboardInput: updateDashboardInput
    };
    return this.generalService.set(DASHBOARD_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => {
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DASHBOARD_UPDATED'));
          const dashboard = plainToInstance(Dashboard, response.data['updateDashboard'] as Dashboard);
          this.updateDashboardSubject.next(dashboard);
          return dashboard;
        })
      );
  }

  /**
   * Open dialog to create a dashboard
   */
  public openCreateDashboardDialog(): void {
    const dialogRef = this.popupManager.showGenericPopup(
      CreateDashboardModalComponent,
      PopupSize.SMALL,
      this.currentDashboard.entityType
    );
    dialogRef.afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          const analyticsEvent = new AnalyticsEvent(ActionEnum.CREATE, EntityTypeEnum.DASHBOARD);

          if (dialogResponse === 'yes') {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE});
            const dataObject = dialogRef.componentInstance.getGeneratedObject();
            this.analyticsService.trackEvent(analyticsEvent);
            return this.createDashboard(dataObject.dashboardName);
          } else {
            analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
            this.analyticsService.trackEvent(analyticsEvent);
            return EMPTY;
          }
        }))
      .subscribe(() => {this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DASHBOARD_ADDED'));});
  }

  /**
   * Open dialog to delete a dashboard.
   * @param dashboard Dashboard to delete.
   */
  public openDeleteDashboardDialog(dashboard: Dashboard): Observable<any> {
    const dialogRef = this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant('TITLE.DELETE_DASHBOARD'),
      dialogMessage: this.translate.instant('MESSAGE.DELETE_DASHBOARD'),
      okText: this.translate.instant('BUTTON.DELETE'),
      type: 'warning'
    });
    return dialogRef.afterClosed().pipe(
      switchMap(dialogResponse => {
        const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.DASHBOARD);
        if (dialogResponse === 'yes') {
          analyticsEvent.addProperties({
            [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
            [AnalyticsKeyEnum.ENTITY_ID]: dashboard.id
          });
          this.analyticsService.trackEvent(analyticsEvent);
          return this.deleteDashboard(dashboard);
        } else {
          analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
          this.analyticsService.trackEvent(analyticsEvent);
          return EMPTY;
        }
      })
    );
  }

  /**
   * Make an API request to create new Dashboard with the provided data by copying the current one.
   * @param dashboardName Name of the new dashboard.
   * @return Observable emitting the new Dashboard that was created.
   */
  public createDashboard(dashboardName: string): Observable<Dashboard> {
    const copyDashboard = this.copyCurrentDashboard();
    const createDashboardInput = {
      organizationId: this.appManager.currentOrganization.id,
      entityType: copyDashboard.entityType,
      dashboardInput: {
        name: dashboardName,
        charts:
          copyDashboard.charts.map(chart => {
            return {
              options: {},
              config: chart.config
            };
          })
      }
    };

    const COMBINED_MUTATION = gql`
      mutation CreateDashboard($createDashboardInput: CreateDashboardInput!){
        createDashboard(createDashboardInput: $createDashboardInput) {
          ...DashboardInfo
        }
      }
      ${this.dashboardGraphqlFragment}
    `;
    const MUTATION_VAR = {
      createDashboardInput: createDashboardInput
    };
    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Dashboard, response.data['createDashboard'] as Dashboard)),
        tap(newDashboard => {
            this.applyDashboardSubject.next(newDashboard);
          }
        )
      );
  }

  /**
   * Make an API request to delete a Dashboard.
   * @param dashboard Dashboard to delete.
   */
  public deleteDashboard(dashboard: Dashboard): Observable<any> {
    const COMBINED_MUTATION = gql`
      mutation DeleteDashboard($id: String!) {
        deleteDashboard(id: $id)
      }
    `;
    const MUTATION_VAR = {
      id: dashboard.id
    };
    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR).pipe(
      tap(() => this.deleteDashboardSubject.next(this.currentDashboard))
    );
  }

  /**
   * Build the chart tooltip template for a series type
   * @param params Series tooltip renderer params containing names and values to show in tooltip
   * @param options Chart options which might contain suffixes or formatTypes
   * @param seriesType Type of the chart series
   * @return {string} Formatted tooltip result
   * @private
   */
  private buildTooltipTemplate(params: any, options: ChartOptions, seriesType: ChartType): string {
    const undefinedValue = this.translate.instant('LABEL.UNDEFINED_CHART_CATEGORY');
    const tooltipTemplate = (xLabel, xValue, yLabel, yValue): string => {
      xValue = (xValue === ' ') ? undefinedValue : xValue;
      yValue = (yValue === ' ') ? undefinedValue : yValue;
      return '<b>' + xLabel + ':</b> ' + xValue + '<br/>' + '<b>' + yLabel + ':</b> ' + yValue;
    };

    return seriesType === 'pie' ?
      //Pie series tooltip template
      tooltipTemplate(
        params.calloutLabelName,
        params.datum[params.calloutLabelKey] ?? ' ',
        params.angleName,
        [<string>this.fieldFormatPipe.transform(params.angleValue, options?.formatTypes?.y), options?.suffix?.y]
          .join(' ')
      ) :
      //Cartesian series tooltip template
      tooltipTemplate(
        params.xName,
        [<string>this.fieldFormatPipe.transform(params.xValue, options?.formatTypes?.x), options?.suffix?.x].join(' '),
        params.yName,
        [<string>this.fieldFormatPipe.transform(params.yValue, options?.formatTypes?.y), options?.suffix?.y].join(' ')
      );
  }

  /**
   * Generate functions to format axes and tooltip values with charts' options such as suffixes
   * @param chart Chart to update by adding formatters
   * @return {Chart} Chart with updated theme overrides options
   */
  public addFormatterOptions(chart: Chart): Chart {
    const seriesType: ChartType[] = ['scatter', 'pie', 'column', 'area', 'bar', 'line', 'histogram'];
    const chartThemeOverrides = chart.config.chartThemeOverrides || {};

    //Build tooltip renderer
    seriesType.forEach(type => {
      const tooltipRenderer = (params): any => {
        return {content: this.buildTooltipTemplate(params, chart.options, type)};
      };
      const tooltipRendererPath = [type, 'series', 'tooltip', 'renderer'];
      setValue(chartThemeOverrides, tooltipRendererPath, tooltipRenderer);
    });

    //Build number label formatter for cartesian charts
    const cartesianNumberFormatter = (params: AgAxisLabelFormatterParams): any => {
      const number = this.fieldFormatPipe.transform(params.value, chart.options?.formatTypes?.y);
      return number !== '' ? [number, chart.options?.suffix?.y].join(' ') :
        this.translate.instant('LABEL.UNDEFINED_CHART_CATEGORY');
    };
    const cartesianNumberFormatterPath = ['cartesian', 'axes', 'number', 'label', 'formatter'];
    setValue(chartThemeOverrides, cartesianNumberFormatterPath, cartesianNumberFormatter);

    //Build number label formatter for polar charts
    const polarNumberFormatter = (params: AgPieSeriesLabelFormatterParams<any>): any => {
      const number = this.fieldFormatPipe.transform(params.datum[params.sectorLabelKey], chart.options?.formatTypes?.y);
      return number !== '' ? [number, chart.options?.suffix?.y].join(' ') :
        this.translate.instant('LABEL.UNDEFINED_CHART_CATEGORY');
    };
    const polarCategoryFormatterPath = ['polar', 'series', 'pie', 'sectorLabel', 'formatter'];
    setValue(chartThemeOverrides, polarCategoryFormatterPath, polarNumberFormatter);

    return chart;
  }
}
