<div class="fx-fill fx-layout-col">
  <div class="sheet-header">
    <div class="fx-layout-row">
      <div class="sheet-title">
        <form-builder *ngIf="equipment"
                      formId="equipment_sidebar_header"
                      [eventsOrigin]="eventsOrigin"
                      [permissionsForEdition]="permissionsForEdition">
        </form-builder>
      </div>
      <div class="fx-flex-48">
        <div class="btn-container">
          <button (click)="closeEquipmentSidebar()" [disableRipple]="true"
                  class="fx-layout-col fx-justify-center fx-align-center"
                  id="close-sheet-button" mat-icon-button>
            <i class="mdi mdi-close sm"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="fx-layout-row">
      <restricted-icon-button (onClick)="uploadEquipmentDocuments()"
                              [class]="'mdi mdi-paperclip sm'"
                              [matTooltip]="'BUTTON.ADD_DOCUMENT' | translate"
                              [permissions]="[Permission.EDIT_EQUIPMENT, Permission.CREATE_DOCUMENT]"
                              [id]="'upload-equipment-document-button'">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="uploadEquipmentPictures()"
                              [class]="'mdi mdi-image-plus sm'"
                              [matTooltip]="'BUTTON.ADD_PICTURE' | translate"
                              [permissions]="[Permission.EDIT_EQUIPMENT, Permission.CREATE_DOCUMENT]"
                              [id]="'upload-equipment-picture-button'">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="deleteEquipment()"
                              [class]="'mdi mdi-delete sm'"
                              [id]="'delete-equipment-button'"
                              [matTooltip]="'BUTTON.DELETE' | translate"
                              [permissions]="[Permission.DELETE_EQUIPMENT]">
      </restricted-icon-button>
      <restricted-icon-button (onClick)="duplicateEquipments()"
                              [class]="'mdi mdi-content-copy sm'"
                              [matTooltip]="'BUTTON.DUPLICATE' | translate"
                              [permissions]="[Permission.CREATE_EQUIPMENT]"
                              [id]="'duplicate-equipment-button'">
      </restricted-icon-button>

      <span class="fx-flex"></span>

      <div class="equipment-sheet-link">
        <span (click)="navigateToEquipmentSheet()">{{ 'BUTTON.SHOW_EQUIPMENT_SHEET' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="sidebar-sheet fx-flex-grow" cdk-scrollable>
    <div class="sidebar-picture-container fx-layout-col fx-justify-center fx-align-center">
      <img id="sidebar-picture"
           [src]="images[0]?.url || appConfig.PLACEHOLDER_CAROUSEL"
           [alt]="images[0] ? images[0].alt : 'LABEL.NO_EQUIPMENT_PICTURE' | translate"/>
    </div>
    <div class="page-sidebar">
      <div class="form-container">
        <form-builder *ngIf="equipment"
                      [formId]="formId"
                      [eventsOrigin]="eventsOrigin"
                      [permissionsForEdition]="permissionsForEdition">
        </form-builder>
      </div>
      <div class="document-title fx-layout-row fx-justify-start fx-align-center">
        <span class="fx-flex-30-percent"> {{'LABEL.DOCUMENT' | translate}} </span>
        <restricted-text-button
          (onClick)="uploadEquipmentDocuments()"
          [id]="'equipments-upload-document-button'"
          [permissions]="[Permission.EDIT_EQUIPMENT, Permission.CREATE_DOCUMENT]"
          class="btn-upload-document">
          {{'BUTTON.ADD_DOCUMENT' | translate}}
        </restricted-text-button>
      </div>
      <div *ngFor="let document of equipment?.documents" class="document-content">
        <a *ngIf="accessManager.hasAccess(Permission.EXPORT_DOCUMENT); else noExportPermission"
           (click)="fileService.downloadFile(document.id)">
          <i class="mdi mdi-file-outline xs"></i>
          <span class="file-name-label">{{ document.name }}</span>
        </a>
        <ng-template #noExportPermission>
          <span class="file-name-label">{{ document.name }}</span>
        </ng-template>
        <span (click)="deleteEquipmentDocument(document)"
              [permission-lock]="[Permission.EDIT_EQUIPMENT, Permission.DELETE_DOCUMENT]" class="delete-document-icon">
            <i class="mdi mdi-delete xs"></i>
          </span>
      </div>
    </div>
  </div>
</div>
