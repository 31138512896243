import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum, NavigateToEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Asset, IRelatedAsset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { Entity, IRelatedEntity } from '@app/core/model/entities/entity';
import {
  FIELD_ASYNC_PRECONDITIONS_INJECTION,
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import { FieldStateMode } from '@app/shared/components/fields/abstract.field';
import {
  EntityChipsFieldBuilderComponent
} from '@app/shared/components/fields/chips-field-builder/entity-chips-field-builder/entity-chips-field-builder';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { getValue, simplifyStringForSearch } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { EMPTY, merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface SuggestedAsset {
  asset: RelatedAsset;
  checked: boolean;
}

@Component({
  selector: 'assets-chips-field-builder',
  templateUrl: './assets-chips-field-builder.component.html',
  styleUrls: ['../chips-field-builder.component.scss', './entity-chips-field-builder.scss']
})
export class AssetsChipsFieldBuilderComponent extends EntityChipsFieldBuilderComponent implements OnInit, AfterViewInit {

  @ViewChild('inputField') public declare inputField: ElementRef<HTMLInputElement>;
  @ViewChild('suggestions') public declare suggestions: MatAutocomplete;
  @ViewChild('trigger') public trigger: MatAutocompleteTrigger;
  public allSelected = false;
  public assets: Map<string, SuggestedAsset> = new Map();
  // Current entity assets, avoid getValue call in the template (bad for performance)
  protected assetValues: RelatedAsset[] = [];
  public asyncPreconditionsForEdition = false;
  protected selectionChangedSubject = new Subject<void>();

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) public preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              @Inject(FIELD_ASYNC_PRECONDITIONS_INJECTION) public asyncPreconditionsForEdition$: Observable<boolean>,
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService,
              fb: FormBuilder,
              private assetsService: AssetsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService,
      fb
    );
  }

  /**
   * Load assets data. Then, create the form, and add the chips.
   */
  public ngOnInit(): void {
    this.assetValues = this.getFieldValue();
    this.form = this.fb.group({
      entityName: this.fb.control(''),
      selectedAssetIds: this.fb.control(
        this.assetValues.map(asset => asset.id),
        Validators.required
      )
    });

    // Initialise the field in the registry
    this.setFieldValue(this.fieldInitValue);
    this.setFieldInitialValue(this.fieldInitValue);
    this.getNextState();

    // Load Assets
    this.asyncPreconditionsForEdition$
      .pipe(
        takeUntil(this.destroy$),
        tap(preconditionsToEdit => {
          this.asyncPreconditionsForEdition = preconditionsToEdit;
          if (!preconditionsToEdit) {
            this.form.get('entityName').disable();
            this.form.get('selectedAssetIds').disable();
          }
        }),
        switchMap(preconditionsToEdit => {
          return preconditionsToEdit ? this.assetsService.getAssets(this.entity.entityType, false) : EMPTY;
        })
      )
      .subscribe((assets) => {
        // Select assets already related to the project
        const selectedAssets: IRelatedEntity[] = [...getValue(this.entity, this.fieldConfig.fieldPath)] ?? [];
        this.assets = new Map(assets.map(asset => [
          asset.id,
          {
            asset: asset.toRelatedAsset(),
            checked: selectedAssets.map(selectedAsset => selectedAsset.id).includes(asset.id)
          }
        ]));
        this.selectionChangedSubject.next();
      });

    // Apply filter on entities
    const filteredAssets$ = this.form.get('entityName').valueChanges.pipe(
      startWith(''),
      debounceTime(50),
      filter(value => value !== void 0 && this.preconditionsForEdition),
      map(value => this.getFilteredAssets(value))
    );

    //Update filtered assets' checkbox after selection changes
    const updateFilteredAssetsSelection$ = this.selectionChangedSubject.asObservable().pipe(
      map(() => {
        //Update selected assets
        this.selectedEntities = Array.from(this.assets.values())
          .filter(asset => asset.checked)
          .map(suggestedAsset => suggestedAsset.asset);
        this.setFieldValue(this.selectedEntities.map(relatedAsset => relatedAsset.id));
        this.form.get('selectedAssetIds').setValue(this.selectedEntities.map(asset => asset.id));

        // Update filtered assets' checkboxes
        const filteredAssets = this.getFilteredAssets(this.form.get('entityName').value);
        return filteredAssets.map(suggestedAsset => {
          suggestedAsset.checked = this.isChecked(suggestedAsset.asset.id);
          return suggestedAsset;
        });
      })
    );

    this.filteredEntities = merge(filteredAssets$, updateFilteredAssetsSelection$).pipe(
      takeUntil(this.destroy$),
      tap(filteredAssets => {
        this.allSelected = Array.from(filteredAssets.values()).every(suggestedAsset => !!suggestedAsset.checked);
      })
    );
  }

  public ngAfterViewInit(): void {
    this.setupHooks();
    this.getFieldGroupState().pipe(
      takeUntil(this.destroy$),
      filter(state => state === FieldStateMode.ERROR)
    ).subscribe(_ => {
      this.cancel();
      // Reset selected assets
      this.selectedEntities = Array.from(this.assets.values())
        .filter(asset => asset.checked)
        .map(suggestedAsset => suggestedAsset.asset);
      this.setFieldValue(this.selectedEntities.map(relatedAsset => relatedAsset.id));
      this.form.get('selectedAssetIds').setValue(this.selectedEntities.map(asset => asset.id));
    });
  }

  /**
   * Get the field value to display in read mode.
   * @return any
   */
  public getFieldValue(): RelatedAsset[] {
    return getValue(this.entity, this.fieldConfig.fieldPath)
        ?.map((asset: IRelatedAsset) => new RelatedAsset(asset))
      ?? [];
  }


  /**
   * Filter used by the autocomplete input.
   * @param value the input value.
   * @return SuggestedAsset[] Filtered assets with their checkbox values.
   * @protected
   */
  protected getFilteredAssets(value: Asset | string = ''): SuggestedAsset[] {
    return Array.from(this.assets.values())
      .filter((suggestedAsset: SuggestedAsset) =>
        simplifyStringForSearch(suggestedAsset.asset.toString())
          .includes(simplifyStringForSearch(value.toString()))
      );
  }

  /**
   * Add or remove an asset to the selection.
   * @param asset Asset to select or deselect.
   * @protected
   */
  protected selectToggle(asset: RelatedAsset): void {
    this.assets.set(asset.id, {asset: asset, checked: !this.isChecked(asset.id)});
    this.selectionChangedSubject.next();
  }

  /**
   * Select or deselect all filtered assets when the user clicks on selectAll checkbox.
   * @param filteredAssets All assets in the suggestion list associated to a boolean which indicates if their checkbox is currently checked or not.
   * @protected
   */
  protected selectAllToggle(filteredAssets: SuggestedAsset[]): void {
    filteredAssets.forEach(suggestedAsset => {
      this.assets.set(suggestedAsset.asset.id, {asset: suggestedAsset.asset, checked: !this.allSelected});
    });
    this.selectionChangedSubject.next();
  }

  /**
   * Whether the checkbox of an asset is currently checked.
   * @param assetId ID of the asset to check
   * @return True if the asset's checkbox is checked, false otherwise.
   * @private
   */
  private isChecked(assetId: string): boolean {
    return this.assets.get(assetId).checked;
  }

  /**
   * Navigate to the Dashboard of the related Asset
   * @param assetId
   */
  public async navigateToAssetDashboard(assetId: string): Promise<void> {
    this.analyticsService.trackNavigationEvent(
      EventOriginEnum.FIELD_CLICK,
      NavigateToEnum.SHEET,
      EntityTypeEnum.ASSET,
      assetId
    );
    await this.assetsService.navigateToAssetSheet(assetId, 'dashboard');
  }

  /**
   * Keep the autocomplete opened after each item is picked.
   */
  public keepPanelOpen(): void {
    requestAnimationFrame(() => {
      this.trigger.openPanel();
      this.inputField.nativeElement.focus();
    });
  }

  /**
   * Stop event propagation and call selectToggle method when a mat-option is clicked.
   */
  public optionClicked(assets: SuggestedAsset[], event: MouseEvent): void {
    event.stopPropagation();

    if (assets.length === 1) {
      this.selectToggle(assets.firstItem().asset);
    } else {
      this.selectAllToggle(assets);
    }
  }

  /**
   * Reset the form and the chips to their initial state, then switch to read mode.
   */
  public cancel(): void {
    const initialAssets = [...this.assetValues] ?? [];
    this.assets.forEach((suggestedAsset, assetId, assets) =>
      assets.set(
        assetId,
        {asset: suggestedAsset.asset, checked: initialAssets.map(asset => asset.id).includes(assetId)}
      )
    );
    this.selectionChangedSubject.next();

    this.form.get('entityName').setValue('');
    this.setFieldValue(initialAssets.map(asset => asset.id));
    this.getNextState();
  }
}
