import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '@services/general.service';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { ReplaySubject, Subscription } from 'rxjs';

@Injectable()
export class NotificationManager {
  static NotificationQueryBuilder = class {
    private canBuildQuery = false;
    private userNotifications = '';
    private moduleNotifications = '';
    private subscriptionNotifications = '';
    private clientNotifications = '';
    private organizationNotifications = '';

    public subscribeToUserNotifications(): any {
      this.userNotifications = 'user { description }  \n';
      this.canBuildQuery = true;
      return this;
    }

    public subscribeToModuleNotifications(): any {
      this.moduleNotifications = 'module { description }  \n';
      this.canBuildQuery = true;
      return this;
    }

    public subscribeToSubscriptionNotifications(): any {
      this.subscriptionNotifications = 'subscription { description }  \n';
      this.canBuildQuery = true;
      return this;
    }

    public subscribeToClientNotifications(): any {
      this.clientNotifications = 'client { description }  \n';
      this.canBuildQuery = true;
      return this;
    }

    public subscribeToOrganizationNotifications(): any {
      this.organizationNotifications = 'organization { description } \n';
      this.canBuildQuery = true;
      return this;
    }

    public build(): string {
      if (!this.canBuildQuery) {
        return '';
      }

      return 'subscription notifications { \n ' +
        'notifications { \n ' +
        this.userNotifications +
        this.moduleNotifications +
        this.subscriptionNotifications +
        this.clientNotifications +
        this.organizationNotifications +
        ' \n }' +
        ' \n }';
    }
  };

  private notificationSubscription: Subscription = null;

  // Temporary solution to get new feature message
  private newFeatureMessageSubject: ReplaySubject<string> = new ReplaySubject<string>();

  constructor(private generalService: GeneralService,
              private snackbarManager: SnackbarManager,
              private translate: TranslateService,
              private analyticsService: AnalyticsService) {
  }

  // Temporary solution to get new feature message
  // TODO : Remove after notification push implementation
  public showNewFeatureMessage(): void {
    this.newFeatureMessageSubject.subscribe((message) => {
      this.snackbarManager.showNotificationSnackbar(message);
    });
  }

  public addNewFeatureMessage(message: string): void {
    this.newFeatureMessageSubject.next(message);
  }

  /***
   * Subscribes to all notifications
   *
   * @param organizationId in case of tb users this parameter can be null if the user
   *                       is in tb-dashboard until an organization is selected.
   */
  public subscribeToNotifications(organizationId: number = null): void {
    // FIXME disable subscription
    /*
    const queryHeaders = {};
    let queryBuilder = new NotificationManager.NotificationQueryBuilder();
    queryBuilder.subscribeToUserNotifications();

    if (organizationId !== null) {
      queryHeaders['organizationId'] = organizationId;
      queryBuilder = queryBuilder.subscribeToClientNotifications()
        .subscribeToModuleNotifications()
        .subscribeToOrganizationNotifications()
        .subscribeToSubscriptionNotifications();
    }

    this.doSubscribe(queryBuilder.build(), queryHeaders);
    */
  }

  /***
   * Sends the notification query to websocket
   *
   * @param query this is the query in string form instead of using the gql function
   *                because we can't construct an empty query
   * @param headers this is used to send extra data to when sending the query,
   *                for instance to send organizationId so that we get notifications
   *                only related to that organization.
   */
  private doSubscribe(query: string, headers = {}) {
    if (this.notificationSubscription != null) {
      this.notificationSubscription.unsubscribe();
    }

    if (query.length === 0) {
      return;
    }

    this.notificationSubscription = this.generalService.subscribeTo(query, {}, 'notifications', headers).subscribe((response) => {
      this.solve(response);
    });
  }

  private solve(data: any) {
    if (data.notifications.user !== void 0 && data.notifications.user !== null) {
      this.handleUserNotifications(data.notifications.user);
    }

    if (data.notifications.module !== void 0 && data.notifications.module !== null) {
      this.handleModuleNotifications(data.notifications.module);
    }

    if (data.notifications.subscription !== void 0 && data.notifications.subscription !== null) {
      this.handleSubscriptionNotifications(data.notifications.subscription);
    }

    if (data.notifications.client !== void 0 && data.notifications.client !== null) {
      this.handleClientNotifications(data.notifications.client);
    }

    if (data.notifications.organization !== void 0 && data.notifications.organization !== null) {
      this.handleOrganizationNotifications(data.notifications.organization);
    }
  }

  private handleUserNotifications(userNotification: { description: string; }) {
    this.snackbarManager.showActionSnackbar(this.translate.instant(userNotification.description));
  }

  private handleModuleNotifications(moduleNotification: { description: string; }) {
    this.snackbarManager.showActionSnackbar(this.translate.instant(moduleNotification.description));
  }

  private handleSubscriptionNotifications(subscriptionNotification: { description: string; }) {
    this.snackbarManager.showActionSnackbar(this.translate.instant(subscriptionNotification.description));
  }

  private handleClientNotifications(clientNotification: { description: string; }) {
    this.snackbarManager.showActionSnackbar(this.translate.instant(clientNotification.description));
  }

  private handleOrganizationNotifications(organizationNotification: { description: string; }) {
    this.snackbarManager.showActionSnackbar(this.translate.instant(organizationNotification.description));
  }
}
