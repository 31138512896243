<div *ngIf="currentMode === FieldMode.READ" class="read-field-container">
  <div [hidden]="!toggled" [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
       class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span *ngIf="fieldConfig.tooltip" [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div class="field-container fx-flex-100-66-percent fx-layout-col">
      <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
           [ngClass]="{'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) &&  preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
        <mat-chip-listbox *ngIf="chips?.length > 0 else empty" tabIndex="-1">
          <mat-chip-option class="clickable-field" (click)="onClickActivate($event)"
                           (keyup.enter)="onEnterActivate($event)"
                           *ngFor="let chip of chips; let i = index">
            <a (click)="navigateToEquipmentSheet(chip.id)">{{ chip.toString() }}</a>
          </mat-chip-option>
        </mat-chip-listbox>
        <ng-template #empty>
          <span>{{ appConfig.EMPTY_FIELD_VALUE }}</span>
        </ng-template>
        <span *ngIf="fieldConfig.suffixType">
          {{ fieldConfig.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
        </span>
        <span *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)" class="overlay-icon">
          <i *ngIf="preconditionsForEdition else readOnly;" class="mdi mdi-pencil selectable"></i>
          <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentMode === FieldMode.EDIT">
  <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span *ngIf="fieldConfig.tooltip" [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <ng-container *ngIf="!noAsset; else noAssetMessage">
      <ng-container *ngIf="!noEquipment; else noEquipmentMessage">
        <div #fieldContainer class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
          <form autocomplete="off" class="chips-field-builder-form" [formGroup]="form">
            <mat-form-field appearance="outline">
              <mat-label>{{ fieldConfig.label }}</mat-label>
              <mat-chip-grid #chipList>
                <mat-chip-row (removed)="remove(chip)" *ngFor="let chip of chips; let i = index"
                              [removable]="true">
                  {{ chip.toString() }}
                  <button matChipRemove>
                    <i class="mdi mdi-close-circle"></i>
                  </button>
                </mat-chip-row>
                <input #inputField formControlName="field"
                       (keyup.escape)="onEscapeCancel($event)"
                       [matAutocomplete]="suggestions"
                       [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       class="chip-input"
                       type="text">
              </mat-chip-grid>
              <span *ngIf="fieldConfig.suffixType" matTextSuffix>
                {{ fieldConfig.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
              </span>
              <mat-autocomplete #suggestions="matAutocomplete" (optionSelected)="select($event)"
                                [panelWidth]="fieldContainer.offsetWidth-90">
                <mat-option *ngFor="let fieldValue of equipments | async" [value]="fieldValue">
                  {{ fieldValue.toString() }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>
                <ng-template ngFor let-validator [ngForOf]="fieldConfig.field.validators">
                  <span *ngIf="form.get('field').hasError(validator.type)">
                    {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                  </span>
                </ng-template>
              </mat-error>
            </mat-form-field>

          </form>
          <div *ngIf="isSingleField" class="save-options">
            <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
              <i class="mdi mdi-close"></i>
            </button>
            <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                    class="button save">
              <i class="mdi mdi-check"></i>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #noEquipmentMessage>
        <div class="field-container fx-flex-100-66-percent-min-500">
          <p id="no-related_equipment-message">
            {{ 'LABEL.NO_RELATED_EQUIPMENTS' | translate }}
          </p>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #noAssetMessage>
      <div class="field-container fx-flex-100-66-percent-min-500">
        <p id="no-asset-selected-message">
          {{ 'LABEL.NO_ASSET_SELECTED' | translate }}
        </p>
      </div>
    </ng-template>
  </div>
</div>
