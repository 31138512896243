<div class="header-container fx-layout-row fx-justify-start fx-align-baseline fx-gap-10">
  <div class="inline-field-block page-title">
    <div class="inline-field-container">
      <span>{{ 'TITLE.MANAGE_RIGHTS' | translate }}</span>
    </div>
  </div>

  <ng-container *ngIf="!client?.isTb && accessManager.hasAccess(Permission.MANAGE_CLIENT)" >
    <div class="inline-field-block client-name-block inline-field-container page-title">
      <inline-edit-client (onSave)="saveClientName($event)" [control]="clientName"
                          [disabled]="client?.isTb" [full]="true" [reduxFieldLabel]="'clientName'">
        <mat-error *ngIf="clientName.hasError('required')" class="small-font text-danger support-text">
          {{'ERROR.FIELD_REQUIRED' | translate}}
        </mat-error>
        <mat-error *ngIf="clientName.hasError('isValueTaken')" class="small-font text-danger support-text">
          {{'ERROR.CLIENT_NAME_ALREADY_IN_USE' | translate}}
        </mat-error>
        <mat-error *ngIf="clientName.hasError('maxLength')" class="small-font text-danger support-text">
          {{ 'ERROR.FIELD_MAXLENGTH' | translate:{ value: appConfig.NAME_MAX_LENGTH } }}
        </mat-error>
      </inline-edit-client>
    </div>
  </ng-container>


  <div class="fx-flex"></div>
  <ng-container *ngIf="!client?.isTb && accessManager.hasAccess(Permission.MANAGE_CLIENT)">
    <div class="fx-flex-48" id="client-menu">
      <restricted-icon-button [class]="'mdi mdi-dots-vertical sm'" [id]="'client-sheet-menu-button'"
                              [matMenuTriggerFor]="menu" [permissions]="[Permission.MANAGE_CLIENT]"
                              [preconditions]="true"></restricted-icon-button>
      <mat-menu #menu="matMenu">
        <restricted-menu-item (onClick)="deleteClient()" [id]="'delete-client-menu'"
                              [permissions]="[Permission.MANAGE_CLIENT]" [preconditions]="true">
          {{'BUTTON.DELETE_CLIENT' | translate}}
        </restricted-menu-item>
      </mat-menu>
    </div>
  </ng-container>
</div>
