<div class="field-builder-container">

  <div *ngIf="currentMode === FieldMode.SAVING" class="field-custom-container">
    <div class="saving-field-container">
      <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
        </div>

        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          {{ 'LABEL.SAVE_IN_PROGRESS' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.EMPTY" class="field-custom-container">
    <div class="empty-field-container">
      <div [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
           class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
          <span *ngIf="fieldGroup.tooltip" [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
        </span>
        </div>

        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <div class="empty-value">
            <ng-container *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition); else noPermissions">
              <add-field-link (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" [inputMessage]="fieldGroup.emptyLabel"></add-field-link>
            </ng-container>
            <ng-template #noPermissions>
              {{ appConfig.EMPTY_FIELD_VALUE }}
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.READ" class="field-custom-container">
    <div class="read-field-container">
      <div [ngClass]="{'read-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
           class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
          <span *ngIf="fieldGroup.tooltip" [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
        </span>
        </div>

        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
               [ngClass]="{'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) &&  preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}" tabindex="0">
            <span>
              {{ form.get('replacementValue.simplifiedReplacementValue').value.toString().replace(',', '.') | fieldFormatType: fieldGroup.fieldConfigs[0]?.customOptions?.['formatType'] | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE} }}
            </span>
            <span>
              {{ fieldGroup.fieldConfigs[0]?.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
            </span>
            <span *ngIf="form.get('replacementValue.simplifiedReplacementValueDate').value">
              ({{ formatDate(form.get('replacementValue.simplifiedReplacementValueDate').value) }})
            </span>
            <span [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.COMPUTED_VALUE' | translate"
                  #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
              <i class="mdi mdi-calculator tooltip-icon"></i>
            </span>
            <span *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)" class="overlay-icon">
              <i *ngIf="preconditionsForEdition else readOnly;" class="mdi mdi-pencil selectable"></i>
              <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.EDIT" class="field-custom-container">
    <div class="edit-field-container">
      <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
        <!-- label -->
        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
          <span *ngIf="fieldGroup.tooltip" [matTooltipPosition]="'below'"
                [matTooltip]="fieldGroup.tooltip" #tooltip="matTooltip"
                (click)="onClickTooltip(tooltip)">
            <i class="mdi mdi-help-circle tooltip-icon"></i>
          </span>
        </div>
        <!-- fields -->
        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <form autocomplete="off" class="fx-layout-col fx-gap-10" [formGroup]="form">
            <mat-form-field appearance="outline" formGroupName="replacementValue">
              <mat-label>{{ fieldGroup.fieldConfigs[0].label }}</mat-label>
              <input (beforeinput)="validateKeypressEvent($event)" (paste)="validatePasteEvent($event)"
                     formControlName="simplifiedUnitReplacementValue" matInput inputFieldBuilderAutoFocus type="text">
              <span *ngIf="fieldGroup.fieldConfigs[0]?.suffixType" matTextSuffix>
                {{ fieldGroup.fieldConfigs[0]?.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
              </span>
              <mat-error>
                <ng-template ngFor let-validator [ngForOf]="fieldGroup.fieldConfigs[0].field.validators">
                  <span *ngIf="form.get('replacementValue.simplifiedUnitReplacementValue').hasError(validator.type)">
                    {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                  </span>
                </ng-template>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ fieldGroup.fieldConfigs[3].label }}</mat-label>
              <input formControlName="floorArea" matInput type="text">
              <span *ngIf="fieldGroup.fieldConfigs[3]?.suffixType" matTextSuffix>
                {{ fieldGroup.fieldConfigs[0]?.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
              </span>
            </mat-form-field>

            <mat-form-field appearance="outline" formGroupName="replacementValue">
              <mat-label>{{ fieldGroup.fieldConfigs[1].label }}</mat-label>
              <input (focus)="dateField.open()" formControlName="simplifiedReplacementValueDate"
                     [matDatepicker]="dateField"
                     matInput type="text"/>
              <mat-datepicker-toggle [for]="dateField" matIconSuffix></mat-datepicker-toggle>
              <mat-datepicker #dateField color="accent"></mat-datepicker>
              <span *ngIf="fieldGroup.fieldConfigs[1]?.suffixType" matTextSuffix>
                {{ fieldGroup.fieldConfigs[0]?.suffixType | translate }}
              </span>
              <mat-error>
                <ng-template ngFor let-validator [ngForOf]="fieldGroup.fieldConfigs[1].field.validators">
                  <span *ngIf="form.get('replacementValue.simplifiedReplacementValueDate').hasError(validator.type)">
                    {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                  </span>
                </ng-template>
              </mat-error>
            </mat-form-field>
          </form>
          <div class="button-container fx-layout-row fx-justify-end fx-align-center">
            <!-- cancel + save -->
            <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" mat-stroked-button>{{ 'BUTTON.CANCEL' | translate }}</button>
            <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid" color="accent" mat-raised-button>{{ 'BUTTON.SAVE' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>