import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { select, Store } from '@ngrx/store';
import { GeneralService } from '@services/general.service';
import { gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';

export const isValueTaken = (generalService: GeneralService, entityType: EntityTypeEnum, fieldName: string,
                             exclude: string, filter: Record<string, string>, store?: {store: Store<any>, fieldName: string}): AsyncValidatorFn => {

  let defaultValue;
  if (store) {
    store.store.pipe(select(store.fieldName)).subscribe(newDefaultValue => {
      defaultValue = newDefaultValue['originalValue'];
    });
  }

  return (control: AbstractControl): Observable<ValidationErrors | null> => {

    if (control.pristine) {
      if (!store) {
        defaultValue = control.value;
      }
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((value) => {
        let isNewValueDifferent;
        if (Array.isArray(value) && Array.isArray(defaultValue)) {
          isNewValueDifferent = !(value.length === defaultValue.length && value.every((val) => defaultValue.includes(val)));
        } else {
          isNewValueDifferent = value !== defaultValue;
        }
        if (isNewValueDifferent) {
          const QUERY = gql`
            query IsValueTakenQuery($entityType: EntityType!, $value: Object!, $exclude: String!, $filter: Object!) {
              isValueTaken(entityType: $entityType, value: $value, exclude: $exclude, filter: $filter )
            }
          `;
          const QUERY_VAR = {entityType: entityType, value: {[fieldName]: value}, exclude: exclude, filter: filter};
          return generalService.get(QUERY, QUERY_VAR);
        } else {
          return of({data: false});
        }
      }),
      map(response => {
        if (Object.values(response.data)[0] === true) {
          return <ValidationErrors>{isValueTaken: true};
        } else {
          return null;
        }
      }),
      first(),
      tap(() => control.markAsTouched())
    );
  };
};
