import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { Entity } from '@app/core/model/entities/entity';
import { Category } from '@app/core/model/other/classification';
import {
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_GROUP_CONFIG_INJECTION,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig,
  FieldGroup
} from '@app/core/model/other/field-config';
import { AbstractFieldGroupBuilder, FieldStateMode } from '@app/shared/components/fields/abstract.field';
import { FieldAnchorDirective } from '@app/shared/components/fields/field-anchor.directive';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { Dimensions } from '@app/shared/extra/column-dimensions.enum';
import { ColumnType } from '@app/shared/extra/column-type.enum';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { GridOptionsService } from '@app/shared/grid/grid-options.service';
import { ClassificationService } from '@app/shared/services/classification.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '@services/general.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { GetMainMenuItemsParams, GridApi, GridOptions, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Data from Work's properties
type WorkCategories = {
  technicalCategoryLevel1: string,
  technicalCategoryLevel2: string,
  technicalCategoryLevel3: string
  technicalCategoryLevel4?: string
  technicalCategoryLevel5?: string
};

@Component({
  selector: 'technical-category-field-builder',
  templateUrl: './technical-category-field-builder.component.html',
  styleUrls: ['./technical-category-field-builder.component.scss'],
  providers: [ClassificationService]
})
export class TechnicalCategoryFieldBuilderComponent extends AbstractFieldGroupBuilder implements OnInit, AfterViewInit {

  @ViewChild(FieldAnchorDirective, {static: true}) public fieldHost: FieldAnchorDirective;
  @ViewChild('gridContainer') public gridContainer: ElementRef;

  public gridOptions: GridOptions;
  public gridApi: GridApi;
  public categories: Category[];
  public fields: { label: string, value: string }[] = [];

  /**
   * Init grid options.
   */
  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_GROUP_CONFIG_INJECTION) fieldGroup: FieldGroup,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              singleEditService: SingleEditService,
              appManager: AppManager,
              accessManager: AccessManager,
              appConfig: AppConfig,
              analyticsService: AnalyticsService,
              media: MediaMatcher,
              protected validationService: ValidationService,
              private translate: TranslateService,
              private columnBuilder: ColumnBuilder,
              private classificationService: ClassificationService,
              public generalService: GeneralService,
              private gridOptionsService: GridOptionsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldGroup,
      preconditionsForEdition,
      permissionsForEdition,
      accessManager,
      appConfig,
      appManager,
      singleEditService,
      analyticsService,
      media
    );

    const extraGridOptions: GridOptions = {
      getRowId: (params): string => params.data.id,
      defaultColDef: {
        resizable: true,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        filter: true
      },
      treeData: true,
      getDataPath: (data: Category): string[] => data.path,
      suppressContextMenu: true,
      animateRows: true,
      context: this,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">' + this.translate.instant('LABEL.LOADING') + '</span>',
      suppressMovableColumns: true,
      localeText: this.columnBuilder.localeTextCommon(),
      autoGroupColumnDef: {
        headerName: this.translate.instant('LABEL.TECHNICALCATEGORY'),
        valueGetter: ({data}: { data: Category }): string => data?.label,
        tooltipValueGetter: ({data}: { data?: Category }): string => data?.label,
        checkboxSelection: true,
        colId: 'ag-Grid-AutoColumn',
        cellClass: ColumnType.TEXT,
        width: Dimensions.XL,
        cellRendererParams: {suppressCount: true}
      },
      getMainMenuItems: (params: GetMainMenuItemsParams): string[] => params.defaultItems,
      getRowHeight: (): number => this.gridOptionsService.staticGridOptions.rowHeight,
      onRowSelected: this.onRowSelected.bind(this),
      rowMultiSelectWithClick: true,
      onGridSizeChanged: (): void => {
        this.gridApi.sizeColumnsToFit();
      }
    };
    this.gridOptions = {
      ... this.gridOptionsService.staticGridOptions,
      ...extraGridOptions
    };
  }

  /**
   * Init FormStateService value and load Organization Classification.
   */
  public ngOnInit(): void {
    const initialValue = {
      technicalCategoryLevel1: this.entity.properties['technicalCategoryLevel1'],
      technicalCategoryLevel2: this.entity.properties['technicalCategoryLevel2'],
      technicalCategoryLevel3: this.entity.properties['technicalCategoryLevel3'],
      ...(this.entity.properties['technicalCategoryLevel4'] &&
        { technicalCategoryLevel4: this.entity.properties['technicalCategoryLevel4'] }),
      ...(this.entity.properties['technicalCategoryLevel5'] &&
        { technicalCategoryLevel5: this.entity.properties['technicalCategoryLevel5'] })
    };
    this.setFieldGroupInfo([], {
      state: new ReplaySubject<FieldStateMode>(1),
      isDisplayed: new BehaviorSubject(true),
      isSingleField: false,
      value: initialValue,
      initialValue: initialValue
    });
    this.fields = this.getFieldsValues();
    this.getNextState();
  }

  /**
   * Setup hooks and listen for state change.
   */
  public ngAfterViewInit(): void {
    this.setupHooks();

    this.getFieldGroupState().pipe(takeUntil(this.destroy$)).subscribe(newMode => {
      if (newMode === FieldStateMode.AFTER_SAVE) {
        this.refreshEntity();
        const initialValue = {
          technicalCategoryLevel1: this.entity.properties['technicalCategoryLevel1'],
          technicalCategoryLevel2: this.entity.properties['technicalCategoryLevel2'],
          technicalCategoryLevel3: this.entity.properties['technicalCategoryLevel3'],
          ...(this.entity.properties['technicalCategoryLevel4'] &&
            { technicalCategoryLevel4: this.entity.properties['technicalCategoryLevel4'] }),
          ...(this.entity.properties['technicalCategoryLevel5'] &&
            { technicalCategoryLevel5: this.entity.properties['technicalCategoryLevel5'] })
        };

        this.setFieldGroupValue(initialValue);
        this.setFieldGroupInitialValue(this.getFieldGroupValue());
        this.fields = this.getFieldsValues();
        this.getNextState();
      } else {
        this.currentMode = newMode;
      }
    });
  }

  /**
   * Auto-size column and select row corresponding to the Works's initial technical category if it is present.
   * @param params Ag-grid params.
   */
  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.classificationService.loadClassification()
      .pipe(takeUntil(this.destroy$))
      .subscribe(categories => {
        this.gridApi.setRowData(categories);

        // Expand and select current value
        const currentValue = this.getFieldGroupValue();
        const currentValueCategoryNames = [
          currentValue.technicalCategoryLevel1,
          currentValue.technicalCategoryLevel2,
          currentValue.technicalCategoryLevel3,
          currentValue.technicalCategoryLevel4,
          currentValue.technicalCategoryLevel5
        ].filter(item => item != null);
        // Note: since data come from the API, null values are supposed to always be the last items of the array

        currentValueCategoryNames.forEach((item, index) => {
          const categoryToExpandOrSelect = categories.find(category => {
            return category.label === item && category.path.length === index + 1;
          });
          if (index === currentValueCategoryNames.length - 1) {
            // Select category
            this.gridApi.getRowNode(categoryToExpandOrSelect.id).setSelected(true);
          } else {
            // Expand parent category
            this.gridApi.getRowNode(categoryToExpandOrSelect.id).setExpanded(true);
          }
        });

        this.gridApi.sizeColumnsToFit();
      });
  }

  /**
   * Update the FieldGroup's value in the FormStateService whenever a row is selected.
   * @param params Ag-grid selection event.
   */
  public onRowSelected(params: RowSelectedEvent): void {
    if (params.node.isSelected()) {
      const category = params.data as Category;
      this.setFieldGroupValue(this.convertPathToCategories(category.path));
    } else if (this.gridApi.getSelectedRows().length === 0) {
      this.setFieldGroupValue('');
    }
  }

  /**
   * Get the config of a category level field.
   * @param fieldCode Field's code.
   * @return Field's config.
   */
  public getFieldConfig(fieldCode: string): FieldConfig {
    return this.fieldGroup.fieldConfigs.find(fieldConfig => fieldConfig.fieldCode === fieldCode);
  }

  /**
   * Switch field to edit mode then focus and scroll element into view.
   */
  public edit(): void {
    super.edit();

    // Focus and scroll to field
    setTimeout(() => {
      this.gridContainer.nativeElement.focus();
      this.gridContainer.nativeElement.scrollIntoView({behavior: 'instant', block: 'center'});
    });
  }

  /**
   * Transform a list of Category ids into an Object whose values are set accordingly.
   * @param path List of Category ids.
   * @return WorkCategories.
   * @private
   */
  private convertPathToCategories(path: string[]): WorkCategories {
    const pathCategories = path.reduce((categories, id, i) => {
      const category = this.classificationService.categories.find(category => category.id === id);
      categories[`technicalCategoryLevel${i + 1}`] = category.label;
      return categories;
    }, {} as WorkCategories);
    // Assign category levels or use default value. This is for category paths that are less than 3 levels long
    return {
      technicalCategoryLevel1: '',
      technicalCategoryLevel2: '',
      technicalCategoryLevel3: '',
      technicalCategoryLevel4: '',
      technicalCategoryLevel5: '',
      ...pathCategories
    };
  }

  /**
   * Get the label and value of each subfield of the FieldGroup.
   * @return Fields' values.
   * @private
   */
  private getFieldsValues(): { label: string, value: string }[] {
    return Object.entries(this.getFieldGroupValue() as WorkCategories)
      .sort((fieldA, fieldB) => {
        const fieldConfigA = this.fieldGroup.fieldConfigs
          .find(fieldConfig => fieldConfig.fieldCode === fieldA[0]);
        const fieldConfigB = this.fieldGroup.fieldConfigs
          .find(fieldConfig => fieldConfig.fieldCode === fieldB[0]);
        return fieldConfigA.order - fieldConfigB.order;
      })
      .map(([level, value]) => {
        return {label: level, value};
      });
  }
}
