import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl, UntypedFormBuilder } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { AppConfig } from '@app/core/app.config';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Asset, RelatedAsset } from '@app/core/model/entities/asset/asset';
import { AssetsService } from '@app/features/main/views/assets/assets.service';
import {
  MatSelectAutocompleteComponent
} from '@app/shared/components/mat-select-autocomplete/mat-select-autocomplete.component';
import { Observable, of, switchMap } from 'rxjs';
import { map, mergeWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'mat-select-asset-autocomplete',
  styleUrls: ['./mat-select-asset-autocomplete.component.scss'],
  templateUrl: './mat-select-asset-autocomplete.component.html',
  providers: [
    {provide: MatFormFieldControl, useExisting: MatSelectAssetAutocompleteComponent}
  ]
})
export class MatSelectAssetAutocompleteComponent extends MatSelectAutocompleteComponent<RelatedAsset> implements OnInit, AfterViewInit {
  public declare dataSourceInput$: Observable<RelatedAsset[]>;

  public hasData: boolean = false;

  public displayWith = (asset: Asset | RelatedAsset): string => asset?.toString();

  // Asset to exclude from the search.
  @Input() public exclude: Asset;

  // Limit the search to assets whose this entity module is enabled.
  @Input() public entityType: EntityTypeEnum;

  constructor(fb: UntypedFormBuilder,
              @Optional() @Self() public ngControl: NgControl,
              @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
              focusMonitor: FocusMonitor,
              elementRef: ElementRef<HTMLElement>,
              appConfig: AppConfig,
              private assetsService: AssetsService
  ) {
    super(fb, ngControl, formField, focusMonitor, elementRef, appConfig);
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    // Emits when the value of the input element changes.
    this.dataSourceInput$ = this.assetsService.getAssets(this.entityType, false).pipe(
      map(relatedAssets => relatedAssets.map(asset => asset.toRelatedAsset())),
      mergeWith(this.control.valueChanges.pipe(
        switchMap((value) => {
          if (value && typeof value === 'string') {
            // If the value is a string, search the first ten Assets that match the given value.
            return this.assetsService.searchAssetsByNames(value, this.exclude?.id, this.entityType);
          } else {
            // If the value is not a string, return an empty array.
            return of([]);
          }
        })
      )),
      map(relatedAssets => relatedAssets.slice(0, 10)),
      takeUntil(this.destroy$),
      tap((relatedAssets) => this.hasData = relatedAssets.length > 0)
    );
  }
}
