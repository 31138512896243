import { PayloadAction } from '@app/shared/store/model';
import { Action } from '@ngrx/store';

export enum EquipmentNameActionTypes {
  FieldValueChange = 'equipmentName_change',
  FieldValueReset = 'equipmentName_reset',
  FieldValueRevert = 'equipmentName_revert'
}

export class EquipmentNameChange extends PayloadAction {
  public readonly type = EquipmentNameActionTypes.FieldValueChange;
}

export class EquipmentNameReset extends PayloadAction {
  public readonly type = EquipmentNameActionTypes.FieldValueReset;
}

export class EquipmentNameRevert implements Action {
  public readonly type = EquipmentNameActionTypes.FieldValueRevert;
}
