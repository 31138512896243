import { Component } from '@angular/core';

import {
  SingleFieldBuilderComponent
} from '@app/shared/components/field-groups/single-field-builder/single-field-builder.component';

@Component({
  selector: 'header-field-builder',
  templateUrl: './header-field-builder.component.html',
  styleUrls: ['./header-field-builder.component.scss']
})
export class HeaderFieldBuilderComponent extends SingleFieldBuilderComponent {
}
