import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';

/**
 * Validator that check if the control value match a given regular expression
 * @param expression The regular expression
 */
export const regex = (expression: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const regex = new RegExp(expression);
    return regex.test(control.value)  ? null : { REGEX: true };
  };
};
