<div *ngIf="currentMode === FieldMode.READ" class="read-field-container">
  <div [hidden]="!toggled" [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
       class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span *ngIf="fieldConfig.tooltip" [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div class="field-container fx-flex-100-66-percent fx-layout-col">
      <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)" tabindex="0"
           [ngClass]="{'read-value':accessManager.hasAllNeededPermissions(permissionsForEdition) &&  preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}">
        <mat-list class="compress">
          <mat-list-item *ngFor="let listItem of getFieldValue()" [className]="">
            <span><i class="mdi mdi-chevron-right"></i></span>
            {{ fieldConfig.customOptions?.['translate'] ? (('VALUE.' + fieldConfig.fieldCode + '_' + listItem) | uppercase | translate | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE}) : listItem }}
          </mat-list-item>
        </mat-list>
        <span *ngIf="!getFieldValue()">{{ appConfig.EMPTY_FIELD_VALUE }}</span>
        <span *ngIf="fieldConfig.suffixType">
          {{ fieldConfig.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
        </span>
        <span *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)" class="overlay-icon">
          <i *ngIf="preconditionsForEdition else readOnly;" class="mdi mdi-pencil selectable"></i>
          <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentMode === FieldMode.EDIT">
  <div [hidden]="!toggled" class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

    <div class="field-label fx-flex-100-33-percent">
      {{ fieldConfig.label }}
      <span *ngIf="fieldConfig.tooltip" [matTooltipPosition]="'below'"
            [matTooltip]="fieldConfig.tooltip" #tooltip="matTooltip" (click)="onClickTooltip(tooltip)">
        <i class="mdi mdi-help-circle tooltip-icon"></i>
      </span>
    </div>

    <div class="field-container fx-flex-100-66-percent-min-500 fx-layout-col">
      <form autocomplete="off" [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>{{ fieldConfig.label }}</mat-label>
          <mat-select formControlName="field" multiple (keyup.escape)="onEscapeCancel($event)">
            <mat-option *ngFor="let fieldValue of fieldConfig.field.fieldValues" [value]="fieldValue">
              {{ fieldConfig.customOptions?.['translate'] ? (('VALUE.' + fieldConfig.fieldCode + '_' + fieldValue) | uppercase | translate | emptyFieldPipe: {blankFieldValue: appConfig.EMPTY_FIELD_VALUE}) : fieldValue }}
            </mat-option>
          </mat-select>
          <span *ngIf="fieldConfig.suffixType" matTextSuffix>
          {{ fieldConfig.suffixType | translate: {currency: appManager.currencyMap.get(appManager.currentOrganization.currency).symbol} }}
        </span>
          <mat-error>
            <ng-template ngFor let-validator [ngForOf]="fieldConfig.field.validators">
            <span *ngIf="form.get('field').hasError(validator.type)">
              {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
            </span>
            </ng-template>
          </mat-error>
        </mat-form-field>
      </form>
      <div *ngIf="isSingleField" class="save-options">
        <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)" class="button">
          <i class="mdi mdi-close"></i>
        </button>
        <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                class="button save">
          <i class="mdi mdi-check"></i>
        </button>
      </div>
    </div>
  </div>
</div>
