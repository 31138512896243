import { NgModule } from '@angular/core';
import { AfterDateValidator } from '@app/shared/validators/afterDate/directive';
import { afterDate } from '@app/shared/validators/afterDate/validator';
import {
  assetValueGteSpaceChildrenProperty
} from '@app/shared/validators/asset-value-gte-space-children-property/validator';
import { BeforeDateValidator } from '@app/shared/validators/beforeDate/directive';
import { beforeDate } from '@app/shared/validators/beforeDate/validator';
import { checkDateChronologyValidator } from '@app/shared/validators/check-date-chronology/validator';
import { checkSpaces } from '@app/shared/validators/check-spaces/validator';
import { color } from '@app/shared/validators/color/validator';
import { dateISO } from '@app/shared/validators/date-iso/validator';
import { DecimalValidator } from '@app/shared/validators/decimal/directive';
import { decimal } from '@app/shared/validators/decimal/validator';
import {
  distinct,
  distinctRiskFieldValueOrders,
  distinctRiskParamFields
} from '@app/shared/validators/distinct/validator';
import { email } from '@app/shared/validators/email/validator';
import { equalTo } from '@app/shared/validators/equal-to/validator';
import { gteSpaceChildrenProperty } from '@app/shared/validators/gte-space-children-property/validator';
import { GreaterThanEqualValidator } from '@app/shared/validators/gte/directive';
import { gte } from '@app/shared/validators/gte/validator';
import { inArray } from '@app/shared/validators/in-array/validator';
import { incoherentPermissions } from '@app/shared/validators/incoherent-permissions/validator';
import {
  realisationDateIsAfterToday,
  realisationDateIsBeforeToday,
  renewDateIsAfterToday,
  renewDateIsUndefined
} from '@app/shared/validators/incorrect-check-state/validator';
import { IntegerValidator } from '@app/shared/validators/integer/directive';
import { integer } from '@app/shared/validators/integer/validator';
import { isSpaceMoveAllowed } from '@app/shared/validators/is-space-move-allowed/validator';
import { IsValueTakenValidator } from '@app/shared/validators/is-value-taken/directive';
import { isValueTaken } from '@app/shared/validators/is-value-taken/validator';
import { lteSpaceParentProperty } from '@app/shared/validators/lte-space-parent-property/validator';
import { LessThanEqualValidator } from '@app/shared/validators/lte/directive';
import { lte } from '@app/shared/validators/lte/validator';
import { maxLengthFileName } from '@app/shared/validators/max-length-file-name/validator';
import { MaxNowValidator } from '@app/shared/validators/max-now/directive';
import { maxNow } from '@app/shared/validators/max-now/validator';
import { NotInArrayValidator } from '@app/shared/validators/not-in-array/directive';
import { notInArray } from '@app/shared/validators/not-in-array/validator';
import { number } from '@app/shared/validators/number/validator';
import { PercentValidator } from '@app/shared/validators/percent/directive';
import { percent } from '@app/shared/validators/percent/validator';
import { regex } from '@app/shared/validators/regex/validator';
import { requiredConditional } from '@app/shared/validators/required-conditional/validator';
import { requiredTruePermissions } from '@app/shared/validators/required-true-permissions/validator';
import { endDateIsAfterToday, endDateIsBeforeToday, endDateIsUndefined } from '@app/shared/validators/status/validator';
import { userExists } from '@app/shared/validators/user-exists/validator';
import { YearValidator } from '@app/shared/validators/year/directive';
import { year } from '@app/shared/validators/year/validator';
import { checkBudgetBalance } from './budget-balance/validator';

export const ExtraValidators = {
  isValueTaken,
  notInArray,
  percent,
  maxNow,
  year,
  integer,
  decimal,
  gte,
  endDateIsBeforeToday,
  endDateIsAfterToday,
  endDateIsUndefined,
  realisationDateIsAfterToday,
  realisationDateIsBeforeToday,
  renewDateIsAfterToday,
  renewDateIsUndefined,
  lte,
  checkDateChronologyValidator,
  checkSpaces,
  checkBudgetBalance,
  afterDate,
  beforeDate,
  maxLengthFileName,
  incoherentPermissions,
  requiredTruePermissions,
  regex,
  distinct,
  gteSpaceChildrenProperty,
  lteSpaceParentProperty,
  distinctRiskFieldValueOrders,
  distinctRiskParamFields,
  assetValueGteSpaceChildrenProperty,
  requiredConditional,
  isSpaceMoveAllowed,
  equalTo,
  email,
  number,
  dateISO,
  color,
  inArray,
  userExists
};

export function isPresent(obj: any): boolean {
  return obj !== void 0 && obj !== null;
}

@NgModule({
  declarations: [
    IsValueTakenValidator,
    NotInArrayValidator,
    YearValidator,
    DecimalValidator,
    IntegerValidator,
    MaxNowValidator,
    PercentValidator,
    GreaterThanEqualValidator,
    LessThanEqualValidator,
    AfterDateValidator,
    BeforeDateValidator
  ],
  exports: [
    IsValueTakenValidator,
    NotInArrayValidator,
    YearValidator,
    DecimalValidator,
    IntegerValidator,
    MaxNowValidator,
    PercentValidator,
    GreaterThanEqualValidator,
    LessThanEqualValidator,
    AfterDateValidator,
    BeforeDateValidator
  ]
})
export class ExtraValidatorsModule {
}
