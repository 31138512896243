<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.RENEW_CONTRACT' | translate}}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="contractForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.CONTRACT_PROVIDER' | translate}}</mat-label>
        <input matInput [id]="'renew_contract_provider'" formControlName="provider" required/>
      </mat-form-field>

      <div class="space-form-field">
        <p class="form-custom-label">{{'LABEL.ALLOCATED_SPACES' | translate}} *</p>
        <ag-grid-angular (gridReady)="onGridReady($event)"
                         [gridOptions]="gridOptions"
                         class="ag-theme-material"
                         id="renew-contract-allocated-spaces-grid"
                         style="width: 100%; height: 300px;">
        </ag-grid-angular>
        <mat-error
          *ngIf="contractForm.controls.spaces.touched && contractForm.controls.spaces.hasError('required')">
          {{'ERROR.FIELD_REQUIRED' | translate}}
        </mat-error>
        <mat-error *ngIf="contractForm.controls.spaces.hasError('INCOHERENT_SPACES')">
          {{'ERROR.SPACES_INCOHERENT' | translate}}
        </mat-error>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose="cancel" [id]="'create-contract-cancel-button'">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button [disabled]="!contractForm.valid"
            [id]="'renew-contract-add-button'"
            color="accent"
            mat-raised-button
            matDialogClose="yes">
      {{ 'BUTTON.RENEW' | translate }}
    </button>
  </mat-dialog-actions>
</div>
