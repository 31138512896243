import { Component, inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AssetType } from '@app/core/model/entities/asset/asset-type';
import { DatagridVisualisation } from '@app/core/model/entities/visualisation/datagrid-visualisation';
import { DatagridVisualisationService } from '@app/shared/services/datagrid-visualisation.service';
import { GridStateService } from '@app/shared/services/grid-state.service';
import { UserPreferencesService } from '@app/shared/services/user-preferences.service';
import { TranslateService } from '@ngx-translate/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { ColumnState } from 'ag-grid-community';
import { combineLatestWith, filter, Observable, of, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'visualisation-tool-panel',
  templateUrl: './visualisation-tool-panel.component.html',
  styleUrls: ['./visualisation-tool-panel.component.scss']
})
export class VisualisationToolPanelComponent implements IToolPanelAngularComp, OnDestroy {

  protected visualisationForm: FormGroup<{
    currentVisualisation: FormControl<DatagridVisualisation>,
    currentAssetType?: FormControl<AssetType>
  }>;
  protected visualisations$: Observable<DatagridVisualisation[]>;
  protected destroy$ = new Subject<void>();

  private initialized = false;
  private originalColumnStates: ColumnState[];

  // Injection
  protected datagridVisualisationService = inject(DatagridVisualisationService);
  protected fb = inject(UntypedFormBuilder);
  protected gridStateService = inject(GridStateService);
  protected translate: TranslateService = inject(TranslateService);
  protected userPreferencesService = inject(UserPreferencesService);

  /**
   * Initializes the form
   */
  constructor() {
    this.visualisationForm = this.fb.group({
      currentVisualisation: this.fb.control('', Validators.required),
    });
  }

  /**
   * Stop Observable subscriptions.
   * Clears data and completes the `destroy$` subject to prevent memory leaks.
   */
  public ngOnDestroy(): void {
    this.datagridVisualisationService.clearData();
    // Prevent memory leak
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Method used by Ag-grid to initialise the tool panel with grid parameter.
   * Initialises the tool panel with grid parameters.
   * @param params this is used to get the Grid API
   */
  public agInit(params: any): void {
    this.datagridVisualisationService.loadData()
      .pipe(
        combineLatestWith(this.gridStateService.gridReady$),
        takeUntil(this.destroy$)
      )
      .subscribe(([datagridVisualisations, originalColumnState]) => {
        this.originalColumnStates = originalColumnState;

        const defaultDatagridVisualisation = datagridVisualisations.find(datagridVisualisation => datagridVisualisation.isDefault);

        // get preferred visualisation id
        const preferredVisualisationId = this.userPreferencesService.currentUserPreferences.preferences[params.preferenceIdKey];

        // get the visualisation of the user's preference
        // otherwise, get the default visualisation
        const currentDatagridVisualisation = preferredVisualisationId ?
          datagridVisualisations.find(datagridVisualisation => datagridVisualisation.id === preferredVisualisationId) || defaultDatagridVisualisation
          : defaultDatagridVisualisation;
        this.onDataLoaded(currentDatagridVisualisation, defaultDatagridVisualisation);
        this.initialized = true;
      });

    this.visualisationForm.get('currentVisualisation').valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.initialized)
      )
      .subscribe(datagridVisualisation => {
        this.userPreferencesService.currentUserPreferences.preferences[params.preferenceIdKey] = datagridVisualisation.id;
        this.userPreferencesService.updateUserPreferences();
        this.saveVisualisationAsGridState(datagridVisualisation);
        this.gridStateService.restoreGridState();
      });

    this.afterAgInit();
  }

  /**
   * Method is called after Ag-grid to initialise the tool panel with grid parameter.
   * It sets up subscriptions to handle changes in datagrid visualizations.
   */
  protected afterAgInit(): void {
    // Subscription to handle deletion of datagrid visualizations.
    this.datagridVisualisationService.deleteDatagridVisualisation$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const defaultDatagridVisualisation = this.datagridVisualisationService.datagridVisualisations.find(
          datagridVisualisation => datagridVisualisation.isDefault
        );
        this.visualisationForm.get('currentVisualisation').setValue(defaultDatagridVisualisation);
      });

    // Subscription to handle application of datagrid visualizations.
    this.datagridVisualisationService.applyDatagridVisualisation$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((datagridVisualisation) => {
        this.visualisationForm.get('currentVisualisation').setValue(datagridVisualisation);
      });
  }

  /**
   * Open a dialog to create a visualisation.
   */
  public createVisualisation(): void {
    this.datagridVisualisationService.openCreateVisualisationDialog();
  }

  /**
   * Update the current visualisation.
   */
  public updateVisualisation(): void {
    const currentVisualisation = this.visualisationForm.get('currentVisualisation').value;
    this.datagridVisualisationService.updateDatagridVisualisation(
      currentVisualisation,
      this.gridStateService.gridState
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * Open a dialog to delete a visualisation.
   */
  public deleteVisualisation(): void {
    const currentVisualisation = this.visualisationForm.get('currentVisualisation').value;
    this.datagridVisualisationService.openDeleteVisualisationDialog(currentVisualisation);
  }

  /**
   * Handles when all the visualisation data are loaded.
   * @param currentDatagridVisualisation - The current datagrid visualisation.
   * @param defaultDatagridVisualisation - The default datagrid visualisation.
   */
  public onDataLoaded(currentDatagridVisualisation: DatagridVisualisation,
                      defaultDatagridVisualisation: DatagridVisualisation): void {
    // Skip visualisation initialisation if config is missing
    if (!currentDatagridVisualisation?.config || !defaultDatagridVisualisation?.config!) {
      console.error('Datagrid visualisation config is missing');
      return;
    }

    // Translate filter values
    if (defaultDatagridVisualisation.config.filterModel) {
      const filterModel = defaultDatagridVisualisation.config.filterModel;
      Object.keys(filterModel).forEach(key => {
        filterModel[key].values = filterModel[key].values.map((value: string) => this.translate.instant(value));
      });
    }

    // set the current visualisation
    this.visualisationForm.get('currentVisualisation').setValue(currentDatagridVisualisation);

    // Initialize or update the list of visualisations
    this.visualisations$ = this.datagridVisualisationService.applyDatagridVisualisation$
      .pipe(
        takeUntil(this.destroy$),
        startWith(''),
        switchMap(() => {
          return of(this.datagridVisualisationService.datagridVisualisations);
        })
      );

    // If there is no grid state in the session storage, save and apply the current visualisation
    if (!this.gridStateService.gridState) {
      this.saveVisualisationAsGridState(currentDatagridVisualisation);
      this.gridStateService.restoreGridState();
    }
  }

  /**
   * Save the DatagridVisualisation in the GridStateService. Column states from the visualisation are merged with
   * those from the grid's column definitions.
   * @param visualisation Visualisation to save.
   * @private
   */
  private saveVisualisationAsGridState(visualisation: DatagridVisualisation): void {
    // Visualisation may not contain states of every column in the datagrid, so we merge column states from
    // the visualisation with those from the datagrid.
    // Columns that are not present in the visualisation are hidden.
    const columnStatesToAdd = this.originalColumnStates.filter(colState =>
      !visualisation.config.columnState.find(({colId}) => colId === colState.colId)
    )
      .map(colState => {
        colState.hide = true; // Update column state so the column is hidden
        return colState;
      });

    // Save column state in grid state
    this.gridStateService.gridState = {
      filterModel: visualisation.config.filterModel,
      columnState: [
        ...visualisation.config.columnState,
        ...columnStatesToAdd
      ],
      modified: false
    };
  }
}
