<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_SPACE' | translate}}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="spaceForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.ASSET' | translate}}</mat-label>
        <input formControlName="assetName" [id]="'input-space-asset-name'" matInput/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.PARENT_SPACE_NAME' | translate}}</mat-label>
        <input formControlName="parentSpaceName" [id]="'input-parent-space-name'" matInput/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.SPACE_NAME' | translate}}</mat-label>
        <input formControlName="spaceName" [id]="'input-space-name'" matInput/>
        <mat-error *ngIf="spaceForm.get('spaceName').hasError('required')">
          {{'ERROR.FIELD_REQUIRED' | translate}}
        </mat-error>
        <mat-error *ngIf="spaceForm.get('spaceName').hasError('maxlength')">
          {{ 'ERROR.FIELD_MAXLENGTH' | translate:{ value: appConfig.FIELD_MAX_LENGTH } }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button [id]="'create-space-cancel-button'" mat-stroked-button matDialogClose="cancel">
      {{'BUTTON.CANCEL' | translate}}
    </button>
    <button [disabled]="!spaceForm.valid"
            [id]="'create-space-add-button'"
            color="accent"
            mat-raised-button
            matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate}}
    </button>
  </mat-dialog-actions>
</div>
