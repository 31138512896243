import { Direction } from '@angular/cdk/bidi';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, Event, NavigationStart, Router } from '@angular/router';
import { RouteData } from '@app/app.routing';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { SidenavService } from '@app/features/main/sidenav.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import packageJson from '@root/package.json';

@Component({
  selector: 'mya-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnDestroy {

  public mobileQuery: MediaQueryList;
  public _mobileQueryListener: () => void;

  public dir: Direction = 'ltr';

  public data: RouteData = <RouteData>{};

  public version: string;

  private destroy$ = new Subject<void>();

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private media: MediaMatcher,
              private route: ActivatedRoute,
              private router: Router,
              public sidenavService: SidenavService,
              private translate: TranslateService,
              private titleService: Title,
              private activationEndService: ActivationEndService,
              private analyticsService: AnalyticsService) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = (): void => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.version = this.translate.instant('VALUE.APP_VERSION', {'version': packageJson.version});

    /*
     Subscribe to all events thrown by the Angular router through the common service
     TODO: Find out how to centralise the pipe operations in the service while still allowing to reset the data
     */
    this.activationEndService.getRouteData()
      .pipe(
        takeUntil(this.destroy$),
        filter((event: Event) => event instanceof NavigationStart),
        switchMap(() => {
          return this.router.events.pipe(
            filter((event: Event) => event instanceof ActivationEnd),
            filter((event: ActivationEnd) => event.snapshot.outlet === 'primary'),
            first()
          );
        })
      )
      .subscribe((activationEnd: ActivationEnd) => {
        // Make sure to overwrite the hideSidenav, hideHeader and closeSidenav values with proper booleans
        this.data = <RouteData>{};
        this.data = Object.assign(
          {},
          activationEnd.snapshot.data,
          {
            hideHeader: !!activationEnd.snapshot.data.hideHeader,
            hideSidenav: !!activationEnd.snapshot.data.hideSidenav,
            closeSidenav: !!activationEnd.snapshot.data.closeSidenav
          }
        );

        // On mobile, scroll to top after navigating to new page
        if (this.mobileQuery.matches) {
          window.scrollTo(0, 0);
        }

        // If sidenav should be closed, we update its state
        if(this.data.closeSidenav) {
          this.sidenavService.setSidenavState(!this.data.closeSidenav);
        }

        // Set the page title in the browser tab if it exists
        if (this.data.title) {
          // Track page title with analyticsService. If the current page is a tab, get the breadcrumb else get the page title.
          this.analyticsService.trackDisplayPageEvent(this.translate.instant(this.data['breadCrumb'] ?? this.data.title));
          this.titleService.setTitle(this.translate.instant(this.data.title) + ' - myA');
        }
      });
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
