import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Inject } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { Space } from '@app/core/model/entities/asset/space';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import {
  SpacesFieldBuilderComponent
} from '@app/shared/components/fields/tree-field-builder/spaces-field-builder.component';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { GridOptionsService } from '@app/shared/grid/grid-options.service';
import { GridStateService } from '@app/shared/services/grid-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { DeviceDetectorService } from 'ngx-device-detector';
import { tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'space-path-field-builder',
  templateUrl: './space-path-field-builder.component.html',
  styleUrls: ['./space-path-field-builder.component.scss']
})
export class SpacePathFieldBuilderComponent extends SpacesFieldBuilderComponent {

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService,
              deviceDetectorService: DeviceDetectorService,
              spacesService: SpacesService,
              columnBuilder: ColumnBuilder,
              gridStateService: GridStateService,
              gridOptionsSrvice: GridOptionsService) {
    super(
      entity,
      data,
      eventsOrigin,
      formStateService,
      fieldConfig,
      preconditionsForEdition,
      permissionsForEdition,
      appManager,
      appConfig,
      accessManager,
      media,
      translate,
      validationService,
      singleEditService,
      analyticsService,
      deviceDetectorService,
      spacesService,
      columnBuilder,
      gridStateService,
      gridOptionsSrvice
    );
  }

  public override ngAfterViewInit(): void {
    this.setupHooks();

    this.form.get('field').valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(50),
        takeUntil(this.destroy$)
      )
      .subscribe((spaces: Space[]) => {
        const newParent = spaces.firstItem();
        if(newParent) {
          this.setFieldValue([...newParent[this.fieldConfig.fieldCode], newParent.id]);
        }
      });

    if ((this.fieldInitValue === null || this.fieldInitValue === void 0 || !this.fieldInitValue.length) && this.isSingleField) {
      this.getFieldGroupInfo(['isDisplayed']).next(false);
    }
  }

  /**
   * This method will set selectable spaces in the grid.
   * @param spaces A list of Space entities
   */
  protected override setGridData(spaces: Space[]): void {
    this.gridApi.setRowData(spaces.filter(space => !([...space.parentPath, space.id].includes(this.entity.id))));
  }

  /**
   * This method will set current selected space by fetching the direct parent (last element of the list).
   */
  protected override setSelectedEntities(): void {
    const parentId = this.fieldInitValue?.lastItem();
    this.spaces.pipe(
      takeUntil(this.cancel$),
      tap(spaces => {
        const space = spaces.find((elem) => elem.id === parentId);
        this.selectedSpaces = space ? [space] : [];
        this.setFieldValue(this.selectedSpaces?.map(space => space.id));
        this.setFieldInitialValue(this.selectedSpaces?.map(space => space.id));
        this.getNextState();
        this.selectedSpaces?.forEach(space => this.spacesMap.set(space.id, space.toString()));
        if (this.spacesMap.size === 0) {
          this.spacesMap.set(null, this.appConfig.EMPTY_FIELD_VALUE);
        }
      })
    )
      .subscribe();
  }

  /**
   * Restore field's value to original Spaces.
   */
  public override cancel(): void {
    super.cancel();
    this.setSelectedEntities();
    this.cancel$.next();
  }
}
