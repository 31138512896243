<div class="fx-justify-start fx-align-center">
  <div [ngClass]="{'active':show && !disabled, 'disabled': disabled, 'error': control.invalid}" class="inline-edit" tabindex="0"
       (keyup.enter)="makeEditable()">
    <ng-container *ngIf="!show; else input">
      <div [id]="'inline_edit_container'" class="inline-edit-container">
        <div (click)="makeEditable()" [class.selectable]="full === true" class="inline-edit-value">
          {{ control?.value }}
        </div>
        <div *ngIf="!disabled" (click)="makeEditable()" class="inline-edit-content selectable">
          <span class="overlay-icon">
            <i class="mdi mdi-pencil selectable"></i>
          </span>
        </div>
      </div>
    </ng-container>
    <ng-template #input>
      <div class="field-group">
        <form autocomplete="off">
          <input #element (focus)="onFocus()" (keyup)="onKey($event)" [disabled]="disabled" [formControl]="control"
                 [id]="'inline_input_string'" class="inline-edit-input" tabindex="0" type="text"/>
        </form>
      </div>
    </ng-template>
    <div *ngIf="show" class="save-options">
      <button (click)="cancelEditable()" class="button">
        <i [id]="'inline-input-cancel-button'" class="mdi mdi-close"></i>
      </button>
      <button (click)="callSave()" [disabled]="preventSave()" [id]="'inline-input-save-button'" class="button save">
        <i class="mdi mdi-check"></i>
      </button>
    </div>
    <div class="inline-field-errors fx-layout-col fx-justify-space-between fx-align-start">
      <ng-content>
      </ng-content>
    </div>
  </div>
</div>
