<div id="dialog-container">
  <h1 mat-dialog-title>{{'TITLE.RENEW_LEASE' | translate}}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="leaseForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.OCCUPANT_NAME' | translate}}</mat-label>
        <input matInput formControlName="occupantName"/>
      </mat-form-field>

      <div class="space-form-field">
        <p class="form-custom-label">{{'LABEL.ALLOCATED_SPACES' | translate}} *</p>
        <ag-grid-angular (gridReady)="onGridReady($event)"
                         [gridOptions]="gridOptions"
                         class="ag-theme-material"
                         id="allocated-spaces-grid"
                         style="width: 100%; height: 300px;">
        </ag-grid-angular>
        <mat-error
          *ngIf="leaseForm.controls.spaces.touched && leaseForm.controls.spaces.hasError('required')">
          {{'ERROR.FIELD_REQUIRED' | translate}}
        </mat-error>
        <mat-error *ngIf="leaseForm.controls.spaces.hasError('INCOHERENT_SPACES')">
          {{'ERROR.SPACES_INCOHERENT' | translate}}
        </mat-error>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose="cancel">{{ 'BUTTON.CANCEL' | translate }}</button>
    <button [disabled]="!leaseForm.valid"
            color="accent"
            id="btn_renew_lease"
            mat-raised-button
            matDialogClose="yes">
      {{'BUTTON.RENEW' | translate}}
    </button>
  </mat-dialog-actions>
</div>
