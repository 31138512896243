import { Component } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { AbstractInlineEditComponent } from '@app/shared/components/inline-edit/inline-edit.component';
import { ClientNameChange, ClientNameRevert } from '@app/shared/store/reducers/client-name.actions';
import { Store } from '@ngrx/store';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';

@Component({
  selector: 'inline-edit-client',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditClientComponent extends AbstractInlineEditComponent {

  constructor(private extendedStore: Store<any>,
              protected formStateService: FormStateService,
              protected singleEditService: SingleEditService,
              protected analyticsService: AnalyticsService) {
    super(extendedStore, singleEditService, analyticsService);
  }

  public revertField(): void {
    this.extendedStore.dispatch(new ClientNameRevert());
  }

  public changeField(): void {
    this.extendedStore.dispatch(new ClientNameChange({ currentValue: this.control.value }));
  }
}
