import {Component} from '@angular/core';
import {TextFieldBuilderComponent} from '@app/shared/components/fields/text-field-builder/text-field-builder.component';

@Component({
  selector: 'text-header-field-builder',
  templateUrl: './text-header-field-builder.component.html',
  styleUrls: ['./text-header-field-builder.component.scss']
})
export class TextHeaderFieldBuilderComponent extends TextFieldBuilderComponent {

  public getFieldValue(): any {
    const value = super.getFieldValue() || this.fieldInitValue;
    return this.fieldConfig.customOptions?.['translate'] ? 'VALUE.' + value.toUpperCase() : value;
  }
}
