import {Component} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ModuleGridFooterService} from '@app/shared/components/module-grid/module-grid-footer.service';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {Client} from '@app/core/model/client/client';
import {User} from '@app/core/model/client/user';

interface ModuleCheckBox {
  moduleName: string,
  isDefault: boolean,
  entityIds: number[],
  // Only for clients grid
  user?: User
}

@Component({
  selector: 'module-grid-checkbox-renderer',
  template: `
    <mat-checkbox [disabled]="isDefault" [disableRipple]="true" (change)="onClick($event)"
                  [checked]="checked"></mat-checkbox>
  `
})
export class ModuleGridCheckboxRendererComponent implements ICellRendererAngularComp {
  public module: string;
  public isDefault: boolean;
  public entityIds: number[];
  public entity: User | Client;
  public checked: boolean = false;

  constructor(private moduleService: ModuleGridFooterService) {
  }

  /**
   * Refresh the entity list.
   * @private
   */
  private refreshEntityList(): void {
    // Find out if the entity has this module selected
    this.checked = this.moduleService.footerMap.get(this.module).entityIds.includes(this.entity.id);
  }

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters
   * @param params
   */
  public agInit(params: ICellRendererParams<User | Client> & ModuleCheckBox): void {
    // For clients grid, verify that there is enough licence to add the user.
    let licenceLimitReach = false;
    if (params.data instanceof Client) {
      const moduleLicenceLimit = params.data.displayableSubscription.activeSubscriptionModules
        .find(subscriptionModule => subscriptionModule.module.code == params.moduleName)
        ?.nbLicence ?? 0;
      const moduleLicenceCount = params.data.users
        // Remove current user from count, because we don't want to disable the checkbox if the limit is reach because the user has this module.
        // We still want to be able to remove this module from the user's list of modules.
        .filter(user => user.id != params.user.id)
        .reduce((count, user) => {
          return user.displayableModules.some(subscriptionModule => subscriptionModule.module.code == params.moduleName)
            ? count + 1
            : count;
        }, 0);
      licenceLimitReach = moduleLicenceCount >= moduleLicenceLimit;
    }

    // Passed by cellRendererParams
    this.module = params.moduleName;
    this.entityIds = params.entityIds;
    this.isDefault = params.isDefault || licenceLimitReach;
    // Passed by valueGetter
    this.entity = params.value;
    this.refreshEntityList();
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell
   * This component does not include refresh logic
   * @param _ Unused
   * @returns {boolean}
   */
  public refresh(_: ICellRendererParams): boolean {
    this.refreshEntityList();
    return true;
  }

  /**
   * Manage the checking of a checkbox
   * If the checkbox is checked, add 1 to the count of selected users for this module
   * and add this user id to the list of ids of this module
   * Otherwise, do the opposite
   * @param {MatCheckboxChange} event
   */
  public onClick(event: MatCheckboxChange): void {
    this.checked = event.checked;
    if (this.checked) {
      this.moduleService.setFooter(this.module, +1);
      this.moduleService.addEntity(this.module, this.entity.id);
    } else {
      this.moduleService.setFooter(this.module, -1);
      this.moduleService.removeEntity(this.module, this.entity.id);
    }
  }
}
