import { UploadFile, UploadInput } from '@angular-ex/uploader';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PresignedUrl } from '@app/core/model/entities/document/document';
import {
  RestrictedIconButtonComponent
} from '@app/shared/components/restricted-icon-button/restricted-icon-button.component';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '@services/general.service';
import { ErrorManager } from '@services/managers/error.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { Observable, timeout, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class FileService {

  constructor(private translate: TranslateService,
              private generalService: GeneralService,
              private errorManager: ErrorManager) {
  }

  /**
   * Download file using document entity ID to get presigned url
   * @param documentId document's id
   */
  public downloadFile(documentId: string): void {
    this.getDocumentFileUrl(documentId).subscribe((documentUrl) => {
      window.open(documentUrl);
    });
  }

  /**
   * Download files using document's Ids to get presigned url
   * @param documentIds document's IDs
   * @param snackBarManager Snackbar manager
   * @param button Download button
   */
  public downloadFiles(documentIds: string[],
                       snackBarManager: SnackbarManager,
                       button: RestrictedIconButtonComponent): void {
    if (documentIds.length === 1) {
      this.getDocumentFileUrl(documentIds.firstItem()).subscribe((documentUrl) => {
        snackBarManager.closeSnackbar();
        window.open(documentUrl);
      });
    } else {
      const downloadLink = environment.backend.baseUrl + environment.backend.download.endpoint;
      const header = new HttpHeaders({'Content-Type': 'application/json'});
      // FIXME TTT-3576 Remove polling
      const waitForZipFile = switchMap((presignedUrl) => this.generalService.httpGetAsBlob(presignedUrl['documents.zip'])
        .pipe(
          catchError(() => timer(5000).pipe(map(() => presignedUrl), waitForZipFile)),
          map(() => presignedUrl)
        )
      );

      this.generalService.httpPostAsJson(downloadLink, {'documentIds': documentIds, 'zip': true}, header)
        .pipe(waitForZipFile, timeout(900000))
        .subscribe({
          next: (presignedUrl) => {
            snackBarManager.closeSnackbar();
            window.open(presignedUrl['documents.zip']);
            button.preconditions = true;
          },
          error: error => {
            this.errorManager.handleNetworkError({
              error: error.error,
              title: this.translate.instant('TITLE.AWS_UNKNOWN_ERROR'),
              status: 491,
              description: error.message
            });
          }
        });
    }
  }

  /**
   * Get single presigned url by document's ID
   * @param documentId document's ID
   * @return Observable<string> of the presigned url
   */
  public getDocumentFileUrl(documentId: string): Observable<string> {
    return this.generalService.httpGetAsJson(this.getDownloadLinkByDocumentId(documentId))
      .pipe(
        map((body) => {
          const presignedUrl = Object.values(body);
          if (presignedUrl.length > 1) {
            throw this.translate.instant('ERROR.HTTP_REQUEST_ONLY_ONE_FILE_EXPECTED');
          } else {
            return presignedUrl.pop();
          }
        }),
        tap({
          error: error => {
            this.errorManager.handleNetworkError({
              error: error.error,
              title: this.translate.instant('TITLE.AWS_UNKNOWN_ERROR'),
              status: 491,
              description: error.message
            });
          }
        })
      );
  }

  /**
   * Get multiple presigned urls using document's Ids
   * @param documentIds of document's files to retrieve
   * @return Observable<JSON>
   */
  public getDocumentFileUrls(documentIds: string[]): Observable<JSON> {
    const downloadLink = environment.backend.baseUrl + environment.backend.download.endpoint;
    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.generalService.httpPostAsJson(downloadLink, {'documentIds': documentIds, 'zip': false}, header)
      .pipe(tap({
        error: error => {
          this.errorManager.handleNetworkError({
            error: error.error,
            title: this.translate.instant('TITLE.AWS_UNKNOWN_ERROR'),
            status: 491,
            description: error.message
          });
        }
      }));
  }

  /**
   * Get url used to send a get request to retrieve presigned url
   * @param documentId of the document linked to the file to retrieve
   * @return string endpoint to get presigned url
   */
  public getDownloadLinkByDocumentId(documentId: string): string {
    return environment.backend.baseUrl + environment.backend.download.endpoint + '?documentId=' + documentId;
  }

  /**
   * Fetch file from presigned url and return it as blob file
   * @param documentId document's ID used to get presigned url
   * @param expectedMimeType mime type of the file
   * @return file as blob
   */
  public getBlobFile(documentId: string, expectedMimeType: string): Observable<Blob> {
    return this.getDocumentFileUrl(documentId)
      .pipe(
        switchMap((presignedUrl) => {
          const headers = new HttpHeaders()
            .set('Content-Type', expectedMimeType);
          return this.generalService.httpGetAsArrayBuffer(presignedUrl, headers)
            .pipe(tap({
              error: error => {
                this.errorManager.handleNetworkError({
                  error: error.error,
                  title: this.translate.instant('TITLE.AWS_UNKNOWN_ERROR'),
                  status: 491,
                  description: error.message
                });
              }
            }));
        }),
        map((arrayBuffer) => {
          const uint = new Uint8Array(arrayBuffer);
          return new Blob([uint], {type: expectedMimeType});
        })
      );
  }

  /**
   * Get presigned urls to upload files then upload them to the S3 bucket.
   * @param files Files to upload
   * @param uploadData Information about the entity and the type of document to upload.
   * @return Observable<UploadInput>
   */
  public uploadFiles(files: UploadFile[], uploadData: any): Observable<UploadInput> {
    return this.getPresignedUrls(files.map(file => file.name), uploadData).pipe(
      switchMap((presignedUrls) => {
        return presignedUrls.map(presignedUrl => {
          // DocumentName is the origin name of the file. fileName is the name of the file stored in the file system
          const file = files.find(it => it.name === presignedUrl.documentName);
          file.name = presignedUrl.fileName;
          const uploadInput: UploadInput = {
            type: 'uploadFile',
            url: presignedUrl.url,
            method: 'PUT',
            file: file,
            includeWebKitFormBoundary: false
          };
          return uploadInput;
        });
      }));
  }

  private getPresignedUrls(fileNames: string[], uploadData): Observable<PresignedUrl[]> {
    const queryName = uploadData.uploadFileToImport ? 'getPutPresignedUrlToImport' : 'getPutPresignedUrl';
    const QUERY = gql`
      query GetPutPresignedUrl (
        $entityId: String!,
        $entityType: EntityType!,
        $fileNames: [String!]!,
        $organizationId: String!
        $documentType: DocumentTypeEnum!
      ) {
        ${queryName}(
        entityId: $entityId,
        entityType: $entityType,
        fileNames: $fileNames,
        organizationId: $organizationId,
        documentType: $documentType
        ) {
        fileName
        documentName
        url
      }
      }
    `;
    const QUERY_VAR = {
      entityId: uploadData.entityId,
      entityType: uploadData.entityType,
      fileNames: fileNames,
      organizationId: uploadData.organizationId,
      documentType: uploadData.documentType
    };
    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => {
          return plainToInstance(PresignedUrl, response.data[queryName] as PresignedUrl[]);
        })
      );
  }

  public getFileNameFromUploadFileResponse(file: UploadFile): string {
    return file.name;
  }
}
