<ng-container *ngIf="check?.type !== 'dpe'; else dpeTemplate">
  <div class="dpe-cell fx-layout-row fx-justify-start fx-align-center">
    <div class="label-container">
      <div class="label-content"
           [ngClass]="checkResult | checkResultColour: {checkType: checkTypes[check?.type]}"></div>
    </div>
    <div class="result-text">
      {{ checkResult }}
    </div>
  </div>
</ng-container>

<ng-template #dpeTemplate>
  <div class="dpe-cell fx-layout-row fx-justify-start fx-align-center fx-gap-5">
    <ng-container *ngIf="check?.properties['climateLabel']">
      <div [ngClass]="'climate-color-' + check?.properties['climateLabel']?.toLowerCase()"
           class="label-content dpe-label-content not-hoverable">{{check?.properties['climateLabel']}}</div>
    </ng-container>
    <ng-container *ngIf="check?.properties['powerLabel']">
      <div [ngClass]="'power-color-' + check?.properties['powerLabel']?.toLowerCase()"
           class="label-content dpe-label-content not-hoverable">{{check?.properties['powerLabel']}} </div>
    </ng-container>
  </div>
</ng-template>
