import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { ActionEnum, EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MatInput } from '@angular/material/input';
import { IFieldValueState } from '@app/shared/store/reducers/field-value.reducer';
import { select, Store } from '@ngrx/store';

export interface ValidatorConfig {
  type: string,
  definition?: string,
  customError?: string
}

@Component({
  selector: 'inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class AbstractInlineEditComponent implements OnInit, OnDestroy {

  @Input() public control: UntypedFormControl;
  @Input() public reduxFieldLabel: string = '';
  @Input() public full: boolean = false;
  @Input() public hideTrigger: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public validators: ValidatorConfig[];
  @Input() public permissionsForEdition: string[] = [];
  @Input() public eventsOrigin: EventOriginEnum = EventOriginEnum.SIDEPANEL;

  @Output() public onInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onEnter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatInput) public input: MatInput;
  @ViewChild('select') public select: MatSelect;

  @ViewChild('gridContainer') public gridContainer: ElementRef;

  public show: boolean = false;
  public storeValue$: Observable<IFieldValueState>;

  protected destroy$ = new Subject<void>();
  protected original: any;

  constructor(private store: Store<any>,
              protected singleEditService: SingleEditService,
              protected analyticsService: AnalyticsService) {
  }

  public ngOnInit(): void {
    this.storeValue$ = this.store.pipe(select<any, string>(this.reduxFieldLabel));

    this.storeValue$.pipe(first()).subscribe((initValue) => {
      this.original = initValue.currentValue;
    });

    // Listen for external updates
    this.storeValue$.pipe(takeUntil(this.destroy$)).subscribe((updatedValue) => {
      if (updatedValue.currentValue !== this.control.value) {
        this.setValue(updatedValue.currentValue);
      }
    });

    this.singleEditService.singleEditSubject.pipe(takeUntil(this.destroy$)).subscribe((fieldLabel?: string) => {
      if (fieldLabel !== this.reduxFieldLabel) {
        this.cancel();
      }
    });

    this.preventSave = (): boolean => this.control.invalid || this.control.pending;

    // Listen for user input
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeField();
        this.onInput.emit(this.control.value);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public makeEditable(): void {
    this.analyticsService.trackInlineActionEvent(this.reduxFieldLabel, ActionEnum.SELECT, this.eventsOrigin);
    if (this.disabled) {
      return;
    }
    this.show = true;
    this.singleEditService.singleEditSubject.next(this.reduxFieldLabel);

    setTimeout(() => {
      if (this.gridContainer) {
        this.gridContainer.nativeElement.focus();
        // Firefox do not scroll to the focus element, so we do it manually
        this.gridContainer.nativeElement.scrollIntoView({behavior: 'instant', block: 'center'});
      }
      if (this.input) {
        this.input.focus();
      }
      if (this.select) {
        this.select.focus();
      }

    }, 0);
  }

  public onKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.callSave();
    } else if (event.key === 'Escape') {
      this.cancel();
    }
    event.stopImmediatePropagation();
  }

  public callSave(): void {
    if (this.preventSave()) {
      return;
    }

    this.analyticsService.trackInlineActionEvent(this.reduxFieldLabel, ActionEnum.SAVE, this.eventsOrigin);
    this.onSave.emit({field: this.reduxFieldLabel, value: this.control.value});
    this.show = false;
  }

  public onFocus(): void {
    this.onEnter.emit();
  }

  public cancel(): void {
    this.show = false;
    this.revertField();
    this.onInput.emit(this.control.value);
  }

  public cancelEditable(): void {
    this.analyticsService.trackInlineActionEvent(this.reduxFieldLabel, ActionEnum.CANCEL, this.eventsOrigin);
    this.cancel();
  }

  /**
   * Abstract methods to be overriden
   */

  public revertField(): void {
    throw new Error('This method is abstract');
  }

  public changeField(): void {
    throw new Error('This method is abstract');
  }

  private setValue(value: string): void {
    this.control.setValue(value);
  }

  public preventSave(): boolean {
    return this.control.invalid || this.control.pending;
  }
}
