import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { PowerBIReportConfig } from '@app/core/model/other/powerBI';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { PowerBIService } from '@services/powerBI.service';
import { IReportEmbedConfiguration } from 'embed';
import { Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'powerBI-report',
  templateUrl: './powerBI-report.component.html',
  styleUrls: ['./powerBI-report.component.scss']
})
export class PowerBIReportComponent implements OnInit, OnDestroy {

  @Input() public workspaceId: string;
  @Input() public reportId: string;
  @Input() public reportDefaultPage: string;
  @Input() public assetId?: string;
  @ViewChild('powerBI') public powerBI: ElementRef;
  @Input() public relatedEntityType: EntityTypeEnum;
  public powerBIReportConfig: PowerBIReportConfig & IReportEmbedConfiguration;
  public exportInProgress = false;
  private destroy$ = new Subject<void>();
  public Permission = PermissionEnum;

  constructor(private powerBIService: PowerBIService,
              private snackbar: SnackbarManager,
              private translate: TranslateService,
              private appManager: AppManager,
              private analyticsService: AnalyticsService) {
  }

  /**
   * Load the BI report.
   */
  public ngOnInit(): void {
    this.powerBIService.loadPowerBIReportConfig(this.workspaceId, this.reportId).pipe(first()).subscribe(
      powerBIReportConfig => {
        const defaultPageName = !!this.appManager?.currentOrganization ?
          this.appManager.currentOrganization?.properties[this.reportDefaultPage]
          : this.reportDefaultPage;

        if (!!this.assetId) {
          powerBIReportConfig.filters.push(PowerBIReportConfig.buildFilter('assets', this.assetId));
        }

        this.powerBIReportConfig = powerBIReportConfig;
        this.powerBIReportConfig.pageName = defaultPageName;
        this.analyticsService.trackEvent(new AnalyticsEvent(ActionEnum.READ, this.relatedEntityType)
          .addProperties({
            [AnalyticsKeyEnum.REPORT_TYPE]: EntityTypeEnum.REPORT,
            [AnalyticsKeyEnum.REPORT_ID]: this.reportId
          }));
      });
  }

  /**
   * Call the service to print the powerBI report in pdf.
   */
  public exportBIReport(): void {
    // Remove filters on organizations if any other filter is applied.
    let filters = this.powerBIReportConfig.filters
      .filter(filter => filter.target.table !== 'organizations');
    if (filters.length === 0) filters = this.powerBIReportConfig.filters;

    this.powerBIService.exportPowerBIReports(
      this.workspaceId,
      this.reportId,
      filters
    )
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.analyticsService.trackEvent(
            new AnalyticsEvent(ActionEnum.EXPORT, this.relatedEntityType)
              .addProperties({
                [AnalyticsKeyEnum.REPORT_TYPE]: EntityTypeEnum.REPORT,
                [AnalyticsKeyEnum.REPORT_ID]: this.reportId
              })
          );
        })
      )
      .subscribe(() =>
        this.snackbar.showActionSnackbar(this.translate.instant('SUCCESS.SEND_EMAIL_EXPORT_REPORTS'))
      );
  }

  /**
   * Toggle full screen mode for the PowerBI report
   */
  public toggleFullScreen(): void {
    !document.fullscreenElement ? this.powerBI.nativeElement.requestFullscreen() : document.exitFullscreen();
  }

  /**
   * Destroy all charts and end subscriptions
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
