import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Check if this control as the same value as the given control.
 * @see https://github.com/rsaenen/ngx-custom-validators
 * @param equalControl The control to compare with.
 * @return ValidatorFn
 */
export const equalTo = (equalControl: AbstractControl): ValidatorFn => {
  let subscribe = false;

  return (control: AbstractControl): ValidationErrors => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }

    const v: string = control.value;

    return equalControl.value === v ? null : { equalTo: { control: equalControl, value: equalControl.value } };
  };
};
