import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Space } from '@app/core/model/entities/asset/space';
import { SpacesService } from '@app/features/main/views/organization-spaces/spaces.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';

export const isSpaceMoveAllowed = (spacesService: SpacesService, spaceToUpdate: Space): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {

    if (control.pristine) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      filter(value => value?.length),
      map((values: Space[]) => values.firstItem()),
      switchMap((newParent: Space) => spacesService.isSpaceMoveAllowed(spaceToUpdate.id, [...newParent.parentPath, newParent.id], false)),
      map((error) => !!error ? <ValidationErrors>{ isSpaceMoveAllowed: true } : null),
      first(),
      tap(() => control.markAsTouched())
    );
  }
}
