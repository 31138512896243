import { PayloadAction } from '@app/shared/store/model';
import { Action } from '@ngrx/store';

export enum UnitCrvActionTypes {
  FieldValueChange = 'unitCrv_change',
  FieldValueReset = 'unitCrv_reset',
  FieldValueRevert = 'unitCrv_revert'
}

export class UnitCrvChange extends PayloadAction {
  public readonly type = UnitCrvActionTypes.FieldValueChange;
}

export class UnitCrvReset extends PayloadAction {
  public readonly type = UnitCrvActionTypes.FieldValueReset;
}

export class UnitCrvRevert implements Action {
  public readonly type = UnitCrvActionTypes.FieldValueRevert;
}
