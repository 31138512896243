import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { VirtualTour } from '@app/core/model/entities/asset/virtual-tour';
import { calculateVectorLength, DEGREE_TO_RAD, RAD_TO_DEGREE } from '@app/shared/extra/utils';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@services/managers/app.manager';
import { ScriptService } from '@services/script.service';
import { filter, finalize, Observable, ReplaySubject, Subject, zip } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SafeResourceUrl } from '@angular/platform-browser';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';

/**
 * Abstract component implementing all the logics behind the Metareal Virtual tour using the Metareal SDK
 */
@Directive()
export abstract class AbstractVirtualTourComponent implements OnDestroy, OnInit, AfterViewInit {
  public entities: any[] = [];
  public currentVirtualTour: VirtualTour;
  public menuExpanded: boolean = false;
  public labelsForm: FormControl<any>;
  public selectTourForm: FormControl<string>;
  public panelContent: SafeResourceUrl;
  public EntityTypeEnum = EntityTypeEnum;

  // Component flags
  public panelVisible: boolean; // whether the panel is visible
  public teleport: boolean; // whether the user is teleporting himself
  public fullscreen = false;
  public loaded: boolean;
  public virtualTourLoaded$: Subject<void> = new Subject<void>();
  public entitiesLoaded$: ReplaySubject<void> = new ReplaySubject<void>(1);

  protected player: MetarealPlayerSDK.Player;
  protected currentLabel: MetarealPlayerSDK.Label;
  protected destroy$ = new Subject<void>();

  private readonly METAREAL_URL = '^\\bhttps:\\/\\/tour(\\-[a-z]{2})*\\.metareal\\.com\\/apps\\/player\\?asset\\=[a-f0-9]{8}(\\-[a-f0-9]{4}){3}\\-[a-f0-9]{12}$';

  // Container containing the Metareal Virtual tour
  public abstract container: ElementRef;
  // Container containing the component itself
  public abstract tour: ElementRef;

  public abstract virtualTours: VirtualTour[];

  protected constructor(protected scriptService: ScriptService,
                        protected changeDetectorRef: ChangeDetectorRef,
                        protected appManager: AppManager,
                        protected translate: TranslateService,
                        protected fb: FormBuilder) {
    this.selectTourForm = this.fb.control(null);
    this.labelsForm = this.fb.control(null);
  }

  /**
   * Return the entity ID
   * @param entity Entity data
   */
  protected abstract getEntityId(entity: Record<string, any>): string;

  /**
   * Fetch all entities related to the VirtualTour's Asset.
   */
  protected abstract getEntities(): Observable<any>;

  /**
   * Format entity display.
   * @param entity
   */
  public abstract valueFormatter(entity: any): string;

  /**
   * Change the iframe url to display the entity panel infos
   * @param entityId The entity ID
   * @return true if the panel content has been updated, false otherwise.
   */
  protected abstract setPanelContent(entityId: string): boolean;

  public ngOnInit(): void {
    this.currentVirtualTour = this.appManager.currentEntity as VirtualTour;

    this.loaded = false;
    zip(this.entitiesLoaded$, this.virtualTourLoaded$)
      .subscribe(_ => {
        this.loaded = true;
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * Start the tour
   */
  public ngAfterViewInit(): void {
    // Start the tour when the Metareal script is fully loaded
    this.scriptService.loadJsScript(this.container, environment.scripts.metarealSDK).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        // Create the player
        this.player = MetarealPlayerSDK.create('metareal');
        // Add parent properties in the iframe as a copy of parentElement properties, since Metareal SDK use it instead
        // of parentElement when destroying the player.
        const iframe = (this.container.nativeElement as HTMLDivElement).getElementsByTagName('iframe')[0];
        iframe['parent'] = iframe.parentElement;

        this.loadVirtualTour();
      }),
      switchMap(() => this.loadEntities()),
    ).subscribe();
  }

  /**
   * Load the current virtual tour in the player
   */
  public loadVirtualTour(): void {
    // Load the tour
    this.player.load(this.currentVirtualTour.url, () => {
      // Start the player automatically
      this.player.startShowcase();
      this.virtualTourLoaded$.next();
      this.changeDetectorRef.detectChanges();
    });
    // Set up the player and event listener
    this.player.setEventListener('ready', () => {
      this.player.hide('FULLSCREEN');

      this.entitiesLoaded$.subscribe(() => {
        this.entities = this.entities
          .filter(
            entity => !!this.player.tour.labels.find(label => label.link?.trim() === this.getEntityId(entity))
          )
          .sort((a, b) => this.valueFormatter(a).localeCompare(this.valueFormatter(b)));
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  /**
   * Fetch all entities related to the VirtualTour's Asset and bind virtual tour labels with the entities.
   */
  private loadEntities(): Observable<any> {
    // Load entities list
    return this.getEntities().pipe(
      tap(entities => {
        this.player.setEventListener('label2clicked', this.labelClicked.bind(this));
        this.player.setEventListener('roomviewpointchanged', this.hidePanel.bind(this));
        this.player.setEventListener('switchview', this.hidePanel.bind(this));
        this.player.setEventListener('startmove', this.moveHandler.bind(this));

        this.selectTourForm.reset();

        // Remove entities without associated labels
        this.entities = entities;
      }),
      // Display the entities list
      finalize(() => this.entitiesLoaded$.next()),
      switchMap(() => this.labelsForm.valueChanges),
      filter(label => !!label),
      tap(label => this.moveToSelectedEntity(this.getEntityId(label)))
    );
  }

  /**
   * Refresh the panel content if the label is linked to an entity.
   * @param label the label object
   * @return Whether the panel was refreshed.
   */
  protected refreshPanel(label): boolean {
    const entityId = label?.link.trim();
    if (!entityId) {
      this.hidePanel();
      alert(this.translate.instant('MESSAGE.NO_RELATED_ENTITY'));
      return false;
    } else {
      return this.setPanelContent(entityId);
    }
  }

  /**
   * Display the side panel
   */
  protected showPanel(): void {
    this.panelVisible = true;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Expand menu panel
   */
  public expandPanel(): void {
    this.menuExpanded = !this.menuExpanded;
  }

  /**
   * Update current virtual Tour
   * @param virtualTourId tour selected to be the current one
   */
  public updateCurrentTour(virtualTourId: string): void {
    this.hidePanel();
    this.labelsForm.reset();
    this.entities = [];
    this.loaded = false;
    this.destroy$.next();

    this.selectVirtualTour(virtualTourId);

    this.loadEntities().pipe(takeUntil(this.destroy$))
      .subscribe();
    this.loadVirtualTour();
  }

  /**
   * Switch to the selected Virtual Tour
   * @param virtualTourId Selected Virtual Tour ID
   * @private
   */
  private selectVirtualTour(virtualTourId: string): void {
    this.virtualTours.push(this.currentVirtualTour);
    const index = this.virtualTours.findIndex((tour) => tour.id === virtualTourId);
    this.currentVirtualTour = this.virtualTours.splice(index, 1).pop();
  }

  /**
   * Update the current Virtual Tour when the user select another one.
   * @param selectEvent
   */
  public loadTourFromSelectList(selectEvent: MatSelectChange): void {
    this.updateCurrentTour(selectEvent.value);
  }

  /**
   * Loads new tour based on url when a label linked to another tour is clicked
   * @param url Virtual Tour url
   * @private
   */
  private loadTourFromTransitionLabel(url: string): void {
    const virtualTourToLoad = this.virtualTours.find(virtualTour => virtualTour.url === url);
    this.updateCurrentTour(virtualTourToLoad.id);
  }

  /**
   * Event listener callback for the clicked event
   * @param label the label object
   */
  protected labelClicked(label: MetarealPlayerSDK.Label): void {
    const tourUrlRegexExp = RegExp(this.METAREAL_URL);
    if (tourUrlRegexExp.test(label.link)) {
      this.loadTourFromTransitionLabel(label.link);
    } else if (label !== this.currentLabel) {
      this.currentLabel = label;
      if (this.refreshPanel(this.currentLabel)) {
        this.showPanel();
      } else {
        // Previous label is not linked to an entity
        this.currentLabel = void 0;
      }
    } else {
      this.togglePanel();
    }
  }

  /**
   * Change the panel state : close it if open and vice versa
   */
  private togglePanel(): void {
    this.panelVisible ? this.hidePanel() : this.showPanel();
  }

  /**
   * Hide the side panel
   */
  public hidePanel(): void {
    this.currentLabel = void 0;
    this.panelVisible = false;
    this.labelsForm.reset();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Open or refresh the sheet if the user teleport to a label otherwise close it.
   * @private
   */
  protected moveHandler(): void {
    if (!this.teleport) return this.hidePanel();
    // End teleportation
    this.teleport = false;
  }

  /**
   * Compute the Euler rotation of camera in order to point at the label.
   * @param labelPosition Label's euclidean coordinates.
   * @param roomRotation Euler rotation of the room in 3D space.
   * @param closestPanorama Closest Panorama
   * @return Euler rotation angles.
   */
  private computeCameraOrientation(labelPosition: MetarealPlayerSDK._vector,
                                   roomRotation: MetarealPlayerSDK._vector,
                                   closestPanorama: MetarealPlayerSDK.Panorama): MetarealPlayerSDK._vector {
    const panoramaPosition = closestPanorama.position;

    // Calculate the label position depending on the panorama rotation around y axis
    const panoramaRotationY = ((closestPanorama.rotation.y) * DEGREE_TO_RAD) % Math.PI;
    const X = labelPosition.x * Math.cos(panoramaRotationY) + labelPosition.z * Math.sin(panoramaRotationY);
    const Z = -labelPosition.x * Math.sin(panoramaRotationY) + labelPosition.z * Math.cos(panoramaRotationY);

    // Position is relative to the room. We are going to calculate using relative panorama position
    const labelVector = {
      x: X - panoramaPosition.x,
      y: labelPosition.y - panoramaPosition.y - closestPanorama.height,
      z: Z - panoramaPosition.z
    };

    // Define camera angle for x and y axis. Y axis is the vertical one, x axis is the horizontal one.
    // Calculate the spherical coordinate of the label vector
    const polarAngle = Math.atan2(labelVector.x, labelVector.z) * RAD_TO_DEGREE;
    const radialCoordinate = Math.sqrt(
      labelVector.x * labelVector.x + labelVector.y * labelVector.y + labelVector.z * labelVector.z
    );
    const azimuthalAngle = (Math.acos(labelVector.y / (radialCoordinate)) - (Math.PI / 2)) * RAD_TO_DEGREE;

    return {x: azimuthalAngle, y: polarAngle + roomRotation.y, z: 0};
  }


  /**
   * Move the tour camera to the entity
   * @param entityId The entity ID
   * @private
   */
  protected moveToSelectedEntity(entityId: string): void {
    // Starting teleportation
    this.teleport = true;

    // Determine the room containing the label
    this.currentLabel = this.player.tour.labels.find(label => label.link?.trim() === entityId);
    const roomContainingLabel = this.player.tour.rooms.find(
      room => room.labels.map(label => label.id).includes(this.currentLabel.id)
    );
    // Determine closer panorama
    const labelPosition = this.currentLabel.position;
    const closestPanorama = roomContainingLabel.panoramas.reduce((previousValue, currentValue) => {
      const aLength = calculateVectorLength(labelPosition, previousValue.position);
      const bLength = calculateVectorLength(labelPosition, currentValue.position);
      return aLength < bLength ? previousValue : currentValue;
    });

    // Move camera
    this.player.moveToPanorama(closestPanorama, 'teleport');
    const cameraOrientation = this.computeCameraOrientation(
      labelPosition,
      roomContainingLabel.rotation,
      closestPanorama
    );
    this.player.setTourViewCamera(cameraOrientation);

    // Update sidebar
    if (this.refreshPanel(this.currentLabel)) {
      this.showPanel();
    }
  }

  /**
   * Toggle in or out fullscreen mode
   */
  public toggleFullScreen(): void {
    if (this.fullscreen) {
      document.exitFullscreen
        ? document.exitFullscreen().catch(console.error) // Standard syntax
        : (document as any).webkitExitFullscreen(); // Safari
    } else {
      this.tour.nativeElement.requestFullscreen
        ? this.tour.nativeElement.requestFullscreen() // Standard syntax
        : this.tour.nativeElement.webkitFullscreenEnabled(); // Safari
    }
  }

  /**
   * Change fullscreen state variable when entering or exiting fullscreen mode.
   */
  public onFullscreenChange(): void {
    this.fullscreen = !this.fullscreen;
  }

  /**
   * Free resources
   */
  public ngOnDestroy(): void {
    // Destroy the player
    MetarealPlayerSDK.destroy(this.player);
    this.destroy$.next();
    this.destroy$.complete();
    this.entitiesLoaded$.complete();
    this.virtualTourLoaded$.complete();
  }
}
