<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_PROJECT' | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="projectForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.PROJECT_NAME' | translate }}</mat-label>
        <input matInput id="input_project_name" formControlName="name" required/>
        <mat-error *ngIf="projectForm.get('name').hasError('required')">
          {{ 'ERROR.FIELD_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="projectForm.get('name').hasError('maxLength')">
          {{ 'ERROR.FIELD_MAXLENGTH' | translate: {value: appConfig.FIELD_MAX_LENGTH} }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button [id]="'create-project-cancel-button'" matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button [id]="'create-project-add-button'" [disabled]="projectForm.invalid"
            color="accent"
            matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
