import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig,
  FieldConfigValidator
} from '@app/core/model/other/field-config';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'dpe-field-builder',
  templateUrl: './dpe-field-builder.component.html',
  styleUrls: ['./dpe-field-builder.component.scss']
})
export class DpeFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  public Permission = PermissionEnum;


  public dpeList: string[];

  public cssClass;

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(entity, data, eventsOrigin, formStateService, fieldConfig, preconditionsForEdition, permissionsForEdition, appManager, appConfig, accessManager, media, translate, validationService, singleEditService, analyticsService);

    this.dpeList = this.appConfig.DPE_LIST;
  }

  public ngOnInit(): void {
    if (this.fieldConfig.fieldCode === 'powerLabel') {
      this.cssClass = 'power-color';
    } else if (this.fieldConfig.fieldCode === 'climateLabel') {
      this.cssClass = 'climate-color';
    }
    this.getFieldGroupInfo(['isDisplayed']).next(this.entity['type'] === this.fieldConfig.field?.checkType);
    this.form = new UntypedFormGroup({
      field: new UntypedFormControl(
        this.fieldInitValue,
        this.computeValidators()
      )
    });
    // Initialise the field in the registry
    this.setFieldValue(this.fieldInitValue);
    this.setFieldInitialValue(this.fieldInitValue);
    this.getNextState();
  }

  public ngAfterViewInit(): void {
    this.setupHooks();

    this.form.get('field').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.setFieldValue(value);
    });
  }

  public cancel(): void {
    this.form.get('field').setValue(this.getFieldInitialValue());
    super.cancel();
  }

  public isLabelSelected(label): string {
    return (label === this.form.get('field').value) ? 'selected-dpe-label' : '';
  }

  public selectDpeValue(value): void {
    this.setFieldValue(value);
    this.form.setValue({ 'field': value });
    this.onClickSave();
  }

  protected computeValidators(): ValidatorFn {
    this.fieldConfig.field?.validators.push(<FieldConfigValidator>{ type: 'NOT_IN_ARRAY', definition: this.fieldConfig.field?.fieldValues });
    return super.computeValidators();
  }

}
