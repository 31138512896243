import { Directive, HostListener } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormControlStatus,
  FormGroup,
  UntypedFormBuilder,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { AppConfig } from '@app/core/app.config';
import { Entity } from '@app/core/model/entities/entity';
import { FieldConfig, FieldConfigValidator, isValidatorFieldDependant } from '@app/core/model/other/field-config';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { getValue } from '@app/shared/extra/utils';
import { ExtendedColDef } from '@app/shared/grid/column-builder';
import { ValidationService } from '@app/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-enterprise';
import { from, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface CustomCellEditorParams {
  required: boolean;
  suffix: string;
  suggestedOptions: any[];
  validators: FieldConfigValidator[];
  entityList: any[];
  fieldConfig: FieldConfig;
  control: AbstractControl;
}

@Directive()
export abstract class AbstractCellEditor implements ICellEditorAngularComp {

  public params: Partial<ICellEditorParams & CustomCellEditorParams>;
  public fullRowEdit = false;
  public focusAfterAttached: boolean;

  public entity?: Entity;
  public required?: boolean = false;

  public control: AbstractControl;
  public errorTooltip: string;

  protected destroy$ = new Subject<void>();

  protected constructor(public fb: UntypedFormBuilder,
                        public formStateService: FormStateService,
                        public validationService: ValidationService,
                        public translateService: TranslateService,
                        public appConfig: AppConfig) {
    this.control = this.fb.control(null);
  }


  private validateCanStopEditing(): boolean {
    return this.control.valid;
  }

  /**
   * Uses Angular's composition of validators on all validators from the field configuration
   * @returns {ValidatorFn}
   */
  protected computeValidators(validators: FieldConfigValidator[]): ValidatorFn {
    return Validators.compose(validators?.map((validator: FieldConfigValidator) => this.validationService.getValidator(
      validator,
      this.params.fieldConfig,
      this.entity
    )));
  }

  /**
   * Uses Angular's composition of async validators on all validators from the field configuration
   * @returns {AsyncValidatorFn}
   */
  protected computeAsyncValidators(validators: FieldConfigValidator[]): AsyncValidatorFn {
    return Validators.composeAsync(validators?.map((validator: FieldConfigValidator) => this.validationService.getAsyncValidator(
      validator,
      this.params.fieldConfig,
      this.entity
    )));
  }

  @HostListener('keydown.escape', ['$event'])
  @HostListener('keydown.tab', ['$event'])
  public onEscapeOrTab(): void {
    if (this.fullRowEdit) {
      return;
    }
    this.params.eGridCell.classList.remove('pending-cell');
    this.params.eGridCell.classList.remove('error-cell');
    this.params.api.stopEditing(true);
    // Keep the focus on the current cell
    this.params.stopEditing(true);
  }

  @HostListener('keydown.enter', ['$event'])
  public onEnter(event: KeyboardEvent): void {
    if (this.fullRowEdit) {
      return;
    }
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.stopEditing();
  }

  /**
   * Initialisation function common to all cell editors
   * Sets up the form control for each field with its sync and async validators
   * Subscribes to change in formcontrol status to add styling to a cell where some validation failed
   * along with the tooltip of the combined errors
   * @param params
   * @protected
   */
  public agInit(params: Partial<ICellEditorParams & CustomCellEditorParams>): void {
    this.params = params;
    this.entity = this.params.data;
    this.focusAfterAttached = this.params.cellStartedEdit;
    this.required = this.params.required;
    this.fullRowEdit = (this.params.colDef as ExtendedColDef).fullRowEdit;

    // Convert validators which depend on another field of the entity
    this.params.validators = this.params.validators?.map(validator => {
      const initialValue = isValidatorFieldDependant(validator.type) ?
        getValue(this.entity, validator.definitionPath)
        : validator.definition;
      return this.validationService.convertFieldDependantValidator(validator, initialValue);
    });

    // Add validators to the control.
    if (!!params.control) {
      this.control = params.control;
      this.control.addValidators(this.computeValidators(params.validators));
      if (!!params.fieldConfig) {
        this.control.addAsyncValidators(this.computeAsyncValidators(params.validators));
      }
    } else {
      this.control.setValidators(this.computeValidators(params.validators));
      this.control.setAsyncValidators(this.computeAsyncValidators(params.validators));
    }

    // Add value to the control.
    // The control will check if it's valid or not.
    const isFormGroup = this.control instanceof FormGroup;
    if ((params.value || ((typeof params.value) === 'number' && !Number.isNaN(params.value))) && params.value?.toString() !== this.appConfig.GRID_EMPTY_VALUE) {
      this.control.setValue(this.params.value);
    } else if (!isFormGroup) {
      this.control.setValue('');
    }

    if (isFormGroup) {
      return;
    }

    // If the control is invalid, show it on the GUI.
    if (this.control.invalid) {
      this.params.eGridCell.classList.add('error-cell');
      const validator = this.params.validators?.firstOrNull((validator) => {
        return this.control.hasError(validator.type);
      });
      if (!!validator) {
        this.setTooltip(validator.type, validator.definition);
      }
    }

    // When the control statues changes, show it on the GUI.
    this.control.statusChanges
      .pipe(
        takeUntil(this.destroy$),
        // We wait for the async validation to complete first before adding or removing error cell style
        tap((status: FormControlStatus) => {
          if (status === 'INVALID') {
            this.params.eGridCell.classList.remove('pending-cell');
            this.params.eGridCell.classList.add('error-cell');
          } else if (status === 'PENDING') {
            this.params.eGridCell.classList.remove('error-cell');
            this.params.eGridCell.classList.add('pending-cell');
          } else {
            this.params.eGridCell.classList.remove('error-cell', 'pending-cell');
          }
        }),
        filter(() => this.params.validators?.length > 0),
        switchMap((): Observable<FieldConfigValidator> => {
          return from(this.params.validators)
            .pipe(filter((validator: FieldConfigValidator) => this.control.hasError(validator.type)));
        })
      )
      .subscribe((validator: FieldConfigValidator) => {
        this.setTooltip(validator.type, validator.definition);
      });
  }

  /**
   * Return the final value - called by the grid once after editing is complete
   */
  public getValue(): any {
    return this.control.value;
  }

  /**
   * Return the control cast as FormControl
   */
  public get formControl(): FormControl {
    return this.control as FormControl;
  }

  /**
   *  Gets called once after editing is complete. If you return true, then the new
   *  value will not be used. The editing will have no impact on the record.
   */
  public isCancelAfterEnd(): boolean {
    this.params.eGridCell.classList.remove('pending-cell');
    this.params.eGridCell.classList.remove('error-cell');
    return !this.control.valid || this.control.pristine;
  }

  /**
   * Convenience method to notify the grid to exit the edit mode
   */
  public stopEditing(): void {
    if (this.validateCanStopEditing()) {
      this.params.eGridCell.classList.remove('pending-cell');
      this.params.eGridCell.classList.remove('error-cell');
      this.params.api.stopEditing();
    } // if invalid, enter does nothing
  }

  /**
   * Update tooltip message in case of validation error
   * @param type Type of the validator in error
   * @param definition Definition of the validator in error
   */
  protected setTooltip(type: string, definition: string | number): void {
    this.errorTooltip = this.translateService.instant(
      `ERROR.FIELD_${type}`.toUpperCase(),
      {value: definition}
    );
  }
}
