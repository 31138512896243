import { Component } from '@angular/core';
import { Check } from '@app/core/model/entities/asset/check';
import { CheckType } from '@app/core/model/entities/asset/check-type';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'check-result-cell',
  templateUrl: './check-result-cell.component.html',
  styleUrls: ['./check-result-cell.component.scss']
})
export class CheckResultCellComponent implements ICellRendererAngularComp {

  public params: any;
  public check: Check;
  public checkTypes: { [k: string]: CheckType };
  public checkResult: string;

  /**
   * Method used by Ag-grid to initialise the cell with grid parameters
   * There are two cases for the cell : the first one is when we receive an array of assetChecks (master grid) and the second is when we receive a simple check (detail grid),
   * so we need to check if the data received has one or more elements.
   * @param params
   */
  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.check = this.params.data;
    this.checkTypes = this.params.checkTypes;
    this.checkResult = this.check?.result;
  }

  /**
   * Method used by Ag-Grid to tell the grid to refresh this cell
   * This component does not include refresh logic
   * @param params
   * @returns {boolean}
   */
  public refresh(params: any): boolean {
    return false;
  }
}
