<div class="header-field-builder-container">
  <div *ngIf="currentMode === FieldMode.SAVING" class="field-single-container">
    <div class="saving-field-container">
      <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
        <div class="field-container">
          {{ 'LABEL.SAVE_IN_PROGRESS' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="{'field-single-container': currentMode === FieldMode.EDIT || currentMode === FieldMode.READ}">
    <div [ngClass]="{'edit-field-container': currentMode === FieldMode.EDIT}">
      <ng-template fieldAnchor>
      </ng-template>
    </div>
  </div>
</div>
