import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';

export const notInArray = (array: any[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isPresent(array)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }

    let items: any[];
    if (Array.isArray(control.value)) {
      items = control.value;
    } else {
      items = [control.value];
    }
    return items.every(value => array.includes(value)) ? null : { NOT_IN_ARRAY: true };
  };
};
