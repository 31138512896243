<div class="field-builder-container">

  <div *ngIf="currentMode === FieldMode.SAVING" class="field-custom-container">
    <div class="saving-field-container">
      <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
        </div>

        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          {{ 'LABEL.SAVE_IN_PROGRESS' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.EMPTY" class="field-custom-container">
    <div class="empty-field-container">
      <div
        [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
        class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
        </div>

        <div class="field-container fx-layout-col fx-flex-100-66-percent">
          <div class="empty-value">
            <ng-container
              *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition; else noPermissions">
              <add-field-link (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
                              [inputMessage]="fieldGroup.emptyLabel"></add-field-link>
            </ng-container>
            <ng-template #noPermissions>
              {{ appConfig.EMPTY_FIELD_VALUE }}
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.READ" class="field-custom-container">
    <div class="read-field-container">
      <div
        [ngClass]="{'read-value': !accessManager.hasAllNeededPermissions(permissionsForEdition) || !preconditionsForEdition}"
        class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">

        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
        </div>
        <div class="field-container fx-layout-col fx-gap-5 fx-flex-100-66-percent">
          <div (click)="onClickActivate($event)" (keyup.enter)="onEnterActivate($event)"
               [ngClass]="{'read-value': accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition, 'computed': !(accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition)}"
               tabindex="0">
            <div class="formatted-string">
              {{ formatAddress() }}
            </div>
            <span *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition)"
                  class="overlay-icon">
              <i *ngIf="preconditionsForEdition else readOnly;" class="mdi mdi-pencil selectable"></i>
              <ng-template #readOnly><i [matTooltipPosition]="'below'" [matTooltip]="'TOOLTIP.READ_ONLY_VALUE' | translate" class="mdi mdi-pencil-off tooltip-icon"></i></ng-template>
            </span>
          </div>
          <div class="fx-layout-row">
            <div (click)="onClickActivate($event)" class="map-zone fx-flex-100-50-percent">
              <single-asset-map [allowZooming]="false"
                                [allowMarkerDragging]="false"
                                [allowPanning]="false"
                                [allowClicking]="false"
                                [address]="completeAddress"
                                [permissionsForEdition]="permissionsForEdition"
                                height="250px"
                                width="100%">
              </single-asset-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentMode === FieldMode.EDIT" class="field-custom-container">
    <div class="edit-field-container">
      <div class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
        <!-- label -->
        <div class="field-label fx-flex-100-33-percent">
          {{ fieldGroup.label }}
        </div>
        <!-- fields -->
        <div class="field-container fx-flex-100-66-percent fx-layout-col">
          <form autocomplete="off" class="fx-layout-col" [formGroup]="form">
            <div class="streetNumber street fx-layout-row fx-gap-10">
              <div class="fx-flex-30-percent">
                <!-- civic number -->
                <mat-form-field appearance="outline">
                  <mat-label>{{ fieldGroup.fieldConfigs[0].label }}</mat-label>
                  <input formControlName="streetNumber" matInput inputFieldBuilderAutoFocus type="text">
                  <span *ngIf="fieldGroup.fieldConfigs[0].suffixType" matTextSuffix>
                    {{ fieldGroup.fieldConfigs[0].suffixType | translate }}
                  </span>
                  <mat-icon *ngIf="fieldGroup.fieldConfigs[0].tooltip" [matTooltipPosition]="'after'"
                            [matTooltip]="fieldGroup.fieldConfigs[0].tooltip"
                            matIconSuffix>help
                  </mat-icon>

                  <mat-error>
                    <ng-template ngFor let-validator [ngForOf]="fieldGroup.fieldConfigs[0].field.validators">
                      <span *ngIf="form.get('streetNumber').hasError(validator.type)">
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </span>
                    </ng-template>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="fx-flex-70-percent">
                <!-- street -->
                <mat-form-field appearance="outline">
                  <mat-label>{{ fieldGroup.fieldConfigs[1].label }}</mat-label>
                  <input formControlName="street" matInput type="text">
                  <span *ngIf="fieldGroup.fieldConfigs[1].suffixType" matTextSuffix>
                    {{ fieldGroup.fieldConfigs[1].suffixType | translate }}
                  </span>
                  <mat-icon *ngIf="fieldGroup.fieldConfigs[1].tooltip" [matTooltipPosition]="'after'"
                            [matTooltip]="fieldGroup.fieldConfigs[1].tooltip"
                            matIconSuffix>help
                  </mat-icon>

                  <mat-error>
                    <ng-template ngFor let-validator [ngForOf]="fieldGroup.fieldConfigs[1].field.validators">
                      <span *ngIf="form.get('street').hasError(validator.type)">
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </span>
                    </ng-template>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="postalcode city country fx-layout-row fx-gap-10">
              <div class="fx-flex-20-percent">
                <!-- postal code -->
                <mat-form-field appearance="outline">
                  <mat-label>{{ fieldGroup.fieldConfigs[2].label }}</mat-label>
                  <input formControlName="postalCode" matInput type="text">
                  <span *ngIf="fieldGroup.fieldConfigs[2].suffixType" matTextSuffix>
                    {{ fieldGroup.fieldConfigs[2].suffixType | translate }}
                  </span>
                  <mat-icon *ngIf="fieldGroup.fieldConfigs[2].tooltip" [matTooltipPosition]="'after'"
                            [matTooltip]="fieldGroup.fieldConfigs[2].tooltip"
                            matIconSuffix>help
                  </mat-icon>

                  <mat-error>
                    <ng-template ngFor let-validator [ngForOf]="fieldGroup.fieldConfigs[2].field.validators">
                      <span *ngIf="form.get('postalCode').hasError(validator.type)">
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </span>
                    </ng-template>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="fx-flex-40-percent">
                <!-- city -->
                <mat-form-field appearance="outline">
                  <mat-label>{{ fieldGroup.fieldConfigs[3].label }}</mat-label>
                  <input formControlName="city" matInput type="text">
                  <span *ngIf="fieldGroup.fieldConfigs[3].suffixType" matTextSuffix>
                    {{ fieldGroup.fieldConfigs[3].suffixType | translate }}
                  </span>
                  <mat-icon *ngIf="fieldGroup.fieldConfigs[3].tooltip" [matTooltipPosition]="'after'"
                            [matTooltip]="fieldGroup.fieldConfigs[3].tooltip"
                            matIconSuffix>help
                  </mat-icon>

                  <mat-error>
                    <ng-template ngFor let-validator [ngForOf]="fieldGroup.fieldConfigs[3].field.validators">
                      <span *ngIf="form.get('city').hasError(validator.type)">
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </span>
                    </ng-template>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="fx-flex-40-percent">
                <mat-form-field appearance="outline">
                  <mat-label>{{ fieldGroup.fieldConfigs[4].label }}</mat-label>
                  <mat-select formControlName="country">
                    <mat-option [value]="''">{{ 'LABEL.UNDEFINED' | translate }}</mat-option>
                    <mat-option *ngFor="let fieldValue of fieldGroup.fieldConfigs[4].field.fieldValues"
                                [value]="fieldValue">{{ 'VALUE.COUNTRY_' + fieldValue | translate }}
                    </mat-option>
                  </mat-select>
                  <span *ngIf="fieldGroup.fieldConfigs[4].suffixType" matTextSuffix>
                    {{ fieldGroup.fieldConfigs[4].suffixType | translate }}
                  </span>
                  <mat-icon *ngIf="fieldGroup.fieldConfigs[4].tooltip" [matTooltipPosition]="'after'"
                            [matTooltip]="fieldGroup.fieldConfigs[4].tooltip"
                            matIconSuffix>help
                  </mat-icon>

                  <mat-error>
                    <ng-template ngFor let-validator [ngForOf]="fieldGroup.fieldConfigs[4].field.validators">
                      <span *ngIf="form.get('country').hasError(validator.type)">
                        {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                      </span>
                    </ng-template>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="location-link">
              <!-- Location link -->
              <span tabindex="0" (click)="map.updateLocationWithAddress()">
                {{ 'LABEL.LOCATE' | translate }}
              </span>
            </div>
            <div class="fx-layout-row">
              <div class="map-zone fx-flex-100-60-percent">
                <single-asset-map [allowZooming]="true"
                                  [allowClicking]="true"
                                  [allowPanning]="accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition"
                                  [allowMarkerDragging]="accessManager.hasAllNeededPermissions(permissionsForEdition) && preconditionsForEdition"
                                  [address]="completeAddress"
                                  [permissionsForEdition]="permissionsForEdition"
                                  (positionUpdated)="updateLatLong($event)"
                                  height="350px"
                                  width="100%">
                </single-asset-map>
              </div>
            </div>
            <mat-accordion displayMode="flat" togglePosition="before">
              <mat-expansion-panel id="gps-coordinates">
                <!-- GPS coordinates link -->
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ 'LABEL.GPS_COORDINATES' | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="latitude longitude fx-layout-row fx-gap-10">
                  <div class="fx-flex-49-30-percent">
                    <!-- latitude -->
                    <mat-form-field appearance="outline">
                      <mat-label>{{ fieldGroup.fieldConfigs[5].label }}</mat-label>
                      <input (beforeinput)="validateKeypressEvent($event)" (paste)="validatePasteEvent($event)"
                             formControlName="latitude" matInput
                             type="text">
                      <span *ngIf="fieldGroup.fieldConfigs[5].suffixType" matTextSuffix>
                        {{ fieldGroup.fieldConfigs[5].suffixType | translate }}
                      </span>
                      <mat-icon *ngIf="fieldGroup.fieldConfigs[5].tooltip" [matTooltipPosition]="'after'"
                                [matTooltip]="fieldGroup.fieldConfigs[5].tooltip"
                                matIconSuffix>help
                      </mat-icon>

                      <mat-error>
                        <ng-template ngFor let-validator [ngForOf]="fieldGroup.fieldConfigs[5].field.validators">
                          <span *ngIf="form.get('latitude').hasError(validator.type)">
                            {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                          </span>
                        </ng-template>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="fx-flex-49-30-percent">
                    <!-- longitude -->
                    <mat-form-field appearance="outline">
                      <mat-label>{{ fieldGroup.fieldConfigs[6].label }}</mat-label>
                      <input (beforeinput)="validateKeypressEvent($event)" (paste)="validatePasteEvent($event)"
                             formControlName="longitude"
                             matInput
                             type="text">
                      <span *ngIf="fieldGroup.fieldConfigs[6].suffixType" matTextSuffix>
                        {{ fieldGroup.fieldConfigs[6].suffixType | translate }}
                      </span>
                      <mat-icon *ngIf="fieldGroup.fieldConfigs[6].tooltip" [matTooltipPosition]="'after'"
                                [matTooltip]="fieldGroup.fieldConfigs[6].tooltip"
                                matIconSuffix>help
                      </mat-icon>

                      <mat-error>
                        <ng-template ngFor let-validator [ngForOf]="fieldGroup.fieldConfigs[6].field.validators">
                          <span *ngIf="form.get('longitude').hasError(validator.type)">
                            {{ 'ERROR.FIELD_' + validator.type | uppercase | translate: {value: validator.definition} }}
                          </span>
                        </ng-template>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </form>
          <div class="button-container fx-layout-row fx-justify-end fx-align-center">
            <!-- cancel + save -->
            <button (click)="onClickCancel()" (keyup.escape)="onEscapeCancel($event)"
                    mat-stroked-button>{{ 'BUTTON.CANCEL' | translate }}
            </button>
            <button (click)="onClickSave()" (keyup.escape)="onEscapeCancel($event)" [disabled]="!form.valid"
                    color="accent"
                    mat-raised-button>{{ 'BUTTON.SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
