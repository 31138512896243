<div class="fx-justify-start fx-align-center">
  <div [ngClass]="{'active':show && !disabled, 'disabled': disabled, 'inline-edit': control?.value || show, 'error': control.invalid}" tabindex="0"
       (keyup.enter)="makeEditable()">
    <ng-container *ngIf="!show; else input">
      <div *ngIf="control?.value; else empty" [id]="'inline_edit_container'" class="inline-edit-container">
        <div (click)="makeEditable()" [class.selectable]="full === true" class="inline-edit-value">
          <span>{{ control?.value | fieldFormatType: 'numeric'  }}</span>
          <span *ngIf="suffix">{{suffix}}</span>
        </div>
        <div *ngIf="!disabled" (click)="makeEditable()" class="inline-edit-content selectable">
          <span class="overlay-icon">
            <i aria-hidden="true" class="mdi mdi-pencil selectable"></i>
          </span>
        </div>
      </div>
    </ng-container>

    <ng-template #empty>
      <div class="empty-field-container">
        <div
          [ngClass]="{'no-value': !accessManager.hasAllNeededPermissions(permissionsForEdition)}"
          class="field-row fx-layout-row fx-wrap fx-justify-start fx-align-baseline">
          <div class="field-container fx-flex-100-66-percent fx-layout-col">
            <div class="empty-value">
              <ng-container
                *ngIf="accessManager.hasAllNeededPermissions(permissionsForEdition); else noPermissions">
                <add-field-link (click)="onActivate($event)" (keyup.enter)="onActivate($event)"
                                [inputMessage]="'LABEL.EMPTY_' + reduxFieldLabel.toUpperCase()"></add-field-link>
              </ng-container>
              <ng-template #noPermissions>
                {{ appConfig.EMPTY_FIELD_VALUE }}
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #input>
      <div class="field-group">
        <form autocomplete="off" class="fx-layout-row fx-justify-start fx-align-baseline">
          <input #element matInput (focus)="onFocus()"
                 (keyup.escape)="onKey($event)" (keyup.enter)="onKey($event)"
                 (beforeinput)="validateKeypressEvent($event)" (paste)="validatePasteEvent($event)"
                 [disabled]="disabled" [formControl]="control"
                 [id]="'inline_input_string'" class="inline-edit-input" tabindex="0" type="text"/>
          <span *ngIf="suffix">{{ suffix }}</span>
        </form>
      </div>
    </ng-template>

    <div *ngIf="show" class="save-options">
      <button (click)="cancelEditable()" class="button">
        <i aria-hidden="true" [id]="'inline-input-cancel-button'" class="mdi mdi-close"></i>
      </button>
      <button (click)="callSave()" [disabled]="preventSave()" [id]="'inline-input-save-button'" class="button save">
        <i aria-hidden="true" class="mdi mdi-check"></i>
      </button>
    </div>

    <div class="inline-field-errors fx-layout-col fx-justify-space-between fx-align-start">
      <ng-content>
      </ng-content>
    </div>
  </div>
</div>
