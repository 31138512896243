export enum ActionEnum {
  READ = 'read',
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
  IMPORT = 'import',
  EXPORT = 'export',
  RENEW = 'renew',
  COPY = 'copy',
  SAVE = 'save',
  SELECT = 'select',
  ADD = 'add',
  CANCEL = 'cancel',
  DOWNLOAD = 'download',
  UPLOAD = 'upload',
  NAVIGATE = 'navigate',
  GRID = 'grid',
  SEND = 'send',
  DUPLICATE = 'duplicate',
  REFRESH = 'refresh'
}

export enum EventOriginEnum {
  MENUBAR = 'menuBar',
  BREADCRUMB = 'breadcrumb',
  HEADER = 'header',
  FIELD_CLICK = 'fieldClick',
  SIDEBAR = 'sidebar',
  DASHBOARD_MENU = 'dashboardMenu',
  ORGANIZATION_LIST = 'organizationList',
  TAB = 'tab',
  ROW_CLICK = 'rowClick',
  BUTTON = 'button',
  GRID_BUTTON = 'gridButton',
  SHEET = 'sheet',
  CONTEXT_MENU = 'contextMenu',
  ASSET_DASHBOARD = 'assetDashboard',
  ASSET_CRV = 'assetCrv',
  NOTIFICATION_POPUP = 'notificationPopup',
  SIDEPANEL = 'sidepanel'
}

export enum ModuleEnum {
  ASSET = 'asset',
  ORGANIZATION = 'organization',
  ADMINISTRATION = 'administration',
}

export enum NavigateToEnum {
  CREATE_DIALOG = 'createDialog',
  DELETE_DIALOG = 'deleteDialog',
  EDIT_DIALOG = 'editDialog',
  MOVE_DIALOG = 'moveDialog',
  RENEW_DIALOG = 'renewDialog',
  DUPLICATE_DIALOG = 'duplicateDialog',
  EDIT_INLINE = 'editInline',
  UPLOAD_DIALOG = 'uploadDialog',
  EXPORT_DIALOG = 'exportDialog',
  IMPORT_DIALOG = 'importDialog',
  SHEET = 'sheet',
  GRID = 'grid',
  DASHBOARD = 'dashboard',
  PROFILE = 'profile',
  CLOSE_NOTIFICATION = 'closeNotification',
  NEW_TAB = 'newTab',
  SIDEPANEL = 'sidepanel'
}

export enum GridActionTypeEnum {
  ERASE_FILTER = 'eraseFilter',
  FILTER = 'filter',
  SORT = 'sort',
  SEARCH = 'search',
  ROW_GROUP = 'rowGroup',
  DISPLAY_COLUMN = 'displayColumn',
  MOVE_COLUMN = 'moveColumn',
  PINNED = 'pinned',
  OPEN_TOOL_PANNEL = 'openToolPannel',
  CHART = 'chart',
  CELL_CLICK = 'cellClick'
}
