<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_ASSET' | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="assetForm" novalidate>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.ASSET_NAME' | translate }}</mat-label>
        <input matInput formControlName="assetName" [id]="'input-asset-name'"/>
        <mat-error *ngIf="assetForm.get('assetName').hasError('required')">
          {{ 'ERROR.FIELD_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="assetForm.get('assetName').hasError('maxLength')">
          {{ 'ERROR.FIELD_MAXLENGTH' | translate:{value: appConfig.FIELD_MAX_LENGTH} }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.ASSETSTATE' | translate }}</mat-label>
        <input matInput formControlName="assetState" [matAutocomplete]="assetStateAutocomplete" [id]="'input-asset-state'">
        <mat-autocomplete #assetStateAutocomplete="matAutocomplete">
          <mat-option *ngFor="let fieldValue of filteredAssetStates$ | async" [value]="fieldValue">
            {{ fieldValue }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="assetForm.get('assetState').hasError('required')">
          {{ 'ERROR.FIELD_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="assetForm.get('assetState').hasError('maxLength')">
          {{ 'ERROR.FIELD_MAXLENGTH' | translate: {value: appConfig.FIELD_MAX_LENGTH} }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.ASSET_TYPE' | translate }}</mat-label>
        <mat-select formControlName="assetType" [required]="true" [id]="'input-asset-type'">
          <mat-option *ngFor="let type of assetTypes$ | async" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="assetForm.get('assetType').hasError('required')">
          {{ 'ERROR.FIELD_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button [id]="'asset-cancel-button'" mat-stroked-button matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button [disabled]="!assetForm.valid"
            [id]="'asset-save-button'"
            color="accent"
            mat-raised-button
            matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
