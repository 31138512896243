import { Action } from '@ngrx/store';

export class PayloadAction implements Action {
  type: string;

  constructor(public payload?: any) {
  }
}

export class AnonymousAction implements Action {
  type: string;

  constructor(public newType: string) {
    this.type = newType;
  }
}
