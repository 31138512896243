import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';
import dayjs, { Dayjs } from 'dayjs';

export const beforeDate = (dateToCompare: Dayjs): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!dateToCompare || isPresent(Validators.required(control))) {
      return null;
    }
    const startDate = dayjs(control.value);
    const endDate = dayjs(dateToCompare);
    return (endDate.isBefore(startDate) || endDate.isSame(startDate)) ? { BEFORE_DATE: true } : null;
  };
};
