import { Directive, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

import { notInArray } from './validator';

const IN_ARRAY_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NotInArrayValidator),
  multi: true
};

@Directive({
  selector: '[notInArray][formControlName],[notInArray][formControl],[notInArray][ngModel]',
  providers: [IN_ARRAY_VALIDATOR]
})
export class NotInArrayValidator implements Validator, OnInit, OnChanges {
  @Input() array: any[];

  private validator: ValidatorFn;
  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = notInArray(this.array);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'NOT_IN_ARRAY') {
        this.validator = notInArray(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
