import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { environment } from '@env/environment';
import packageJson from '@root/package.json';
import { ErrorManager } from '@services/managers/error.manager';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

export function apolloLinkFactory(httpLink: HttpLink, errorManager: ErrorManager): ApolloClient<NormalizedCacheObject> {
  const errorLink = onError((errorHandler) => {

    // GraphQL errors may not be parsed correctly by Apollo client due to using apollo-angular's link.
    // If the error object contains the path 'errors' then use the content of the array as the GraphQL errors.
    if (errorHandler.networkError instanceof HttpErrorResponse && errorHandler.networkError.error?.['errors']) {
      // Manually parse GraphQL errors from the network error
      errorHandler.graphQLErrors = errorHandler.networkError['error']['errors'];
    }

    if (errorHandler.graphQLErrors) {
      errorManager.handleQueryError(errorHandler.graphQLErrors[0], 'Error in ' + errorHandler.operation.operationName);
    } else if (errorHandler.networkError) {
      errorManager.handleNetworkError(errorHandler.networkError);
    } else {
      // unknown error
      errorManager.handleError({statusCode: 520});
    }
  });

  const link = httpLink.create({
    uri: environment.backend.baseUrl + environment.backend.graphql.endpoint
  });

  return new ApolloClient<NormalizedCacheObject>({
    cache: new InMemoryCache({
      typePolicies: {
        'User': {
          keyFields: ['id']
        },
        'Client': {
          keyFields: ['id']
        },
        'ClientSubscription': {
          keyFields: ['id']
        },
        'ClientSubscriptionModule': {
          keyFields: ['id']
        },
        'Group': {
          keyFields: ['id']
        },
        'UserRole': {
          keyFields: ['id']
        },
        'Asset': {
          keyFields: ['id']
        },
        'Work': {
          keyFields: ['id']
        },
        'Equipment': {
          keyFields: ['id']
        },
        'Project': {
          keyFields: ['id']
        }
      }
    }),

    link: errorLink.concat(link),
    name: packageJson.name,
    version: packageJson.version,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all', // Gets both data and errors in case of error
        fetchPolicy: 'no-cache'
      },
      mutate: {
        errorPolicy: 'none',
        fetchPolicy: 'network-only'
      }
    },
    connectToDevTools: !environment.production
  });
}

@NgModule({
  exports: [
    HttpClientModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloLinkFactory,
      deps: [HttpLink, ErrorManager]
    }
  ]
})
export class ApolloConfigModule {

  // TODO to use websockets, follow this guide https://www.apollographql.com/docs/apollo-server/v3/data/subscriptions/#switching-to-graphql-ws
  // and https://www.apollographql.com/docs/react/api/link/apollo-link-subscriptions/

  // Subscription link for websocket
  // const subscriptionLink = new WebSocketLink({
  //   // uri: 'ws://localhost:8080' + environment.backend.graphql.endpoint,
  //   uri: environment.backend.baseUrl + environment.backend.graphql.endpoint,
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       authToken: localStorage.getItem(AUTH_TOKEN) || null
  //     }
  //   },
  // });

  // const link = ApolloLink.split(
  //   ({ query }) => {
  //     const { kind, operation } = getMainDefinition(query);
  //     return kind === 'OperationDefinition' && operation === 'subscription';
  //   },
  //   subscriptionLink,
  //   //httpLink.concat(auth)
  //   auth.concat(httpLink)
  // );
  // }

}
