<div id="dialog-container">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_CONTRACT' | translate}}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="contractForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.CONTRACT_PROVIDER' | translate}}</mat-label>
        <input matInput [id]="'create_contract_provider'" formControlName="provider" required/>
        <mat-error *ngIf="contractForm.controls.provider.hasError('required')">
          {{'ERROR.FIELD_REQUIRED' | translate}}
        </mat-error>
        <mat-error *ngIf="contractForm.controls.provider.hasError('maxlength')">
          {{'ERROR.FIELD_MAXLENGTH' | translate: { value: appConfig.FIELD_MAX_LENGTH } }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'LABEL.ASSET' | translate}}</mat-label>
        <mat-select-asset-autocomplete formControlName="asset" [entityType]="EntityTypeEnum.CONTRACT" required>
        </mat-select-asset-autocomplete>
        <mat-error *ngIf="contractForm.controls.asset.hasError('required')">
          {{'ERROR.FIELD_REQUIRED' | translate}}
        </mat-error>
      </mat-form-field>

      <div class="space-form-field">
        <p class="form-custom-label">{{'LABEL.ALLOCATED_SPACES' | translate}} *</p>
        <ag-grid-angular *ngIf="contractForm.controls.asset.value; else noAsset"
                         (gridReady)="onGridReady($event)"
                         [gridOptions]="gridOptions"
                         class="ag-theme-material"
                         id="contract-allocated-spaces-grid"
                         style="width: 100%; height: 300px;">
        </ag-grid-angular>
        <mat-error
          *ngIf="contractForm.controls.spaces.touched && contractForm.controls.spaces.hasError('required')">
          {{'ERROR.FIELD_REQUIRED' | translate}}
        </mat-error>
        <mat-error *ngIf="contractForm.controls.spaces.hasError('INCOHERENT_SPACES')">
          {{'ERROR.SPACES_INCOHERENT' | translate}}
        </mat-error>
        <ng-template #noAsset>
          <div id="no-asset-selected-message">
            {{ 'LABEL.NO_ASSET_SELECTED' | translate }}
          </div>
        </ng-template>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose="cancel" [id]="'create-contract-cancel-button'">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button [disabled]="!contractForm.valid"
            [id]="'create-contract-add-button'"
            color="accent"
            mat-raised-button
            matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate}}
    </button>
  </mat-dialog-actions>
</div>
