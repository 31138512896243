import { Injectable } from '@angular/core';
import { CheckType } from '@app/core/model/entities/asset/check-type';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CheckTypeService {

  private checkTypes$ = new ReplaySubject<{ [k: string]: CheckType }>(1);
  public hasCheckTypes: boolean = false;

  private readonly checkInfoGraphqlFragment: any;

  constructor(private generalService: GeneralService,
              private appManager: AppManager) {
    this.checkInfoGraphqlFragment = gql`
    fragment CheckTypeInfo on CheckType {
      code
      values
      observation
    }
    `;
  }

  public getCheckTypesObservable(): Observable<{ [k: string]: CheckType }> {
    return this.checkTypes$.asObservable();
  }


  public loadChecksTypeData(): Observable<{ [k: string]: CheckType }> {
      const QUERY = gql`
        query OrganizationCheckTypes($organizationId: String!) {
          checkTypesByOrganizationId(organizationId: $organizationId) {
            ...CheckTypeInfo
          }
        }${this.checkInfoGraphqlFragment}
      `;
      const QUERY_VAR = {
        organizationId: this.appManager.currentOrganization.id
      };

      return this.generalService.get(QUERY, QUERY_VAR)
        .pipe(
          map( response => {
            const checkTypesList = plainToInstance(CheckType, response.data['checkTypesByOrganizationId'] as CheckType[]);
            const checkTypes: { [k: string]: CheckType } = {};
            checkTypesList.forEach((type) => checkTypes[type.code] = type);
            this.hasCheckTypes = checkTypesList.length > 0;
            this.checkTypes$.next(checkTypes);
            return checkTypes;
          })
        );
  }

  public getCheckTypeByCode(code: string): Observable<CheckType> {
    const COMBINED_QUERY = gql`
      query CheckTypeByCodeAndOrganizationId($code: String!, $organizationId: String!) {
        checkTypeByCodeAndOrganizationId(checkTypeCode: $code, organizationId: $organizationId) {
          ...CheckTypeInfo
        }
      }${this.checkInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      code,
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map((response) => {
          return plainToInstance(CheckType, response.data['checkTypeByCode'] as CheckType);
        })
      );
  }

  /**
   * Orders results by alphabetical order.
   * @param resultA A string or a DPE object
   * @param resultB A string or a DPE object
   */
  public static checkResultComparator(resultA, resultB): number {
    if (!resultA) {
      return 1;
    }
    if (!resultB) {
      return -1;
    }
    // DPE vs DPE comparison
    if (resultA.powerLabel && resultA.climateLabel && resultB.powerLabel && resultB.climateLabel) {
      if (resultA.powerLabel === resultB.powerLabel) {
        return (resultA.climateLabel.localeCompare(resultB.climateLabel));
      }
      return (resultA.powerLabel.localeCompare(resultB.powerLabel));
      // One of the results is a DPE, the other a simple string result
    } else if (resultA.powerLabel || resultA.climateLabel || resultB.powerLabel || resultB.climateLabel) {
      if (resultA.powerLabel) {
        return resultA.powerLabel.localeCompare(resultB);
      } else if (resultA.climateLabel) {
        return resultA.climateLabel.localeCompare(resultB);
      } else if (resultB.powerLabel) {
        return resultA.localeCompare(resultB.powerLabel);
      } else {
        return resultA.localeCompare(resultB.climateLabel);
      }
    } else {
      // Simple string vs string comparison
      return (resultA.toString().localeCompare(resultB.toString()));
    }
  }

  public static getLabelFromResult(resultType): string {
    return 'VALUE.' + resultType.toUpperCase();
  }

}
