import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { isPresent } from '@app/shared/validators/extra-validators.module';

export const decimal = (control: AbstractControl): ValidationErrors | null => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  return /^(?:-?\d+|-?\d{1,3}(?:\s?\d{3})+)?(?:[,.]\d+)?$/.test(control.value) ? null : { 'DECIMAL': true };
};
