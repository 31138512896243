import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControlStatus, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInputEvent } from '@angular/material/chips';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { EventOriginEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { ChipColorEnum } from '@app/core/enums/chip-color.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Entity } from '@app/core/model/entities/entity';
import {
  FIELD_CONFIG_INJECTION,
  FIELD_ENTITY_INJECTION,
  FIELD_EVENTS_ORIGIN,
  FIELD_EXTRA_DATA,
  FIELD_PERMISSIONS_INJECTION,
  FIELD_PRECONDITIONS_INJECTION,
  FieldConfig
} from '@app/core/model/other/field-config';
import { AbstractFieldBuilder } from '@app/shared/components/fields/abstract.field';
import { FormStateService } from '@app/shared/components/form-builder/form-state.service';
import { getValue, simplifyStringForSearch } from '@app/shared/extra/utils';
import { SingleEditService } from '@app/shared/services/single-edit-service';
import { ValidationService } from '@app/shared/services/validation.service';
import { ExtraValidators } from '@app/shared/validators/extra-validators.module';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';


@Component({
  selector: 'chips-field-builder',
  templateUrl: './chips-field-builder.component.html',
  styleUrls: ['./chips-field-builder.component.scss']
})
export class ChipsFieldBuilderComponent extends AbstractFieldBuilder implements OnInit, AfterViewInit {

  constructor(@Inject(FIELD_ENTITY_INJECTION) entity: Entity,
              @Inject(FIELD_EXTRA_DATA) data: any,
              @Inject(FIELD_EVENTS_ORIGIN) eventsOrigin: EventOriginEnum,
              formStateService: FormStateService,
              @Inject(FIELD_CONFIG_INJECTION) fieldConfig: FieldConfig,
              @Inject(FIELD_PRECONDITIONS_INJECTION) preconditionsForEdition: boolean,
              @Inject(FIELD_PERMISSIONS_INJECTION) permissionsForEdition: string[],
              appManager: AppManager,
              appConfig: AppConfig,
              accessManager: AccessManager,
              media: MediaMatcher,
              translate: TranslateService,
              validationService: ValidationService,
              singleEditService: SingleEditService,
              analyticsService: AnalyticsService) {
    super(entity, data, eventsOrigin, formStateService, fieldConfig, preconditionsForEdition, permissionsForEdition, appManager, appConfig, accessManager, media, translate, validationService, singleEditService, analyticsService);
  }

  public Permission = PermissionEnum;
  public chips: any[] = [];
  public filteredOptions: Observable<string[]>;
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('inputField') public inputField: ElementRef<HTMLInputElement>;
  @ViewChild('suggestions') public suggestions: MatAutocomplete;
  @ViewChild('chipList') public chipList: MatChipGrid;

  public ngOnInit(): void {
    this.chips.push(...this.fieldInitValue);
    this.form = new UntypedFormGroup({
      field: new UntypedFormControl(
        '',
        Validators.compose([this.computeValidators(), ExtraValidators.distinct(this.chips)])
      )
    });

    // Propagate invalid status to the mat-chip-list
    this.form.get('field').statusChanges.subscribe(
      (status: FormControlStatus) => {
        if (this.chipList !== void 0) {
          this.chipList.errorState = status === 'INVALID';
        }
      });

    // Initialise the field in the registry
    this.setFieldValue(this.fieldInitValue);
    this.setFieldInitialValue(this.fieldInitValue);
    this.getNextState();

    this.filteredOptions = this.form.get('field').valueChanges.pipe(
      startWith(''),
      filter(value => value !== void 0),
      map(value => this.filter(value))
    );
  }

  public ngAfterViewInit(): void {
    this.setupHooks();
  }

  protected calcFieldInitValue(): void {
    this.fieldInitValue = this.fieldConfig ? (getValue(this.entity, this.fieldConfig.fieldPath) ?? []) : [];
  }

  /**
   * Get the chip background-color if it's a colored chip.
   * The color depends on the chip's position.
   * @param index  the chip's position
   * @return background-color or undefined if there is no color.
   */
  public getChipColor(index: number): { 'background-color': string } {
    if (!this.fieldConfig.customOptions['colored']) return void 0;
    const colors = Object.values(ChipColorEnum);
    return {'background-color': colors[index % colors.length]};
  }

  public remove(chip: any): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
    }

    this.setFieldValue(this.chips.slice());
  }

  public input(event: MatChipInputEvent): void {
    const value = event.value;
    const maxlength = this.fieldConfig.field?.validators.find(validator => validator.type === 'MAX_LENGTH')?.definition
      || this.appConfig.DEFAULT_MAX_LENGTH;
    if (value.length <= maxlength && this.form.valid) {
      event.chipInput.clear();
      this.add(value);
    }
  }

  public select(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.inputField.nativeElement.value = '';
    this.add(value);
  }

  public cancel(): void {
    this.chips = (this.getFieldInitialValue() || []).slice();
    this.form.get('field').setValue('');
    this.setFieldValue((this.getFieldInitialValue() || []).slice());
    this.getNextState();
  }

  private add(value: any): void {
    // Add the new chip
    if ((value || '').trim()) {
      this.chips.push(value.trim());
    }

    this.form.get('field').setValue('');
    this.setFieldValue(this.chips.slice());
  }

  private filter(value: string): string[] {
    return this.fieldConfig.field?.fieldValues
      .filter(option => simplifyStringForSearch(option).includes(simplifyStringForSearch(value)) && !this.chips.includes(option));
  }

  public onClickSave(): void {
    this.add(this.form.get('field').value);
    super.onClickSave();
  }

  /**
   * Set focus on the element
   */
  public focus(): void {
    if (this.focusable) {
      setTimeout(() => this.inputField.nativeElement.focus());
    }
  }
}
