import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Project, ProjectInput } from '@app/core/model/entities/project/project';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { UsersService } from '@app/shared/services/users.service';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { from, mergeMap, Observable, Subject, switchMap } from 'rxjs';
import { map, tap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProjectsService  {

  private sidePanelToggleSubject = new Subject<Project | null>();

  private addProjectSubject = new Subject<Project>();
  private updateProjectSubject = new Subject<Project>();
  private deleteProjectsSubject = new Subject<Project[]>();

  private readonly projectInfoGraphqlFragment = gql`
    fragment ProjectInfo on Project {
      id
      identifier
      name
      assetIdsList
      projectIdsList
      properties
      computedProperties
      organizationId
      creationUserId
      creationDate
      lastChangeUserId
      lastChangeDate
      dataDate
    }
  `;

  constructor(private generalService: GeneralService,
              private appManager: AppManager,
              private router: Router,
              private activationEndService: ActivationEndService,
              private usersService: UsersService) {
  }

  /**
   * Emits every new Project after it was created.
   */
  public get projectAdded$(): Observable<Project> {
    return this.addProjectSubject.asObservable();
  }

  /**
   * Emits a Project whenever it has been updated.
   */
  public get projectUpdated$(): Observable<Project> {
    return this.updateProjectSubject.asObservable();
  }

  /**
   * Emits Projects whenever they have been deleted.
   */
  public get projectsDeleted$(): Observable<Project[]> {
    return this.deleteProjectsSubject.asObservable();
  }

  /**
   * Make an API request to fetch Projects linked to the current Organization that the current user have permission
   * to view. If an Asset ID is specified, only Projects related to this Asset are fetched. Otherwise, all
   * the Organization's Projects are fetched.
   * @param assetId ID of Asset whose Projects should be returned.
   * @return Observable emitting a list of all Projects.
   */
  public loadProjects(assetId?: string): Observable<Project[]> {
    const QUERY = gql`
      query Projects($organizationId: String!, $assetId: String) {
        projects(organizationId: $organizationId, assetId: $assetId) {
          ...ProjectInfo
        }
      }
      ${this.projectInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      assetId
    };

    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Project, response.data['projects'] as Project[])),
        // Fetch Users who created and last updated the Project
        switchMap(projects => from(projects)),
        mergeMap(project => this.usersService.fetchUsersInfo(project)),
        toArray()
      );
  }

  /**
   * Check if a Project entity has at least one inaccessible asset for the current user.
   * @param projectId The ID of the project.
   * @return Observable emitting true if a Project entity is related to at least one inaccessible asset entity, false otherwise.
   */
  public projectHasInaccessibleAssets(projectId: string): Observable<boolean> {
    const QUERY = gql`
      query ProjectHasInaccessibleAssets($projectId: String!) {
        projectHasInaccessibleAssets(projectId: $projectId)
      }
    `;
    const QUERY_VAR = {
      projectId
    };
    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(map(response => response.data['projectHasInaccessibleAssets'] as boolean));
  }

  /**
   * Make an API request to create new Project with the provided data.
   * @param projectInput Project data.
   * @return Observable emitting the new Project that was created.
   */
  public createProject(projectInput: ProjectInput): Observable<Project> {
    const MUTATION = gql`
      mutation CreateProject($organizationId: String!, $projectInput: ProjectInput!) {
        createProject(organizationId: $organizationId, projectInput: $projectInput) {
          ...ProjectInfo
        }
      }
      ${this.projectInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      projectInput
    };

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Project, response.data['createProject'])),
        switchMap(project => this.usersService.fetchUsersInfo(project)),
        tap(newProject => this.addProjectSubject.next(newProject))
      );
  }

  /**
   * Call the API to update a Project.
   * @param project Project to update
   * @param projectInput Data to update the Project with.
   * @return Observable emitting the updated Project once the update is completed
   */
  public updateProject(project: Project, projectInput: ProjectInput): Observable<Project> {
    const MUTATION = gql`
      mutation UpdateProject($projectId: String!, $projectInput: ProjectInput!) {
        updateProject(projectId: $projectId, projectInput: $projectInput) {
          ...ProjectInfo
        }
      }
      ${this.projectInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      projectId: project.id,
      projectInput: {
        name: project.name,
        assetIds: project.relatedAssets.map(asset => asset.id),
        projectIds: project.projectIds,
        ...projectInput
      }
    };

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => plainToInstance(Project, response.data['updateProject'])),
        switchMap(project => this.usersService.fetchUsersInfo(project)),
        tap(updatedProject => this.updateProjectSubject.next(updatedProject))
      );
  }

  /**
   * Make an API request to delete Projects.
   * @param projects Projects to delete.
   * @return True if all Projects have been deleted successfully, false otherwise.
   */
  public deleteProjects(projects: Project[]): Observable<boolean> {
    const MUTATION = gql`
      mutation DeleteProject($projectIds: [String!]!) {
        deleteProjects(projectIds: $projectIds)
      }
    `;
    const MUTATION_VAR = {projectIds: projects.map(project => project.id)};

    return this.generalService.set(MUTATION, MUTATION_VAR)
      .pipe(
        map(response => response.data['deleteProjects'] as boolean),
        tap(success => success && this.deleteProjectsSubject.next(projects))
      );
  }

  /**
   * Emits a Project whenever the side panel opens, or null whenever it closes.
   */
  public get sidePanelToggle$(): Observable<Project | null> {
    return this.sidePanelToggleSubject.asObservable();
  }

  /**
   * Open the sidenav with information about the selected Project.
   * @param project Project to be displayed in the side panel.
   */
  public openProjectSidePanel(project: Project): void {
    this.sidePanelToggleSubject.next(project);
  }

  /**
   * Close the side panel.
   */
  public closeProjectSidePanel(): void {
    this.sidePanelToggleSubject.next(null);
  }

  /**
   * Fetch Project to be displayed, based on the activated route.
   * @param route Activated route.
   * @return Project.
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<Project> {
    const QUERY = gql`
      query Project($id: String!) {
        project(id: $id) {
          ...ProjectInfo
        }
      }
      ${this.projectInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      id: route.paramMap.get('id')
    };

    return this.generalService.get(QUERY, QUERY_VAR, 'cache-first').pipe(
      map(response => plainToInstance(Project, response.data['project'] as Project)),
      // Fetch Users who created and last updated the Project
      switchMap(project => this.usersService.fetchUsersInfo(project)),
      tap(() => this.activationEndService.getRefreshHeaderSubject().next())
    );
  }

  /**
   * Fetch a Project by its ID.
   * @param projectId The Project's ID.
   * @return Observable emitting the Project.
   */
  public loadProject(projectId: string): Observable<Project> {
    const QUERY = gql`
      query Project($id: String!) {
        project(id: $id) {
          ...ProjectInfo
        }
      }
      ${this.projectInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      id: projectId
    };

    return this.generalService.get(QUERY, QUERY_VAR, 'network-only')
      .pipe(
        map(response => plainToInstance(Project, response.data['project'] as Project))
      );
  }

  /**
   * Navigate to a Project's sheet.
   * @param projectId ID of the Project to navigate to.
   * @param tabName Name of the tab to open after navigating to the Project's sheet. Default tabName is features
   * @return Router's promise (return true if navigation succeeds)
   */
  public async navigateToProjectSheet(projectId: string, tabName = 'features'): Promise<void> {
    await this.router.navigate([
      'organization',
      this.appManager.currentOrganization.id,
      'projects',
      'project-sheet',
      projectId,
      tabName
    ]);
  }

}
