<ng-container *ngIf="check?.type !== 'dpe'">
  <div class="fx-layout-row fx-justify-start fx-align-center">
    <div class="label-container">
      <div class="label-content" [ngClass]="check?.properties['observation_' + check?.type] | checkResultColour: {checkType: checkTypes[check?.type]}">
      </div>
    </div>
    <div class="result-text">
      {{ params.value }}
    </div>
  </div>
</ng-container>

