<div class="header-container fx-layout-row fx-justify-start fx-align-baseline fx-gap-10">
  <ng-container *ngIf="asset">
    <div class="inline-field-block asset-name-block page-title">
      <div>{{ asset?.toString() }}</div>
    </div>
    <div class="inline-field-block asset-state-block">
      <div>{{ asset?.assetState }}</div>
    </div>
  </ng-container>

  <div class="fx-flex"></div>
  <div class="fx-justify-center fx-align-center fx-flex-250">
    <ng-container *ngIf="!selectAssetFormControl.value; else loading">
      <mat-form-field class="search-asset-input mat-mdc-form-field-appearance-standard">
        <button mat-icon-button matPrefix disableRipple aria-label="Search" class="no-hover">
          <i class="mdi mdi-magnify" aria-hidden="true"></i>
        </button>
        <mat-select-asset-autocomplete [inputId]="'input_asset'"
                                       [formControl]="selectAssetFormControl"
                                       [placeholder]="'PLACEHOLDER.NAVIGATE_ASSET' | translate"
                                       [exclude]="asset">
        </mat-select-asset-autocomplete>
      </mat-form-field>
    </ng-container>
    <ng-template #loading>
      <mat-progress-spinner mode="indeterminate" diameter="15" color="accent"></mat-progress-spinner>
    </ng-template>
  </div>
  <div id="asset-menu" class="fx-flex-48">
    <restricted-icon-button [class]="'mdi mdi-dots-vertical sm'" [id]="'asset-sheet-menu-button'"
                            [matMenuTriggerFor]="menu" [permissions]="[Permission.DELETE_ASSET]"
                            [preconditions]="true"></restricted-icon-button>
    <mat-menu #menu="matMenu">
      <restricted-menu-item (onClick)="deleteAsset()" [id]="'delete-asset-menu'"
                            [permissions]="[Permission.DELETE_ASSET]" [preconditions]="true">
        {{ 'BUTTON.DELETE_ASSET' | translate }}
      </restricted-menu-item>
    </mat-menu>
  </div>
</div>
