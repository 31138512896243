<div id="dialog-container">
  <h1 mat-dialog-title>{{ modalTitle | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <form [formGroup]="workForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.ASSET' | translate }}</mat-label>
        <mat-select-asset-autocomplete formControlName="asset" [entityType]="EntityTypeEnum.WORK">
        </mat-select-asset-autocomplete>
      </mat-form-field>

      <div class="space-form-field">
        <p class="form-custom-label">{{'LABEL.WORK_SPACES' | translate}}</p>
        <ag-grid-angular *ngIf="workForm.get('asset').value; else noAsset"
                         (gridReady)="onGridReady($event)"
                         [gridOptions]="gridOptions"
                         [multiSortKey]="appConfig.GRID_MULTI_SORT_KEY"
                         [rowData]="spaces | async"
                         class="ag-theme-material"
                         id="work_spaces_grid"
                         rowSelection="multiple"
                         style="width: 100%; height: 300px">
        </ag-grid-angular>
        <mat-error *ngIf="workForm.get('spaces').hasError('INCOHERENT_SPACES')">
          {{'ERROR.SPACES_INCOHERENT' | translate}}
        </mat-error>
        <ng-template #noAsset>
          <div id="no-asset-selected-message">
            {{ 'LABEL.NO_ASSET_SELECTED' | translate }}
          </div>
        </ng-template>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button id="duplicate-work-cancel-button" matDialogClose="cancel">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button id="duplicate-work-add-button" [disabled]="workForm.invalid" color="accent"
            matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
