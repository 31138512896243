import { Injectable } from '@angular/core';
import { Section } from '@app/core/model/other/field-config';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SectionService {
  private readonly sectionGraphqlFragment: any;

  constructor(private appManager: AppManager,
              private generalService: GeneralService) {

    this.sectionGraphqlFragment = gql`
      fragment SectionInfo on Section {
        organizationId
        code
        order
        customOptions
        label
        emptyLabel
        fieldGroupsList {
          code
          order
          label
          emptyLabel
          tooltip
          fieldGroupType
          customOptions
          fieldConfigsList {
            fieldCode
            order
            customOptions
            field {
              code
              label
              tooltip
              entityType
              fieldType
              checkType
              computed
              parentPathList
              fieldValuesList
              validatorsList {
                conditionsList {
                  field
                  operator
                  value
                }
                definition
                type
              }
            }
            conditionsToViewList {
              field
              operator
              value
            }
            conditionsToEditList {
              field
              operator
              value
            }
          }
          conditionsToViewList {
            field
            operator
            value
          }
          conditionsToEditList {
            field
            operator
            value
          }
        }
        conditionsToViewList {
          field
          operator
          value
        }
        conditionsToEditList {
          field
          operator
          value
        }
      }
    `;
  }

  /**
   * Get all Sections by form code.
   * @param formCode Form code.
   * @return Observable that emits a list of Sections.
   */
  public getSectionsByFormCode(formCode: string): Observable<Section[]> {
    const COMBINED_QUERY = gql`
      query FormBuilderQuery($formCode: String!, $orgId: String!) {
        sections(formCode: $formCode, organizationId: $orgId) {
          ...SectionInfo
        }
      }${this.sectionGraphqlFragment}
    `;
    const QUERY_VAR = {
      formCode,
      orgId: this.appManager.currentOrganization.id
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => plainToInstance(Section, response.data['sections'] as Section[]))
      );
  }

  /**
   * Get all field's values used in the organization.
   * @param fieldCode code of the field.
   * @param formCode code of the form to which the field belongs.
   * @return observable that emit a list of the organization field's values.
   */
  public getFieldCodeValues(fieldCode: string, formCode: string): Observable<string[]> {
    const QUERY = gql`
      query Sections($formCode: String!, $orgId: String!) {
        sections(formCode: $formCode, organizationId: $orgId) {
          fieldGroupsList {
            code
            fieldConfigsList {
              fieldCode
              field {
                fieldValuesList
              }
            }
          }
        }
      }
    `;
    const QUERY_VAR = {
      formCode: formCode,
      orgId: this.appManager.currentOrganization.id
    };
    return this.generalService.get(QUERY, QUERY_VAR)
      .pipe(
        map(response => {
          const section = plainToInstance(Section, response.data['sections'][0] as Section);
          return section.fieldGroups
            .find(fieldGroup => fieldGroup.code === fieldCode)
            .fieldConfigs
            .find(field => field.fieldCode === fieldCode)
            .field
            .fieldValues;
        })
      );
  }
}

