import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { gql } from '@apollo/client/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { Permission } from '@app/core/model/client/permission';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { JMapAuthDetails } from '@app/core/model/entities/jmap/jmap-auth-details';
import { Organization, OrganizationWithLogo } from '@app/core/model/entities/organization/organization';
import { UserPreferences } from '@app/core/model/entities/preferences/user-preferences';
import { JmapCredentialsInput } from '@app/core/model/inputs/jmap-credentials-input';
import {
  DuplicateOrganizationModalComponent
} from '@app/features/main/views/_tb/tb-organizations/tb-organizations-datagrid/duplicate-organization-modal/duplicate-organization-modal.component';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { thenReturn } from '@app/shared/extra/utils';
import { UserPreferencesService } from '@app/shared/services/user-preferences.service';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@services/file.service';
import { GeneralService } from '@services/general.service';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { NotificationManager } from '@services/managers/notification.manager';
import { PopupManager, PopupSize } from '@services/managers/popup.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { plainToInstance } from 'class-transformer';
import { print } from 'graphql/language/printer';
import { from, map, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, first, mergeMap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class OrganizationsService {

  // Store multiple organizations.
  public organizations: Organization[] = [];

  private addOrganizationSubject = new Subject<Organization>();
  private deleteOrganizationSubject = new Subject<Organization>();

  /**
   * GraphQL fragment for fetching organization information.
   * - id: Unique identifier, used to navigate to its dashboard page.
   * - name: Name of the organization, used for display.
   * - properties: Any additional properties, used to get the ID of the associated logo image
   * @private
   */
  private readonly organizationsInfoGraphqlFragment = gql`
    fragment OrganizationInfo on Organization {
      id
      name
      currency
      classificationType
      properties
      computedProperties
      isDemo
      isTb
      createDate
      storageSpace
    }
  `;

  /**
   * Constructor for the Organizations Service.
   * Import services.
   */
  constructor(private appManager: AppManager,
              private accessManager: AccessManager,
              private userPreferencesService: UserPreferencesService,
              private notificationManager: NotificationManager,
              private analyticsService: AnalyticsService,
              private appConfig: AppConfig,
              private documentsService: DocumentsService,
              private fileService: FileService,
              private generalService: GeneralService,
              private popupManager: PopupManager,
              private sanitizer: DomSanitizer,
              private snackbarManager: SnackbarManager,
              private translate: TranslateService) {
  }

  /**
   * Emits an Organization after it has been created.
   */
  public get organizationCreated$(): Observable<Organization> {
    return this.addOrganizationSubject.asObservable();
  }

  /**
   * Emits an Organization after it has been deleted.
   */
  public get organizationDeleted$(): Observable<Organization> {
    return this.deleteOrganizationSubject.asObservable();
  }

  /**
   * Load an Organization, as well as related settings such as the current User's preferences, permissions and modules.
   * @param organizationId ID of the Organization.
   * @return Observable that emits the loaded Organization.
   */
  public loadOrganization(organizationId: string): Observable<Organization> {
    const COMBINED_QUERY = gql`
      query Organization($orgId: String!) {
        organization(id: $orgId) {
          ...OrganizationInfo
        }
        permissionsForUserOrg(organizationId: $orgId) {
          id
          code
        }
        userPreferences(organizationId: $orgId) {
          id
          preferences
        }
        organizationUsersInfo(organizationId: $orgId) {
          id
          name
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      orgId: organizationId
    };
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR, 'network-only')
      .pipe(
        map(response => {
          return {
            organization: plainToInstance(Organization, response.data['organization'] as Organization),
            permissions: plainToInstance(Permission, response.data['permissionsForUserOrg'] as Permission[]),
            preferences: plainToInstance(UserPreferences, response.data['userPreferences'] as UserPreferences)
          };
        }),
        switchMap(({organization, permissions, preferences}) => {
          // Set the only organization as current
          this.appManager.emptyCurrentEntityStack();
          this.appManager.currentOrganization = organization;
          this.appManager.entityObservable$.next(true);

          // Set the user permissions once the organization is selected
          this.accessManager.currentUser.organizationPermissions = permissions;

          // Set the user preferences once the organization is selected
          this.userPreferencesService.currentUserPreferences = preferences;

          // Try to load the language file for the org
          // Ignore the error if the file does not exist
          this.translate.addLangs([this.appManager.currentOrganization.id]);
          return this.translate.use(this.appManager.currentOrganization.id)
            .pipe(
              catchError(() => {
                // notify other guards that organization is loaded
                const language = this.userPreferencesService.currentUserPreferences?.preferences?.['language']
                  ?? this.translate.defaultLang;
                return this.translate.use(language);
              }),
              first(),
              thenReturn(organization)
            );
        })
      );
  }

  /**
   * Fetch all accessible by the User Organizations.
   * @return Observable emitting a list of Organizations.
   */
  public loadData(): Observable<Organization[]> {
    this.organizations = [];
    const COMBINED_QUERY = gql`
      query AccessibleOrganizations {
        accessibleOrganizations {
          ...OrganizationInfo
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;

    return this.generalService.get(COMBINED_QUERY, {})
      .pipe(
        map(response => {
          this.organizations = plainToInstance(
            Organization,
            response.data['accessibleOrganizations'] as Organization[]
          );
          return this.organizations;
        })
      );
  }


  /**
   * Fetch all accessible by the User Organizations with their associated logos.
   * @return Observable emitting a list of Organizations with their associated logos.
   */
  public loadOrganizationsWithLogo(): Observable<OrganizationWithLogo[]> {
    const COMBINED_QUERY = gql`
      query AccessibleOrganizations {
        accessibleOrganizations {
          ...OrganizationInfo
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;
    // Get all accessible Organizations by the user.
    return this.generalService.get(COMBINED_QUERY, {})
      .pipe(
        switchMap(response => {
          return from(plainToInstance(Organization, response.data['accessibleOrganizations'] as Organization[]))
            .pipe(
              mergeMap(organization => {
                if (!organization.logoDocumentId) {
                  // If the organization doesn't have a logo, only return the organization with a placeholder logo.
                  return of({organization, logoUrl: 'url(' + this.appConfig.PLACEHOLDER_ORGANIZATION + ')'});
                } else {
                  // If the organization has a logo, Load the logo related to the organization.
                  return this.documentsService.getDocument(organization.logoDocumentId)
                    .pipe(
                      // Map the logo document to a style url.
                      switchMap(document => {
                        return this.fileService.getDocumentFileUrl(document.id).pipe(
                          map(documentUrl => {
                            const logoUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${documentUrl})`);
                            return {organization, logoUrl};
                          })
                        );
                      })
                    );
                }
              })
            );
        }),
        toArray(),
        map(organizations => {
          // sort the organizations by name.
          return organizations.sort(({organization: organizationA}, {organization: organizationB}) => {
            return organizationA.name.compareTo(organizationB.name);
          });
        })
      );
  }

  /**
   * Fetch the storage information of an Organization.
   * @param organizationId ID of the Organization.
   * @return An Observable that emits the storage config of the Organization.
   */
  public getOrganizationStorage(organizationId: string): Observable<{ occupiedStorageSpace: number }> {
    const COMBINED_QUERY = gql`
      query OrganizationQuery($organizationId: String!) {
        currentlyUsedStorageSpace(organizationId: $organizationId)
      }
    `;
    const QUERY_VAR = {organizationId};
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        map(response => {
          return {
            occupiedStorageSpace: response.data['currentlyUsedStorageSpace'] as number
          };
        })
      );
  }

  /**
   * Get an Organization's JMap credentials.
   * @param organizationId Organization's ID.
   * @return Observable that emits the Organization's JMap credentials.
   */
  public getOrganizationJMapCredentials(organizationId: string): Observable<JMapAuthDetails> {
    const COMBINED_QUERY = gql`
      query OrganizationQuery($organizationId: String!) {
        organizationJmapCredentials(organizationId: $organizationId) {
          url
          projectId
          username
        }
      }
    `;
    const QUERY_VAR = {organizationId};
    return this.generalService.get(COMBINED_QUERY, QUERY_VAR)
      .pipe(
        first(),
        map(response => plainToInstance(JMapAuthDetails, response.data['organizationJmapCredentials']))
      );
  }

  /**
   * API call to update an organization.
   * @param organizationId the Organization ID
   * @param organizationInput the data to update
   * @return the updated Organization
   */
  public updateOrganization(organizationId: string, organizationInput: Record<string, any>): Observable<Organization> {
    const COMBINED_MUTATION = gql`
      mutation UpdateOrganizationMutation($organizationId: String!, $organizationInput: UpdateOrganizationInput!) {
        updateOrganization(organizationId: $organizationId, organizationInput: $organizationInput) {
          ...OrganizationInfo
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;

    const MUTATION_VAR = {
      organizationId: organizationId,
      organizationInput: organizationInput
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR).pipe(
      map(response => plainToInstance(Organization, response.data['updateOrganization']))
    );
  }

  /**
   * API call to update the Organization's JMap credentials.
   * @param organizationId the Organization ID
   * @param jmapCredentialsInput the new jmap credentials
   * @return the updated Organization
   */
  public upsertOrganizationJmapCredentials(
    organizationId: string,
    jmapCredentialsInput: JmapCredentialsInput
  ): Observable<JMapAuthDetails> {
    const COMBINED_MUTATION = gql`
      mutation UpsertOrganizationJmapCredentials($organizationId: String!, $jmapCredentialsInput: JmapCredentialsInput!) {
        upsertOrganizationJmapCredentials(organizationId: $organizationId, jmapCredentialsInput: $jmapCredentialsInput) {
          id
          url
          projectId
          username
        }
      }
    `;

    const MUTATION_VAR = {
      organizationId: organizationId,
      jmapCredentialsInput: jmapCredentialsInput
    };

    return this.generalService.set(COMBINED_MUTATION, MUTATION_VAR).pipe(
      map(response => plainToInstance(JMapAuthDetails, response.data['upsertOrganizationJmapCredentials']))
    );
  }

  /**
   * Open a dialog to delete a Organization.
   * @param organization Entity to delete.
   */
  public openDeleteOrganizationDialog(organization: Organization): void {
    const dialogRef = this.popupManager.showOkCancelPopup({
      dialogTitle: this.translate.instant('TITLE.DELETE_ORGANIZATION'),
      dialogMessage: this.translate.instant('MESSAGE.DELETE_ORGANIZATION'),
      okText: this.translate.instant('BUTTON.DELETE_ORGANIZATION'),
      type: 'warning'
    });
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      const analyticsEvent = new AnalyticsEvent(ActionEnum.DELETE, EntityTypeEnum.ORGANIZATION);
      if (dialogResponse === 'yes') {
        analyticsEvent.addProperties({
          [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
          [AnalyticsKeyEnum.ENTITY_ID]: organization.id
        });
        this.deleteOrganization(organization);
      } else {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
      }
      this.analyticsService.trackEvent(analyticsEvent);
    });
  }

  /**
   * Delete an existing organization.
   * @param organization Entity to delete.
   */
  public deleteOrganization(organization: Organization): void {
    this.snackbarManager.showInfiniteSnackbar(this.translate.instant('LABEL.DELETE_IN_PROGRESS'));
    this.deleteOrganizationSubject.next(organization);

    const SUBSCRIPTION = gql`
      subscription DeleteOrganizationMutation($id: String!) {
        deleteOrganization(organizationId: $id)
      }
    `;
    const SUBSCRIPTION_VAR = {
      id: organization.id
    };
    this.generalService.subscribeTo(
      print(SUBSCRIPTION),
      SUBSCRIPTION_VAR,
      `delete_organization_${organization.id}`
    )
      .subscribe({
        next: response => {
          if (response['deleteOrganization']) {
            this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DELETE_ORGANIZATION'));
          } else this.snackbarManager.closeSnackbar();
        },
        error: () => {
          this.snackbarManager.closeSnackbar();
        }
      });
  }

  /**
   * Open a dialog to duplicate an existing organization.
   * @param organization to duplicate.
   */
  public openDuplicateOrganizationDialog(organization: Organization): void {
    const dialogRef = this.popupManager.showGenericPopup(DuplicateOrganizationModalComponent, PopupSize.SMALL, {});
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      const analyticsEvent = new AnalyticsEvent(ActionEnum.DUPLICATE, EntityTypeEnum.ORGANIZATION);
      if (dialogResponse === 'yes') {
        analyticsEvent.addProperties({
          [AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.SAVE,
          [AnalyticsKeyEnum.ENTITY_ID]: organization.id
        });
        const dataObject = dialogRef.componentInstance.getGeneratedObject();
        this.duplicateOrganization(organization.id, dataObject.organizationName);
      } else {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.DIALOG_ACTION]: ActionEnum.CANCEL});
      }
      this.analyticsService.trackEvent(analyticsEvent);
    });
  }

  /**
   * Duplicate an existing organization.
   * @param organizationId The ID of the organization to duplicate.
   * @param name of the new organization.
   */
  private duplicateOrganization(organizationId: string, name: string): void {
    const SUBSCRIPTION = gql`
      subscription DuplicateOrganization($organizationId: String!, $name: String!) {
        duplicateOrganization(name: $name, organizationId: $organizationId) {
          ...OrganizationInfo
        }
      }
      ${this.organizationsInfoGraphqlFragment}
    `;

    const SUBSCRIPTION_VAR = {
      organizationId: organizationId,
      name: name
    };

    this.snackbarManager.showInfiniteSnackbar(this.translate.instant('LABEL.DUPLICATE_IN_PROGRESS'));

    this.generalService.subscribeTo(print(SUBSCRIPTION), SUBSCRIPTION_VAR, `duplicate_organization_${organizationId}`)
      .pipe(
        map(response => plainToInstance(Organization, response['duplicateOrganization'] as Organization))
      )
      .subscribe({
        next: organization => this.addOrganizationSubject.next(organization),
        complete: () =>
          this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.DUPLICATE_ORGANIZATION')),
        error: () => this.snackbarManager.closeSnackbar()
      });
  }
}
