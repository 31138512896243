import { Component } from '@angular/core';
import {
  WorksSidebarComponent
} from '@app/features/main/views/works/works-inventory/works-sidebar/works-sidebar.component';
import { WorkModalService } from '@app/features/main/views/works/works-inventory/modals/work-modal.service';
import { DocumentModalService } from '@app/features/main/views/organization-documents/modals/document-modal.service';

@Component({
  selector: 'asset-works-sidebar',
  templateUrl: './asset-works-sidebar.component.html',
  styleUrls: ['./asset-works-sidebar.component.scss'],
  providers: [WorkModalService, DocumentModalService]
})
export class AssetWorksSidebarComponent extends WorksSidebarComponent {
}
