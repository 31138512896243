import { PayloadAction } from '@app/shared/store/model';
import { IState } from '@app/shared/store/state';

export interface IFieldValueState extends IState {
  currentValue: string;
  originalValue: string;
}

export class ReduxStore {
  static createFieldReducer(fieldName: string): (state: IFieldValueState, action: PayloadAction) => IFieldValueState {
    let initialState: IFieldValueState = {
      currentValue: '',
      originalValue: ''
    };

    function newState(state, newData) {
      return Object.assign({}, state, newData);
    }

    function resetState(state, newData) {
      initialState = Object.assign({}, state, newData);
      return initialState;
    }

    return (state: IFieldValueState = initialState, action: PayloadAction): IFieldValueState => {

      switch (action.type) {
        case `${fieldName}_change`:
          return newState(state, action.payload);
        case `${fieldName}_revert`:
          return initialState;
        case `${fieldName}_reset`:
          return resetState(state, action.payload);
        default:
          return state;
      }
    };
  }
}
